import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./../../../SCSS/ProductPage.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import FAQ from "../../../Component/FAQ/FAQ";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { COLORS } from "../../../SupportingFiles/colors";
import CarProductContainer from "../../../Container/CarProductContainer/CarProductContainer";

const CarProductPage = () => {
  const isMobile = useIsMobile();
  const [value, setValue] = React.useState("1");
  const [covrageTabvalue, setCovrageTabvalue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const handleTabsChange = (
    event: React.SyntheticEvent,
    newTabsValue: string
  ) => {
    setCovrageTabvalue(newTabsValue);
  };

  const benefitsArray = [
    {
      title: "No More Agents",
      desc: `Always read the fine print of the policy you choose. Check out the inclusions and exclusions so that you know the scope of the policy. This will ensure that you are not caught off-guard when you suffer a claim.`,
    },
    {
      title: "Zero Paperwork",
      desc: `Though the third party coverage fulfils the legal obligations, it does not cover the damages that your car might suffer. Such damages can also incur considerable financial losses and so, it is best to cover them as well. So, opt for comprehensive plans for a wider scope of coverage.`,
    },
    {
      title: "Convenient & Saves Time",
      desc: `This, again, is a common practice that you should follow. Since comparison helps you find the best policy, always compare the available plans online and then invest in the best policy.`,
    },
    {
      title: "Easy Comparison",
      desc: `Choosing the right insurance company is as vital as choosing the right insurance policy. You must always check the incurred claim ratio of the insurance company you choose, which is the amount of money paid out as claims against the total money collected as a premium. Ideally, the ICR should not be too high, which would mean that the company doesn't have enough surplus to pay claims and neither should it be too low, which would mean that the company is overcharging the premium.`,
    },
    {
      title: "Easy customisation",
      desc: `Add-ons enhance the scope of coverage of the policy and can prove to be a great value addition, especially at the time of claims. So, choose suitable add-ons, depending on your needs, to make your coverage all-inclusive`,
    },
    {
      title: "Cost-Effective",
      desc: `If you want a cashless claim settlement, which is easier, always check out the network of garages the insurance company is tied up with. The information is easily available on the insurer’s website and you should choose an insurer with the largest network so that locating a preferred garage becomes easier.`,
    },
    {
      title: "Instant policy issuance",
      desc: `Comprehensive plans allow a host of premium discounts, besides the no claim discount. Look for the available discounts and try and claim as many as you can. This would reduce your car insurance premiums and make the policy more affordable.`,
    },
  ];

  const addonsArray = [
    {
      title: "Roadside assistance",
      desc: `Provides 24x7 assistance in the case of breakdowns in the middle of nowhere. This coverage is especially necessary if you use your car in remote locations where pick-ups vans are not easily available`,
    },
    {
      title: "Zero depreciation cover",
      desc: `This add-on is also known as bumper-to-bumper coverage wherein the depreciation is not applied on damaged parts during claim payments.In the absence of this add-on, the insurer deducts depreciation on the different parts repaired or replaced which effectively reduces the claim amount and incurs out of pocket expenses.`,
    },
    {
      title: "NCB Protect",
      desc: `This add-on helps you protect your no claim bonus even after a claim is made. This coverage is especially necessary if you have a high NCB and you do not wish to let that go in case of a claim.`,
    },
    {
      title: "Return to invoice",
      desc: `This coverage would help you to claim the invoice value of the car without any depreciation applied on the IDVin the case of total loss or theft of the car`,
    },
    {
      title: "Engine gearbox protection",
      desc: `This rider covers damages caused to the engine and gearbox especially due to floods or waterlogging. This coverage is especially necessary if you live in a flood-prone area or in areas with heavy seasonal rainfalls`,
    },
    {
      title: "Personal cover for passengers",
      desc: `This add-on extends the personal accident coverage to the passengers of the car. If the passengers suffer accidental death or disablement when riding in the car, the add-on would pay a lump sum benefit for the contingency suffered.`,
    },
    {
      title: "Consumables cover",
      desc: `The cost of consumables is not covered when there is a claim. They form your out of pocket expenses. However, with this add-on, the cost of consumables would be covered by the plan.`,
    },
    {
      title: "Key replacement cover",
      desc: `If you lose both the keys to your car, this add-on would cover the cost of replacing the lost keys with a duplicate set`,
    },
    {
      title: "Tyre replacement cover",
      desc: `If your tyres or tubes suffer damages in an accident, the cost of repairing or replacing them would not be covered. However, if you choose this add-on, such costs would be covered by the plan`,
    },
    {
      title: "Loss of personal belongings",
      desc: `In the case of theft or accident, if you lose your personal belongings that were in the car, the financial loss suffered would be covered by this add-on`,
    },
  ];
  return (
    <Box className="productPage-layout">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="header-Form-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} textAlign={"center"} marginBottom={"32px"}>
            <h2 className="mb-4">
              Clarify All Doubts Related To Car Insurance
            </h2>
            <p>
              If you own a vehicle in India, it is compulsory to get it insured
              under a valid insurance policy. A car insurance policy covers the
              financial liability which arises in case the insured car injures
              any third party or damages any third party property. Moreover, if
              the coverage of the policy is comprehensive, the loss suffered by
              the car due to accidents or any other calamities is also covered.
              The Motor Vehicles Act, 1988 mandate every vehicle to be insured
              before it is allowed to run on Indian roads. So, by default,
              vehicle owners have to invest in suitable motor insurance policies
              for their vehicles.The car owner is the policyholder who is
              required to pay the premium on an annual basis.
            </p>
          </Grid>
          <Grid xs={12} textAlign={"center"}>
            <Box className="formBox" position="relative">
              <CarProductContainer />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="benefits-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Box className="benfitsbox">
              <Box className="headerBox">
                <h2>Benefits of Buying Car Insurance Policy</h2>
              </Box>
              <Box className="contentBox">
                <p>
                  As mentioned earlier, you can buy a car insurance plan both
                  online or offline. The online mode, however, has distinct
                  advantages and is preferred over the offline mode. Here's a
                  look at the advantages offered by a car insurance policy
                  online:
                </p>
                <FAQ data={benefitsArray} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="types-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <h2 className="mb-4">Types Of Car Insurance Plans</h2>
            <p className="mb-4">
              A car insurance policy, as the name suggests, is a motor insurance
              policy taken for a privately owned car, i.e. a car that is used
              for personal purposes, not commercial ones. Car insurance plans
              are also sub-divided into different types. They are as follows:
            </p>
          </Grid>
          <Grid xs={12} className="types-tabs">
            <TabContext value={value}>
              <Box>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label={
                      <>
                        Third-party
                        <br /> Liability
                      </>
                    }
                    value="1"
                  />
                  <Tab
                    label={
                      <>
                        Comprehensive
                        <br /> Cover
                      </>
                    }
                    value="2"
                  />
                  <Tab
                    label={
                      <>
                        Pay-as-you
                        <br /> drive
                      </>
                    }
                    value="3"
                  />
                  <Tab
                    label={
                      <>
                        Long term
                        <br /> Liability cover
                      </>
                    }
                    value="4"
                  />
                  <Tab
                    label={
                      <>
                        Standalone Own
                        <br /> Damage Cover
                      </>
                    }
                    value="5"
                  />
                  <Tab
                    label={
                      <>
                        Bundled
                        <br /> Plan
                      </>
                    }
                    value="6"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <p>
                  This is the mandatory cover as required by the Motor Vehicles
                  Act, 1988. This policy provides coverage against third party
                  legal liabilities. It pays the required compensation for any
                  death, injury or damage of property to any third party. For
                  example, say your car hits an individual and causes physical
                  injury. The financial loss suffered by the individual, due to
                  the injury, would be covered by the policy. If the legal
                  liability comes to Rs.50,000, the policy would pay the amount
                  on your behalf.
                </p>
              </TabPanel>
              <TabPanel value="2">
                <p>
                  This policy covers the mandatory third-party liability as well
                  as the damages suffered by your own car due to natural or
                  man-made causes. For example, say your car hits another
                  individual’s car and damages it. Your car is also damaged in
                  the process. The liability suffered from damaging an
                  individual’s car comes to Rs.1 lakh while the repair cost for
                  your own car comes to Rs.50,000. In this case, the
                  comprehensive policy would pay Rs.1 lakh liability to the
                  third party whose car you damaged. Furthermore, for your own
                  damages, the repair cost of Rs.50,000 would also be covered.
                </p>
              </TabPanel>
              <TabPanel value="3">
                <p>
                  This is a flexible policy wherein the coverage can be turned
                  on and off depending on your usage of the car. You can turn
                  the coverage on when you use the car and off when you don’t.
                  This saves a lot on the premium cost that is otherwise payable
                  for a policy wherein the coverage is always on. Premiums for
                  these plans are calculated based on the kilometres you declare
                  to cover in a year.
                </p>
              </TabPanel>
              <TabPanel value="4">
                <p>
                  This policy offers third party liability coverage for a
                  continuous period of 3 years. The policy is available only for
                  new cars that are required to carry a long term third party
                  cover as per IRDAI’s latest rules.
                </p>
              </TabPanel>
              <TabPanel value="5">
                <p>
                  This policy covers only the damages that your car suffers.
                  Third-party liability coverage is not allowed. A standalone
                  car insurance policy can be purchased when you already have
                  third-party liability insurance or you can buy them both
                  simultaneously. For example, say you buy a new car. You would
                  have to buy a long term liability cover on the same. Now, if
                  you wish to enjoy coverage for your own damages too, you can
                  buy a standalone own damage cover from the same or any other
                  insurer.
                </p>
              </TabPanel>
              <TabPanel value="6">
                <p>
                  A bundled policy is available for new cars. This policy allows
                  third party liabilities for 3 years and own damage cover for
                  one year.
                </p>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Box>

      <Box className="features-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Box className="featurebox">
              <Box className="headerBox">
                <h2>Features Of Car Insurance Plans</h2>
              </Box>
              <Box className="contentBox">
                <Grid container columnSpacing={3}>
                  <Grid xs={12}>
                    <p>
                      Here are some notable features of car insurance plans:
                    </p>
                    <ul className="blue-square-list">
                      <li>
                        If you choose comprehensive car insurance plans, you can
                        customize and enhance the coverage through optional
                        add-ons.
                      </li>
                      <li>
                        Comprehensive plans also allow different types of
                        premium discounts, the most notable of them being the no
                        claim discount that you earn for not making a claim
                        under the policy.
                      </li>
                      <li>
                        The premium for third party plans is fixed by the IRDA
                        depending on the engine capacity. For comprehensive
                        plans, however, the premium is determined by respective
                        insurance companies. This is why premium varies across
                        insurers and you should compare car insurance online
                        before you buy comprehensive plans.
                      </li>
                    </ul>
                  </Grid>
                  <Grid xs={12} md={8}>
                    <ul className="blue-square-list">
                      <li>
                        The personal accident cover is mandatory under car
                        insurance plans. However, if you already have a personal
                        accident cover in your name, you can skip it.
                      </li>
                      <li>
                        You can buy a standalone third party cover, standalone
                        own damage cover and the personal accident cover from
                        different insurers.
                      </li>
                      <li>
                        The long term liability cover for new cars, all other
                        plans are annual plans.
                      </li>
                      <li>
                        Every time the cover is renewed, you can switch
                        insurance companies easily if you want.
                      </li>
                      <li>
                        You can buy car insurance policy online or offline.
                      </li>
                    </ul>
                  </Grid>
                  <Grid xs={12} md={4} textAlign={"center"}>
                    <img
                      src="./images/product-car.svg"
                      alt=""
                      className="product-img"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="addOns-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <h2>Add-ons Under Car Insurance Plans</h2>
            <p>
              Add-ons are additional coverage features that are available as
              optional benefits in comprehensive car insurance plans. You can
              opt for one or more add-ons as per your coverage needs. Different
              insurance companies offer a different set of add-ons under their
              car insurance plans. However, some of the most common add-ons that
              you can avail of include the following:
            </p>
            <FAQ data={addonsArray} />
          </Grid>
        </Grid>
      </Box>

      <Box className="inclusionExclusion-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Box className="inclusionbox">
              <Box className="headerBox">
                <h2>Coverage Inclusions & Exclusions In Car Insurance Plans</h2>
              </Box>
              <Box className="contentBox">
                <Box className="coverageTabs">
                  <TabContext value={covrageTabvalue}>
                    <Box>
                      <TabList
                        onChange={handleTabsChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Coverage Inclusions" value="1" />
                        <Tab label="Coverage Exclusions" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Grid container columnSpacing={3}>
                        <Grid xs={12}>
                          <p>
                            Any third party injury or death which is a result of
                            an accident involving your car
                          </p>
                          <p>
                            Third-party property damage that occurs because of
                            your car A comprehensive plan, on the other hand,
                            would cover the above-mentioned instances along with
                            the following:
                          </p>
                        </Grid>
                        <Grid xs={12} md={9}>
                          <ul className="blue-square-list mb-3">
                            <li>Damages suffered due to accidents</li>
                            <li>
                              Damages suffered due to natural calamities like
                              floods, earthquakes, lightning, etc.
                            </li>
                            <li>
                              Damages suffered due to man-made disasters like
                              fire, theft, explosion, terrorism, etc.
                            </li>
                            <li>
                              Damages suffered when the car is in transit by
                              rail, road, water or air
                            </li>
                          </ul>
                          <p style={{ marginBottom: "4px", fontWeight: 700 }}>
                            Moreover, if you opt for the personal accident
                            cover, coverage would be allowed for the following –
                          </p>
                          <ul className="blue-square-list mb-3">
                            <li>Accidental death</li>
                            <li>
                              Accidental permanent and total or partial
                              disablement
                            </li>
                          </ul>
                        </Grid>
                        <Grid xs={12} md={3}>
                          <img
                            src="./images/coverage-img.svg"
                            alt=""
                            className="coverage-img"
                          />
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                      <Grid container columnSpacing={3}>
                        <Grid xs={12} md={9}>
                          <ul className="blue-square-list mb-3">
                            <li>
                              When you are driving without a valid license
                            </li>
                            <li>When you are driving outside India</li>
                            <li>
                              When you are driving under the influence of
                              alcohol and/or drugs
                            </li>
                            <li>Consequential losses</li>
                            <li>
                              Depreciation and normal wear and tear of the car
                              and its parts
                            </li>
                            <li>
                              When you are using the car in violation of its
                              usage limitations
                            </li>
                            <li>
                              When you are using the car for a criminal or
                              hazardous act
                            </li>
                            <li>Deliberate accidents or attempted suicide</li>
                          </ul>
                        </Grid>
                        <Grid xs={12} md={3}>
                          <img
                            src="./images/coverage-img.svg"
                            alt=""
                            className="coverage-img"
                          />
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </TabContext>
                </Box>
                {/* <Grid container columnSpacing={3}>
                  <Grid xs={12} md={9}>
                    <ul className="blue-square-list">
                      <li>
                        The personal accident cover is mandatory under car
                        insurance plans. However, if you already have a personal
                        accident cover in your name, you can skip it.
                      </li>
                      <li>
                        You can buy a standalone third party cover, standalone
                        own damage cover and the personal accident cover from
                        different insurers.
                      </li>
                      <li>
                        The long term liability cover for new cars, all other
                        plans are annual plans.
                      </li>
                      <li>
                        Every time the cover is renewed, you can switch
                        insurance companies easily if you want.
                      </li>
                      <li>
                        You can buy car insurance policy online or offline.
                      </li>
                    </ul>
                  </Grid>
                  <Grid xs={12} md={3} textAlign={"center"}>
                    <img
                      src="./images/product-car.svg"
                      alt=""
                      className="product-img"
                    />
                  </Grid>
                </Grid> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default CarProductPage;
