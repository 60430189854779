import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./../../../SCSS/CMSPages.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import FAQ from "../../../Component/FAQ/FAQ";

const FAQPage = () => {
  const isMobile = useIsMobile();
  const hiArray = [
    {
      title: " What is Health Insurance?",
      desc: `Health Insurance is medical insurance that covers the all medical Expenses when the policy holder is hospitalized.`,
    },
    {
      title: "Can I Save tax with health Insurance?",
      desc: `Yes, Health Insurance can help to reduce your tax income up to 1 Lakh under Section 80D of the Income Tax Act, 1961.`,
    },
    {
      title: "What is family floater plan?",
      desc: `It is a one single plan, which take care of the hospitalization expenses of your entire family.`,
    },
    {
      title: "Can person have more than one Health policy?",
      desc: `Yes.`,
    },
    {
      title: " What is the maximum number of claims allowed over a year?",
      desc: `He/she can claim any number of claims under a policy, within a year. But the claim amount cannot exceed the sum-insured amount.</`,
    },
  ];
  const miArray = [
    {
      title: " What is the period of the policy?",
      desc: `A motor policy valid for a period of one year and has to be renewed before the due date.`,
    },
    {
      title: "What is No Claim Bonus ?",
      desc: `A fixed discount % is applicable on the car insurance premium for nil claims.`,
    },
    {
      title: "Is GST applicable on motor insurance policy ?",
      desc: `Yes, GST rate of 18% is applicable`,
    },
    {
      title:
        "For accident, what documents should I submit for Motor insurance claim ?",
      desc: (
        <>
          <ul className="bluedot_list">
            <li>Copy of the insurance policy</li>
            <li>Tax receipt</li>
            <li>Copy of the Registraton book</li>
            <li>Copy of the driving licence</li>
            <li>FIR</li>
            <li>Estimate cost of repair</li>
          </ul>
        </>
      ),
    },
    {
      title:
        " For theft, what documents should I submit for Motor insurance Claim ?",
      desc: (
        <>
          <ul className="bluedot_list">
            <li>Original policy document</li>
            <li>Original registration book</li>
            <li>Tax Receipt</li>
            <li>All keys and service documentation</li>
            <li>FIR</li>
            <li>
              Copy of the letter to the Rto (which have mention about the theft)
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "What is the penalty if I drive without insurance ?",
      desc: (
        <>
          <p>You will be penalized by-</p>
          <ul className="bluedot_list">
            <li>Driving license and vehicle registration may be suspended</li>
            <li>
              You will have to pay a fine of Rs.2000 at first offense and 4000
              at second offense and/or face imprisonment for 3 months.
            </li>
          </ul>
        </>
      ),
    },
  ];
  return (
    <Box className="cmsPage-layout">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="contentPages-layout mb-margin1">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h2 className="mb-5">Frequently Asked Questions</h2>
            <p style={{ textAlign: "center" }}>
              We are here to solve all your doubts. Read below all the questions
              with answers about motor & health insurance Policy:
            </p>
          </Grid>
        </Grid>
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <Box className="faqbox">
              <Box className="headerBox">
                <h2>Health Insurance</h2>
              </Box>
              <Box className="contentBox">
                <FAQ data={hiArray} />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <Box className="faqbox">
              <Box className="headerBox">
                <h2>Motor Insurance</h2>
              </Box>
              <Box className="contentBox">
                <FAQ data={miArray} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default FAQPage;
