import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Grid";
import { subDays } from "date-fns";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../Component/InputFields/CustomButton/CustomButton";
import CustomCheckbox from "../../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../../Component/InputFields/DatePicker/DatePicker";
import RKTextField from "../../../../Component/InputFields/RKTextField/RKTextField";
import RadioText from "../../../../Component/InputFields/RadioText/RadioText";
import SearchDropdown from "../../../../Component/InputFields/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import Popup from "../../../../Component/Popup/Popup";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import { useAppSelector } from "../../../../Store/Store/hooks";
import {
  FORMAT_DD_MM_YYYY,
  calculateAgeInDays,
  isEmpty,
  validateRegno,
} from "../../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../../SupportingFiles/colors";
import { TCarForm } from "../../../../Type/Car/TCarSlice/TCarSlice";

interface Props {
  open: boolean;
  setOpen: Function;
  formData: TCarForm;
  setFormData: Function;
  updateMasterState: Function;
  validateForm: Function;
  GET_VEHICLE_INFO: Function;
  step: 1 | 2;
  setStep: Function;
}

const CarForm: React.FC<Props> = ({
  open,
  setOpen,
  formData,
  setFormData,
  updateMasterState,
  validateForm,
  GET_VEHICLE_INFO,
  step,
  setStep,
}) => {
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Car);
  const navigate = useNavigate();

  return (
    <Popup
      open={open}
      handleClose={() => {
        setOpen(false);
      }}
      variant="medium"
      title="Find the Best Car Insurance"
      content={
        <>
          {step === 2 ? (
            <Link
              sx={{
                position: "absolute",
                top: "20px",
                left: "12px",
                color: COLORS.black,
                display: "inline-block",
              }}
              onClick={() => {
                setStep(1);
              }}>
              <ChevronLeftRoundedIcon sx={{ fontSize: "30px" }} />
            </Link>
          ) : null}
          {step === 1 ? (
            formData.business_type === "Rollover" ? (
              <Box padding="24px">
                <Grid
                  container
                  spacing={2}
                  textAlign="center"
                  justifyContent={"center"}>
                  <Grid item xs={3}>
                    <RKTextField
                      class_name="inputField vehicleNumberField mb-3"
                      title={""}
                      value={formData.reg_no.value}
                      attrName={"reg_no"}
                      value_update={updateMasterState}
                      warn_status={formData.reg_no.warning}
                      error_message={
                        isEmpty(formData.reg_no.value)
                          ? "Enter Reg. Number"
                          : "Enter valid Reg. Number"
                      }
                      placeholder="MH01AB2345"
                      validation_type="ALL_CAPS"
                      max_length={11}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DatePicker
                      class_name="inputField"
                      title={"Reg. Date"}
                      value={formData.reg_date.value}
                      attrName={"reg_date"}
                      value_update={updateMasterState}
                      warn_status={formData.reg_date.warning}
                      error_message={"Select Reg. Date"}
                      min_date={15 * 12}
                      max_date={3}
                      date_validation_type="MONTHS"
                      default_date={subDays(new Date(), 90)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomButton
                      className="primaryBtn mb-2"
                      text="Continue"
                      onClick={() => {
                        let data = { ...formData };
                        if (step === 1) {
                          data = {
                            ...data,
                            reg_no: {
                              ...data.reg_no,
                              warning: !validateRegno(data.reg_no.value),
                            },
                            reg_date: {
                              ...data.reg_date,
                              warning: isEmpty(data.reg_date.value),
                            },
                          };
                          setFormData(data);
                          if (!data.reg_no.warning && !data.reg_date.warning) {
                            GET_VEHICLE_INFO();
                          }
                        } else {
                          validateForm();
                        }
                      }}
                      fullWidth={false}
                      variant="contained"
                    />
                  </Grid>

                  <Grid item xs={12} marginBottom={"80px"}>
                    <Link
                      sx={{ color: COLORS.black, fontWeight: "500" }}
                      onClick={() => {
                        setFormData((prev: TCarForm) => ({
                          ...prev,
                          rto: { value: "", warning: false },
                          business_type: "New",
                        }));
                      }}>
                      Bought a{" "}
                      <span style={{ color: COLORS.primary }}>New Car</span>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box padding="24px">
                <Grid
                  container
                  spacing={2}
                  textAlign="center"
                  justifyContent={"center"}>
                  <Grid item xs={8}>
                    <SearchDropdown
                      class_name="inputField mb-4"
                      title={"RTO"}
                      attrName={"rto"}
                      value={formData.rto.value}
                      value_update={updateMasterState}
                      data={DROPDOWN_DATA.RTO_LIST}
                      warn_status={formData.rto.warning}
                      error_message={"Select RTO "}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomButton
                      className="primaryBtn mb-2"
                      text="Continue"
                      onClick={() => {
                        if (formData.business_type === "New") {
                          setFormData((prev: TCarForm) => ({
                            ...prev,
                            rto: {
                              ...prev.rto,
                              warning: isEmpty(prev.rto.value),
                            },
                          }));
                          if (!isEmpty(formData.rto.value)) {
                            setStep(2);
                          }
                        } else {
                          validateForm();
                        }
                      }}
                      fullWidth={false}
                      variant="contained"
                    />
                  </Grid>

                  <Grid item xs={12} marginBottom={"80px"}>
                    <Link
                      sx={{ color: COLORS.black }}
                      onClick={() => {
                        setFormData((prev: TCarForm) => ({
                          ...prev,
                          reg_date: { value: "", warning: false },
                          reg_no: { value: "", warning: false },
                          business_type: "Rollover",
                        }));
                      }}>
                      Old
                      <span style={{ color: COLORS.primary }}> Car</span>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            )
          ) : (
            <Box padding="24px">
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent={"center"}>
                <Grid item xs={4}>
                  <SearchDropdown
                    class_name="inputField mb-4"
                    title={"Make & Model"}
                    attrName={"make_model"}
                    value={formData.make_model.value}
                    value_update={updateMasterState}
                    data={DROPDOWN_DATA.MAKE_MODEL_LIST}
                    warn_status={formData.make_model.warning}
                    error_message={"Select Make and Model"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectDropdown
                    class_name="inputField  mb-4"
                    title={"Fuel Type"}
                    attrName={"fuel_type"}
                    value={formData.fuel_type.value}
                    value_update={updateMasterState}
                    data={
                      isEmpty(formData.make_model.value)
                        ? []
                        : DROPDOWN_DATA.FUEL_TYPE_LIST
                    }
                    warn_status={formData.fuel_type.warning}
                    error_message={"Select Fuel Type"}
                  />
                </Grid>
                <Grid item xs={5}>
                  <SearchDropdown
                    class_name="inputField mb-4"
                    title={"Variant"}
                    attrName={"variant_cc"}
                    value={formData.variant_cc.value}
                    value_update={updateMasterState}
                    data={
                      isEmpty(formData.make_model.value) ||
                      isEmpty(formData.fuel_type.value)
                        ? []
                        : DROPDOWN_DATA.VARIANT_LIST
                    }
                    warn_status={formData.variant_cc.warning}
                    error_message={"Select Variant"}
                  />
                </Grid>
                {formData.business_type === "Rollover" ? (
                  <>
                    <Grid item xs={12} paddingTop={0}>
                      <h6
                        style={{
                          textAlign: "center",
                          fontWeight: 500,
                          marginBottom: "10px",
                        }}>
                        Policy Details
                      </h6>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
                      <p style={{ textAlign: "center", marginBottom: "12px" }}>
                        Please enter all the essential details pertaining to the
                        policy.
                      </p>
                    </Grid>
                    {formData.previous_insurer.value ===
                    "Do not remember" ? null : (
                      <Grid item xs={3}>
                        <DatePicker
                          class_name="inputField"
                          title={"Policy Expiry"}
                          value={formData.policy_expiry_date.value}
                          attrName={"policy_expiry_date"}
                          value_update={updateMasterState}
                          warn_status={formData.policy_expiry_date.warning}
                          error_message={"Select Policy Expiry Date"}
                          date_validation_type="DAYS"
                          min_date={
                            calculateAgeInDays(`${formData.reg_date.value}`) -
                            90
                          }
                          max_date={
                            calculateAgeInDays(
                              FORMAT_DD_MM_YYYY(`${new Date()}`)
                            ) - 60
                          }
                        />
                      </Grid>
                    )}
                    <Grid item xs={3}>
                      <SearchDropdown
                        class_name="inputField  mb-4"
                        title={"Previous Insurer"}
                        attrName={"previous_insurer"}
                        value={formData.previous_insurer.value}
                        value_update={updateMasterState}
                        data={DROPDOWN_DATA.PREVIOUS_INSURER_LIST}
                        warn_status={formData.previous_insurer.warning}
                        error_message={"Select Previous Insurer"}
                      />
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={3}>
                  <RKTextField
                    class_name="inputField mb-3"
                    title={"Full Name"}
                    value={formData.owner_name.value}
                    attrName={"owner_name"}
                    value_update={updateMasterState}
                    warn_status={formData.owner_name.warning}
                    error_message={
                      isEmpty(formData.owner_name.value)
                        ? "Enter Full Name"
                        : "Enter Valid Full Name"
                    }
                    validation_type="NAME"
                  />
                </Grid>
                <Grid item xs={3}>
                  <RKTextField
                    class_name="inputField mb-3"
                    title={"Mobile"}
                    value={formData.mobile.value}
                    attrName={"mobile"}
                    value_update={updateMasterState}
                    warn_status={formData.mobile.warning}
                    error_message={
                      isEmpty(formData.mobile.value)
                        ? "Enter Mobile Number"
                        : "Enter Valid Mobile Number"
                    }
                    max_length={10}
                    validation_type="NUMBER"
                  />
                </Grid>
                {formData.business_type === "Rollover" &&
                formData.previous_insurer.value !== "Do not remember" ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      gap="12px"
                      alignItems="center"
                      justifyContent="center"
                      marginBottom={"8px"}>
                      <h6>Claimed in the last year?</h6>
                      <RadioText
                        count="AUTO"
                        orientation="row"
                        variant="small"
                        attrName="claimed"
                        options={DROPDOWN_DATA.CLAIM_STATUS}
                        onChange={(e: any) => {
                          updateMasterState("claimed", e.target.value);
                        }}
                        value={formData.claimed}
                      />
                    </Grid>
                    {formData.claimed === "No" ? (
                      <Grid item xs={12} marginBottom={"16px"}>
                        <h6>Previous No Claim Bonus (NCB)</h6>
                        <p
                          className="my-2"
                          style={{ fontSize: "12px", color: COLORS.darkGrey }}>
                          {" "}
                          (Check it in your last policy){" "}
                        </p>
                        <RadioText
                          count="AUTO"
                          orientation="row"
                          variant="small"
                          attrName="ncb"
                          options={[
                            { label: "0%", value: "0" },
                            { label: "20%", value: "20" },
                            { label: "25%", value: "25" },
                            { label: "35%", value: "35" },
                            { label: "45%", value: "45" },
                            { label: "50%", value: "50" },
                          ]}
                          onChange={(e: any) =>
                            updateMasterState("prev_ncb", e.target.value)
                          }
                          value={formData.prev_ncb}
                        />
                      </Grid>
                    ) : null}
                  </>
                ) : null}
                <Grid item xs={12}>
                  <CustomButton
                    className="primaryBtn mb-2"
                    text="View Quotes"
                    onClick={() => {
                      validateForm();
                    }}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid item xs={12} className="popup-Checkbox">
                  <CustomCheckbox
                    label={
                      <>
                        By clicking on view quotes you agree to all
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(COMMON_ROUTES.TERM_AND_CONDTION)
                          }>
                          {" "}
                          Terms & Conditions.
                        </span>
                      </>
                    }
                    isChecked={true}
                    defaultChecked={true}
                    attrName={"termCondition"}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      }
    />
  );
};

export default CarForm;
