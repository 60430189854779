import React from "react";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MFooter.scss";
import { COLORS } from "../../../../SupportingFiles/colors";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";

function MFooter() {
  const navigate = useNavigate();
  return (
    <Box className="mfooter">
      <Grid container columnSpacing={3} marginBottom={"24px"}>
        <Grid xs={6}>
          <Box>
            <img
              src="./images/jb-boda-footer.svg"
              alt="walnnut Insurance"
              className="jb_footer_img"
            />
          </Box>
        </Grid>
        <Grid xs={6}>
          <p className="mb-1">
            <span>Phone :</span> <a href="tel:+0226 7817 835">0226 7817 835</a>
            <span style={{ display: "block", fontWeight: 400 }}>
              (working hours: 10:00am - 6:30pm)
            </span>
          </p>
          <p className="mb-1">
            <span>Email :</span>{" "}
            <a href="mail: info@walnnut.com"> info@walnnut.com</a>
          </p>
          <p>
            <span>Address :</span> J.B.Boda Group, Maker Bhavan 1, Sir V.
            Thackersey Marg, Mumbai - 400 020
          </p>
        </Grid>
      </Grid>
      <Grid container columnSpacing={3} marginBottom={"24px"}>
        <Grid xs={6}>
          <ul>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.ABOUT_US);
                }}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.CONTACT_US);
                }}
              >
                Contact
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.CLAIM);
                }}
              >
                Claim
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.COMPLAINT);
                }}
              >
                Complaints
              </Link>
            </li>
            {/* <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.CAREER);
                }}
              >
                Career
              </Link>
            </li> */}
          </ul>
        </Grid>
        <Grid xs={6}>
          <ul>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                }}
              >
                Health insurance
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.PRODUCT_CAR);
                }}
              >
                Car insurance
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.PRODUCT_TW);
                }}
              >
                Bike insurance
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={12}>
          <ul
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: "24px",
            }}
          >
            <li style={{ width: "48%" }}>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.DISCLAIMER);
                }}
              >
                Disclaimer
              </Link>
            </li>
            <li style={{ width: "48%" }}>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                }}
              >
                Terms & Conditions
              </Link>
            </li>
            <li style={{ width: "48%" }}>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.PRIVACY_POLICY);
                }}
              >
                Privacy Policy
              </Link>
            </li>
            <li style={{ width: "48%" }}>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.REFUND_CANCELATION);
                }}
              >
                Cancellation & Refund Policy
              </Link>
            </li>
            <li style={{ width: "48%" }}>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.FAQ);
                }}
              >
                FAQ
              </Link>
            </li>
          </ul>
        </Grid>
      </Grid>

      <Grid container columnSpacing={3} marginBottom={"24px"}>
        <Grid xs={6}>
          <p>
            <span>Social Links</span>
          </p>
        </Grid>
        <Grid xs={6} textAlign={"right"}>
          <Box className="social-icons">
            <a
              href="https://www.linkedin.com/company/walnnutjbboda/"
              target="_blank"
            >
              <svg
                width="25"
                height="26"
                viewBox="0 0 25 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.875 1.93091H3.12012C2.26074 1.93091 1.5625 2.63892 1.5625 3.50806V22.2288C1.5625 23.0979 2.26074 23.8059 3.12012 23.8059H21.875C22.7344 23.8059 23.4375 23.0979 23.4375 22.2288V3.50806C23.4375 2.63892 22.7344 1.93091 21.875 1.93091ZM8.17383 20.6809H4.93164V10.2415H8.17871V20.6809H8.17383ZM6.55273 8.81567C5.5127 8.81567 4.67285 7.97095 4.67285 6.93579C4.67285 5.90063 5.5127 5.05591 6.55273 5.05591C7.58789 5.05591 8.43262 5.90063 8.43262 6.93579C8.43262 7.97583 7.59277 8.81567 6.55273 8.81567ZM20.3271 20.6809H17.085V15.6028C17.085 14.3918 17.0605 12.8342 15.4004 12.8342C13.7109 12.8342 13.4521 14.1526 13.4521 15.5149V20.6809H10.21V10.2415H13.3203V11.6672H13.3643C13.7988 10.8469 14.8584 9.98267 16.4355 9.98267C19.7168 9.98267 20.3271 12.1458 20.3271 14.9583V20.6809Z"
                  fill="white"
                />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/walnnut.official/"
              target="_blank"
            >
              <svg
                width="25"
                height="26"
                viewBox="0 0 25 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="2"
                  y="2.36841"
                  width="21"
                  height="21"
                  rx="3"
                  fill="white"
                />
                <path
                  d="M12.5 10.5251C13.8619 10.5251 14.9734 11.5781 14.9734 12.8683C14.9734 14.1585 13.8619 15.2115 12.5 15.2115C11.1381 15.2115 10.0267 14.1585 10.0267 12.8683C10.0267 11.5781 11.1381 10.5251 12.5 10.5251ZM5.08187 12.8683C5.08187 11.898 5.07259 10.9365 5.13011 9.96791C5.18763 8.84291 5.45853 7.84447 6.32689 7.02181C7.1971 6.1974 8.24916 5.94252 9.43666 5.88802C10.4609 5.83353 11.4758 5.84232 12.4982 5.84232C13.5224 5.84232 14.5373 5.83353 15.5597 5.88802C16.7472 5.94252 17.8011 6.19916 18.6695 7.02181C19.5397 7.84623 19.8087 8.84291 19.8662 9.96791C19.9238 10.9382 19.9145 11.8997 19.9145 12.8683C19.9145 13.8369 19.9238 14.8001 19.8662 15.7687C19.8087 16.8937 19.5378 17.8921 18.6695 18.7148C17.7993 19.5392 16.7472 19.7941 15.5597 19.8486C14.5355 19.9031 13.5205 19.8943 12.4982 19.8943C11.474 19.8943 10.459 19.9031 9.43666 19.8486C8.24916 19.7941 7.19525 19.5374 6.32689 18.7148C5.45667 17.8904 5.18763 16.8937 5.13011 15.7687C5.07074 14.8001 5.08187 13.8386 5.08187 12.8683ZM12.5 16.4736C14.606 16.4736 16.3056 14.8634 16.3056 12.8683C16.3056 10.8732 14.606 9.26302 12.5 9.26302C10.3941 9.26302 8.69447 10.8732 8.69447 12.8683C8.69447 14.8634 10.3941 16.4736 12.5 16.4736ZM8.53861 9.95736C9.03031 9.95736 9.42738 9.58119 9.42738 9.11537C9.42738 8.64955 9.03031 8.27337 8.53861 8.27337C8.04691 8.27337 7.64984 8.64955 7.64984 9.11537C7.64969 9.22598 7.67258 9.33553 7.71719 9.43775C7.76181 9.53997 7.82727 9.63284 7.90983 9.71105C7.99239 9.78927 8.09042 9.85128 8.19832 9.89355C8.30621 9.93581 8.42185 9.9575 8.53861 9.95736Z"
                  fill="#0C4D85"
                />
              </svg>
            </a>
          </Box>
        </Grid>
      </Grid>
      <Grid container columnSpacing={3} marginBottom={"24px"}>
        <Grid xs={12} textAlign={"center"}>
          <p
            style={{
              display: "inline-block",
              color: "rgba(14, 14, 14, 0.56)",
              padding: "8px ",
              borderRadius: "5px",
              background: COLORS.white,
              fontSize: "12px",
              lineHeight: "18px",
              textAlign: "justify",
            }}
          >
            Disclaimer for Web Portal: *All discounts provided by insurers as
            per IRDAI approved insurance plans. Standard T&C apply. Insurance is
            the subject matter of solicitation. For more details on policy
            terms, conditions, exclusions, limitations, please refer/read policy
            brochure carefully before concluding sale. Visitors are hereby
            informed that their information submitted on the website may be
            shared with insurers for soliciting insurance policies. The product
            information for comparison displayed on this website is of the
            insurers with whom our company has an agreement. Product information
            is authentic and solely based on the information received from the
            Insurer.
          </p>
        </Grid>
      </Grid>
      <Grid container className="row" columnSpacing={3}>
        <Grid xs={12} textAlign={"center"}>
          <p className="copyright_p">
            Copyright © {new Date().getFullYear()} J.B.BODA INSURANCE &amp;
            REINSURANCE BROKERS Pvt. Ltd. All rights reserved.
          </p>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MFooter;
