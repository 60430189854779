import { PayloadAction } from "@reduxjs/toolkit";
import {
  DROPDOWN_DATA,
  TCarForm,
  TCarSlice,
} from "../../../Type/Car/TCarSlice/TCarSlice";
import { TDropdownData } from "../../../Type/DropdownData/DropdownData";

function ADD_FORM_DATA(state: TCarSlice, action: PayloadAction<TCarForm>) {
  let data: TCarSlice = { ...state };

  data = { ...data, ADD_FORM: { ...data.ADD_FORM, ...action.payload } };

  return data;
}

type valueTypeDropdown = keyof DROPDOWN_DATA;
function SET_DROPDOWN_DATA(
  state: TCarSlice,
  action: PayloadAction<{ key: valueTypeDropdown; value: TDropdownData[] }>
) {
  const { key, value } = action.payload;
  let data: TCarSlice = { ...state };

  data = {
    ...data,
    DROPDOWN_DATA: { ...data.DROPDOWN_DATA, [key]: value },
  };

  return data;
}


type keyTCarAddForm = keyof TCarForm;
function UPDATE_FORM_DATA(
  state: TCarSlice,
  action: PayloadAction<{ key: keyTCarAddForm; value: any }[]>
) {
  const updates = action.payload;
  let data: TCarSlice = { ...state };

  updates.forEach(({ key, value }) => {
    data = { ...data, ADD_FORM: { ...data.ADD_FORM, [key]: value } };
  });

  return data;
}

export const CAR_REDUCERS = {
  ADD_FORM_DATA,
  SET_DROPDOWN_DATA,
  UPDATE_FORM_DATA,
};

export type CarReducers = typeof CAR_REDUCERS;
