import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./../../../SCSS/CMSPages.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";

const RefundCancellation = () => {
  const isMobile = useIsMobile();
  return (
    <Box className="cmsPage-layout">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="contentPages-layout mb-margin1">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h2 className="mb-5">Cancellation and Refund policy</h2>
            <p style={{ textAlign: "center" }}>
              The payment of insurance premiums paid towards the insurance
              policy directly goes to the respective insurance company. The
              respective insurance company should be contacted in case of
              cancellation of policy or request for a refund of premium. All the
              requests are subject to the underwriting guidelines of the
              respective insurance company. Kindly read the terms and conditions
              of cancelling before submitting the request. In case you require
              any assistance/information, kindly contact our{" "}
              <a href="tel:0226 7817 835" target="_blank">
                0226 7817 835
              </a>{" "}
              or write us on{" "}
              <a href="mailto:info@walnnut.com" target="_blank">
                info@walnnut.com
              </a>
            </p>
          </Grid>
         
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default RefundCancellation;
