import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./../../../SCSS/CMSPages.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";

const TermsCondition = () => {
  const isMobile = useIsMobile();
  return (
    <Box className="cmsPage-layout">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="contentPages-layout mb-margin1">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h2 className="mb-5">Terms and Conditions</h2>
            <p>
              J.B.Boda Insurance and Reinsurance Brokers Private Limited
              (hereinafter referred to as “J.B.Boda” or “We” or “Us” or “Our”)
              own the domain www.walnnut.com or mobile applications thereof
              accessible through electronic devices using internet. J.B.Boda is
              a composite broker regulated by the Insurance Regulatory and
              Development Authority of India (“IRDAI”) and our e-commerce
              platform www.walnnut.com is duly regulated under the applicable
              regulations of IRDAI for providing insurance products from
              licensed insurance companies and related services.
            </p>
            <p>
              THESE TERMS OF USE ("Terms Of Use") IS AN ELECTRONIC RECORD IN THE
              FORM OF AN ELECTRONIC CONTRACT FORMED UNDER INFORMATION TECHNOLOGY
              ACT, 2000 AND RULES MADE UNDER THE INFORMATION TECHNOLOGY ACT,
              2000.
            </p>
            <p>
              Your access to and use of this Web Site is subject to the
              following Terms of Use, as well as all applicable laws and
              regulations. Please read these terms carefully. If you do not
              accept and agree to be bound by any of these terms or conditions
              you are not authorized to access or otherwise use this Web Site or
              any Content, Services or Software contained on this Web Site. Your
              access to and use of this Web Site constitute your acceptance of
              and agreement to abide by each of these terms and conditions set
              forth below.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <Box className="formBox">
              <h3 className="mb-2">Term of Use</h3>
              <ul className="numericList">
                <li>
                  1. While J.B.Boda has made its best efforts to ensure that the
                  material on this website was true and accurte at the time of
                  inclusion, you should be aware that J.B.Boda assumes no
                  responsibility if the information is incomplete, incorrect, or
                  out of date.
                </li>
                <li>
                  2. J.B.Boda is not responsible for the content or usage of any
                  web pages or any communication transmitted or received by you
                  unless otherwise stated on this website.
                </li>
                <li>
                  3. J.B.Boda and its licensors own all current and future
                  copyright trademarks and other intellectual property rights
                  linked to this website. Except for the restricted right to use
                  the website in line with the conditions mentioned herein, you
                  acquire no additional rights to the website. You promise to
                  respect J.B.Boda and its licensors' intellectual rights and to
                  quickly notify the J.B.Boda in writing (contact information
                  below) if you become aware of any unauthorised access to, or
                  use of, the website that violates any proprietary rights.
                </li>
                <li>
                  4. You agree not to use this website for any illegal reasons,
                  and in particular, you agree not to send, use, copy, post, or
                  enable any content that is defamatory or obscene, or that is
                  abusive, indecent, or infringes on someone's privacy. You
                  agree not to transmit any unsolicited commercial or
                  advertising material, spam or similar materials, or any volume
                  of communications that may disrupt the functioning of our
                  website or other visitors' enjoyment of it.
                </li>
                <li>
                  5. The descriptions of products and services contained on this
                  Website are intended to provide a reference guide to the types
                  of products and services offered by J.B.Bodaandhave been
                  prepared without taking into account your
                  requirements,objectives, or financial situation. You are
                  responsible for the final product and service selected by you
                  and you should read all the information provided therein
                  before proceeding. Please contact us directly if you wish to
                  obtain specific information concerning the products and
                  services referenced on this Website.
                </li>
                <li>
                  6. J.B.Boda maintains the right to enhance, modify, amend,
                  suspend, or permanently cease all or any portion of this
                  website, as well as to limit or ban access to it, at any time
                  and without notice.
                </li>
                <li>
                  7. J.B.Boda takes no responsibility for the final issuance of
                  the policy, it is subject to underwriting norms and the
                  discretion of the insurer whose product or service you have
                  chosen.
                </li>
                <li>
                  8. You undertake to hold J.B.Boda harmless from any expenses,
                  claims, losses, or damages (including legal fees) incurred by
                  or awarded against J.B.Boda as a consequence of your use of
                  this website or your violation of these conditions.
                </li>
                <li>
                  9. While this website may include links to other websites, it
                  is not responsible for the content of those sites or any risk
                  associated with third-party websites. Such sites are not under
                  the authority of J.B.Boda. J.B.Boda disclaims any
                  responsibility for the accuracy or otherwise of any content or
                  the security of any activity conducted on external sites. You
                  may visit such sites entirely at your own risk.
                </li>
                <li>
                  10. If you violate these conditions, J.B.Boda may suspend your
                  access to this website and refuse to provide you with any
                  additional access at its discretion.
                </li>
                <li>
                  11. This website is provided to you without any warranties or
                  guarantees unless expressly stated, and J.B.Boda accepts no
                  liability to you, whether in contract, tort (including
                  negligence), or otherwise, arising out of or in connection
                  with this website (except in the case of personal injury or
                  death caused by its negligence or for fraud). In the event of
                  death or personal injury caused directly by J.B.Boda's
                  carelessness, J.B.Boda's culpability shall not be restricted.
                </li>
                <li>
                  12. By accessing this website, you agree to the terms of use,
                  and any revisions, and modifications.All changes are effective
                  immediately upon posting and apply to all access to and use of
                  the website thereafter.Any changes will not affect any
                  disputes arising prior to the effective date of the change.We
                  may send you notices with respect to the service in various
                  mediums, including by email address, postal mail, and/or by
                  posting on the website.
                </li>
                <li>
                  13. These terms and conditions, as well as the content of this
                  website, are governed by Indian law, with exclusive authority
                  over the parties.
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">Privacy</h3>
            <p>
              Your use of the Service is also subject to our Privacy Policy that
              is available online here (“Privacy Policy”) and is incorporated
              into and made a part of these Terms of Use. The Privacy Policy is
              subject to change, and you are encouraged to review the Privacy
              Policy whenever you supply the Website with any information.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">J.B.Boda Terms of Business</h3>
            <p>
              J.B.Boda is dedicated to becoming the world's most valuable and
              service-oriented insurance brokerage company. In the absence of
              contradictory provisions in a formal services agreement entered by
              and between J.B.Boda and Client, the specific services J.B.Boda
              firm delivers to each client (“Client”) will be subject to the
              terms of business are defined as direct to the customer.The
              client's request for J.B.Boda to provide consultancy,
              insurance/risk management consulting, and/or brokerage services on
              its behalf is deemed Client's consent and will be bound by the
              conditions set forth above.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">Accuracy and Completeness of Information</h3>
            <p>
              The client is entirely responsible for the accuracy and
              completeness of any information provided to J.B.Boda and/or
              underwriters, insurers, insurance-related intermediaries, and/or
              other third parties as may be required for the services envisaged
              herein. J.B.Boda is not responsible for independently confirming
              the accuracy or completeness of any information provided by the
              Client, and it is authorised to rely on such information. J.B.Boda
              will not be liable for any mistakes or omissions in any services
              offered to Client, including insurance placement on Client's
              behalf, that is the consequence of, originate from, or are based,
              in whole or part, on erroneous or incomplete information submitted
              to J.B.Boda.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">Limitation of liability</h3>
            <p className="mb-2">
              Subject to the terms stated herein, neither J.B.Boda nor any of
              its officers, directors, employees, agents or related bodies
              corporate shall be liable for any direct, indirect, incidental,
              consequential or special damages arising out of or in any way
              connected with access to or use of the Site or the Content (even
              if J.B.Boda has been advised of the possibility of such damages),
              including liability associated with any viruses which may infect a
              user’s computer equipment.
            </p>
            <p>
              If J.B.Boda cannot lawfully exclude or modify its liability under
              a statutory term, condition or warranty; that term, condition or
              warranty is deemed to be included in these Terms of Use and
              liability under the terms stated above at the option of J.B.Boda ,
              is limited to the supply of the services again or the payment of
              having the services resupplied.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">Indemnity</h3>
            <p>
              You agree to indemnify Us and keep Us indemnified (including Our
              successors and assigns, Our directors, officers, employees and
              agents) from and against all liabilities, claims, losses, costs,
              damages and expenses (including legal fees) which are reasonably
              incurred by Us (including Our successors and assigns, Our
              directors, officers, employees and agents) arising from your use
              of the Website, your breach of the Terms and Conditions, or your
              violation of any third party rights.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">
              Confidentiality; Data Protection Information Notice
            </h3>
            <p className="mb-2">
              J.B.Boda and Client acknowledge that the nature of J.B.Boda's
              relationship with the Client entails the Client disclosing to
              J.B.Boda certain of the Client's information (“Information”), some
              of which may be confidential or proprietary, for J.B.Boda to
              provide services to the Client. The customer agrees to J.B.Boda's
              use and dissemination of information in the course of conducting
              marketing, servicing, claims handling, risk management, and/or
              policy renewal services for the client.
            </p>
            <p className="mb-2">
              According to relevant data privacy legislation or regulation,
              J.B.Boda is committed to maintaining the privacy and security of
              Client's Information regarded to be personal data. J.B.Boda may
              receive such personal data directly from the Client, in the event
              of an individual Client, or indirectly, in the case of a
              commercial Client on behalf of its employee insureds, while
              providing regulated insurance broking services. J.B.Boda is in
              charge of choosing how personal data about the Client is stored
              and used.
            </p>
            <p className="mb-2">
              In its capacity as an insurance intermediary, J.B.Boda may utilise
              personal data collected from the Client. This may involve
              quotation/inception, policy administration, claims processing,
              renewals, marketing, and other activities related to the supply of
              insurance over the insurance lifecycle.
            </p>
            <p>
              Please see J.B.Boda's Privacy Notice on our website for more
              details. Please email info@walnnut.com if you have any queries
              concerning the Privacy Notice or J.B.Boda's acquisition or use of
              Client personal data.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">Service Provider</h3>
            <p>
              J.B.Boda may use the services of international or domestic service
              providers to help in the servicing, marketing, and/or placement of
              the Client's insurance/risk management.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">Dispute Resolution</h3>
            <p className="mb-2">
              In case of any dispute or difference arising between the parties
              out of or in relation to these terms and conditions, both parties
              hereby agree to settle the said dispute or difference amicably
              among themselves in the first instance. If the said dispute or
              difference is not settled amicably between the parties within 30
              days from the date on which the said dispute or difference first
              arose, the same shall be referred to Arbitration, comprising of
              sole arbitrator appointed by J.B.Boda. The Arbitration Tribunal
              shall be governed by the provisions of the Arbitration and
              Conciliation Act, 1996 and the award passed by such Tribunal,
              including an interim award, shall be final and binding on the
              parties. The venue for the arbitration shall be at Mumbai and the
              proceedings shall be conducted in the English language. The award
              shall be final and binding and the Parties hereby waive all means
              of recourse to the courts of any country.
            </p>
            <p>
              In the event that any provision of these terms is held
              unenforceable, the validity or enforceability of the remaining
              provisions will not be affected, and the unenforceable provision
              will be replaced with an enforceable provision that comes close to
              the intention underlying the unenforceable provision.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">Errors and Questions</h3>
            <p className="mb-2">
              In case of errors or questions about your transactions via this
              website, you should notify us as soon as possible via one of the
              following:
            </p>
            <p>
              Write us: Corporate Office : J.B.Boda Insurance and Reinsurance
              Brokers Private Limited, Maker Bhavan 1, Sir V. Thackersey Marg
            </p>
            <p>
              Email us:{" "}
              <a href="mailto:info@walnnut.com" target="_blank">
                info@walnnut.com
              </a>
            </p>
            <p>
              Call us:{" "}
              <a href="tel:0226 7817 835" target="_blank">
                0226 7817 835
              </a>
            </p>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default TermsCondition;
