import { FormControl, RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import React from "react";
import { COLORS } from "../../../SupportingFiles/colors";

const RadioText = ({
  attrName,
  onChange,
  value,
  defaultValue = "",
  disabled = false,
  options,
  variant = "default",
  orientation = "row",
  count = "1",
  checked = "Yes",
  justifyContent,
}: {
  orientation?: "row" | "column";
  justifyContent?: string;
  attrName: string;
  onChange: any;
  value: string;
  defaultValue?: string;
  disabled?: boolean;
  options: Array<{ value: string; label: string }>;
  variant?: "default" | "large" | "medium" | "small" | "xsmall";
  count?: "1" | "2" | "3" | "4" | "AUTO";
  checked?: any;
}) => {
  return (
    <FormControl>
      <RadioGroup
        row={orientation === "row"}
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={defaultValue}
        name="radio-buttons-group"
        sx={{
          gap: "12px",
          alignSelf: "stretch",
          justifyContent: justifyContent,
        }}
      >
        {options.map((data, index) => (
          <FormControlLabel
            sx={{
              ".MuiRadio-root": {
                display: "none",
              },
              flexGrow: count === "AUTO" ? "0" : "1",
              width:
                count === "1"
                  ? "100%"
                  : count === "2"
                  ? "40%"
                  : count === "3"
                  ? "25%"
                  : count === "4"
                  ? "20%"
                  : "auto",
              borderRadius: "8px",
              padding:
                variant === "default"
                  ? "5px"
                  : variant === "medium"
                  ? "5px 16px"
                  : variant === "small"
                  ? "4px 16px"
                  : variant === "xsmall"
                  ? "4px 16px"
                  : variant === "large"
                  ? "7px 24px"
                  : "7px 16px",
              margin: "0px",
              justifyContent: "center",
              boxShadow: "0px 4px 8px rgba(0,0,0,0.15)",
              backgroundColor:
                data.value === value ? "rgba(216, 150, 65, 0.4)" : COLORS.white,
            }}
            value={data.value}
            control={
              <Radio
                onChange={onChange}
                checked={data.value === value}
                disabled={disabled}
              />
            }
            label={
              <span
                style={{
                  fontSize: variant === "xsmall" ? "12px" : "14px",
                  lineHeight: variant === "xsmall" ? "18px" : "21px",
                  fontWeight: "500",
                  color: "#000",
                }}
              >
                {data.label}
              </span>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioText;
