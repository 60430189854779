import React, { useState } from "react";
import { Box, Link, Button, Menu, MenuItem, Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Grid from "@mui/material/Unstable_Grid2";
import "./MNavbar.scss";
import { COLORS } from "../../../../SupportingFiles/colors";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MLoginPopup from "./MLoginPopup/MLoginPopup";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";
import LoginContainer from "../../../../Container/MyAccount/LoginContainer/LoginContainer";

import { MY_ACCOUNT_ROUTES } from "../../../../Routing/Path/MyAccountRoutes";
import { useAppDispatch, useAppSelector } from "../../../../Store/Store/hooks";
import { SESSION_CONSTANTS } from "../../../../Services/Constants/SessionConstants";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";
import { HomeSlice } from "../../../../Store/Reducer/Home/HomeSlice";
import { UserProfileSlice } from "../../../../Store/Reducer/Account/UserProfileSlice";

function MNavBar() {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openLoginPopup, setOpenLoginPopup] = React.useState(false);
  const [openProductMenu, setOpenProductMenu] = useState(false);
  const [openLoginMenu, setOpenLoginMenu] = useState(false);

  const dispatch = useAppDispatch();
  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);

  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    sessionStorage.removeItem(SESSION_CONSTANTS.USER_MOBILE_NO);
    navigate("/");
  };

  const toggleDrawer =
    (anchor: "right", open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenMenu(!openMenu);
    };

  return (
    <Box className="mnavbar">
      <LoginContainer open={openLoginPopup} setOpen={setOpenLoginPopup} />
      <Grid container className="row" columnSpacing={0} alignItems={"center"}>
        <Grid xs={12}>
          <Box textAlign={"right"} padding={"8px"}>
            <a
              style={{
                lineHeight: "14px",
                fontWeight: 400,
                color: COLORS.primary,
                fontSize: "14px",
                display: "inline-flex",
                alignItems: "center",
                padding: "4px 0px",
              }}
              href="mailto:mailto:info@walnnut.com"
            >
              <svg
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="#0C4D85"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: "4px" }}
              >
                <path
                  d="M14.5136 0.504938C14.4497 0.498354 14.3853 0.498354 14.3214 0.504938H1.19644C1.11232 0.506234 1.02875 0.518847 0.947998 0.542438L7.72144 7.28775L14.5136 0.504938Z"
                  fill="#0C4D85"
                />
                <path
                  d="M15.2074 1.15649L8.38242 7.95337C8.20677 8.12798 7.96916 8.22599 7.72148 8.22599C7.47381 8.22599 7.2362 8.12798 7.06055 7.95337L0.296484 1.20806C0.27569 1.28448 0.264665 1.36323 0.263672 1.44243V10.8174C0.263672 11.0661 0.362444 11.3045 0.538259 11.4803C0.714075 11.6562 0.952531 11.7549 1.20117 11.7549H14.3262C14.5748 11.7549 14.8133 11.6562 14.9891 11.4803C15.1649 11.3045 15.2637 11.0661 15.2637 10.8174V1.44243C15.2599 1.34477 15.241 1.24829 15.2074 1.15649ZM1.84336 10.8174H1.1918V10.1471L4.59961 6.76743L5.26055 7.42837L1.84336 10.8174ZM14.3168 10.8174H13.6605L10.2434 7.42837L10.9043 6.76743L14.3121 10.1471L14.3168 10.8174Z"
                  fill="#0C4D85"
                />
              </svg>
              info@walnnut.com
            </a>
            <p
              style={{
                lineHeight: "22px",
                fontWeight: 400,
                color: COLORS.primary,
                fontSize: "14px",
              }}
            >
              <a
                href="tel:+022 6781 7835"
                style={{
                  lineHeight: "14px",
                  fontWeight: 400,
                  color: COLORS.primary,
                  fontSize: "14px",
                  padding: "4px 0px",
                }}
                className="blinker_a"
              >
                <svg
                  width="11"
                  height="14"
                  viewBox="0 0 11 14"
                  fill="#0C4D85"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginRight: "6px",
                    position: "relative",
                    top: "1px",
                  }}
                >
                  <path
                    d="M4.66611 6.0391L5.74011 5.0551C6.03378 4.78552 6.24018 4.43433 6.33284 4.04661C6.42549 3.65889 6.40017 3.25232 6.26011 2.8791L5.80211 1.6561C5.631 1.19952 5.29225 0.825346 4.85488 0.609819C4.41751 0.394292 3.91442 0.353623 3.44811 0.496097C1.73211 1.0211 0.413111 2.6161 0.819111 4.5101C1.08611 5.7561 1.59711 7.3201 2.56511 8.9841C3.53511 10.6521 4.64311 11.8841 5.59311 12.7501C7.02711 14.0551 9.07711 13.7291 10.3961 12.4991C10.7497 12.1694 10.9642 11.7173 10.996 11.235C11.0278 10.7526 10.8744 10.2763 10.5671 9.9031L9.72711 8.8831C9.47377 8.57465 9.13392 8.34906 8.7513 8.23536C8.36868 8.12166 7.96079 8.12505 7.58011 8.2451L6.19211 8.6821C5.83366 8.31217 5.51954 7.90174 5.25611 7.4591C5.00184 7.01145 4.80368 6.53419 4.66611 6.0381V6.0391Z"
                    fill="#0C4D85"
                  />
                </svg>
                022 6781 7835
              </a>{" "}
              (working hours 10:00am - 6:30pm)
            </p>
          </Box>
        </Grid>
      </Grid>
      <Box className="logo-strip">
        <Grid container className="row" columnSpacing={3} alignItems={"center"}>
          <Grid xs={8}>
            <img
              src="./images/walnnut-logo.png"
              height="27px"
              alt="walnnut Insurance"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(COMMON_ROUTES.HOME);
              }}
              style={{ cursor: "pointer" }}
            />
          </Grid>
          <Grid xs={4} textAlign={"right"}>
            <Link
              className="hamburger-menu"
              onClick={toggleDrawer("right", true)}
            >
              <svg
                width="11"
                height="10"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1778_44105)">
                  <path
                    d="M10.5 1.2037L0.5 1.2037L0.5 0L10.5 0V1.2037Z"
                    fill="white"
                  />
                  <path
                    d="M10.5 5.40744L0.5 5.40744L0.5 4.20374L10.5 4.20374V5.40744Z"
                    fill="white"
                  />
                  <path
                    d="M10.5 9.61117H0.5L0.5 8.40747H10.5V9.61117Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1778_44105">
                    <rect width="11" height="10" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Link>
          </Grid>
        </Grid>
      </Box>

      {/* sidemenu */}
      <SwipeableDrawer
        anchor="right"
        open={openMenu}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        <Box className="menuWrapper">
          <Box className="menu-section">
            <ul>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}
                >
                  About Us
                </Link>
              </li>

              <li>
                <Link
                  className="nav-link"
                  onClick={() => setOpenProductMenu(!openProductMenu)}
                >
                  Products <KeyboardArrowDownIcon />
                </Link>
                <Collapse in={openProductMenu} timeout="auto" unmountOnExit>
                  <Link
                    className="nav-link-inner"
                    onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}
                  >
                    Health Insurance
                  </Link>
                  <Link
                    className="nav-link-inner"
                    onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}
                  >
                    Car Insurance
                  </Link>
                  <Link
                    className="nav-link-inner"
                    onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}
                  >
                    Bike Insurance
                  </Link>
                </Collapse>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.CLAIM)}
                >
                  Claim
                </Link>
              </li>

              <li>
                <Link
                  className="nav-link"
                  onClick={() => setOpenLoginMenu(!openLoginMenu)}
                >
                  {USER_DATA.mobile.value ? "Hi! User" : "Login"}{" "}
                  <KeyboardArrowDownIcon />
                </Link>
                <Collapse in={openLoginMenu} timeout="auto" unmountOnExit>
                  {USER_DATA.mobile.value ? (
                    <>
                      <Link
                        className="nav-link-inner"
                        onClick={(e) => {
                          window.location.href = `${FRONTEND_DOMAIN}${
                            MY_ACCOUNT_ROUTES.DASHBOARD
                          }${btoa(USER_DATA.mobile.value)}`;
                        }}
                      >
                        Dashboard
                      </Link>
                      <Link
                        className="nav-link-inner"
                        onClick={() => {
                          setOpenMenu(false);
                          handleLogout();
                        }}
                      >
                        Logout
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link className="nav-link-inner">Corporate Login</Link>
                      <Link
                        className="nav-link-inner"
                        onClick={() => {
                          setOpenLoginPopup(true);
                          setOpenMenu(false);
                        }}
                      >
                        Customer Login
                      </Link>
                    </>
                  )}
                </Collapse>
              </li>
            </ul>
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

export default MNavBar;
