import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TWForm from "../../Page/Desktop/Home/TWForm/TWForm";
import { FRONTEND_DOMAIN } from "../../Routing/CommonURLs/Domain";
import { TW_ROUTES } from "../../Routing/Path/TWRoutes";

import { TWSlice } from "../../Store/Reducer/TW/TwSlice";
import { useAppDispatch } from "../../Store/Store/hooks";
import {
  isEmpty,
  validateFullName,
  validateMobileNumber,
  validateRegno,
} from "../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../Type/Common/TDropdown";
import { TTWForm } from "../../Type/TW/TTwSlice";
import MTWForm from "../../Page/Mobile/MHome/MTWForm/MTWForm";
import { TW_ADD_FORM_SERVICES } from "../../Services/TW/TWAddFormServices";
import { ADD_TW_DTO } from "../../DTO/TWDTO/Form/AddTWDTO";

const TwFormContainer = ({
  setOpen,
  open,
}: {
  setOpen: Function;
  open: boolean;
}) => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const [step, setStep] = useState<1 | 2>(1);
  const [formData, setFormData] = useState<TTWForm>(
    TWSlice.getInitialState().ADD_FORM
  );

  useEffect(() => {
    setFormData((prev) => ({
      ...TWSlice.getInitialState().ADD_FORM,
      business_type: prev.business_type,
    }));
  }, [formData.business_type]);

  useEffect(() => {
    if (open) {
      // GET_MAKE_MODEL_LIST();
      GET_PREV_INSURER_LIST();
      GET_RTO_LIST();
    }
  }, [open]);

  useEffect(() => {
    if (!isEmpty(formData.make_model.value)) {
      GET_FUEL_TYPE_LIST();
    }
  }, [formData.make_model.value]);

  useEffect(() => {
    if (
      !isEmpty(formData.make_model.value) &&
      !isEmpty(formData.fuel_type.value)
    ) {
      GET_VARIANT_LIST();
    }
  }, [formData.make_model.value, formData.fuel_type.value]);

  // const GET_MAKE_MODEL_LIST = () => {
  //   const onSuccess = (res: any) => {
  //     const results = res.data.results;
  //     const error = results.error;

  //     let data: TDropdown[] = [];
  //     if (error === false) {
  //       const response = results.response;
  //       data = Object.values(response).map((item: any) => ({
  //         label: item,
  //         value: item,
  //       }));
  //     }

  //     dispatch(
  //       TWSlice.actions.UPDATE_DROPDOWN_MASTER({
  //         key: "MAKE_MODEL_LIST",
  //         value: data,
  //       })
  //     );
  //   };

  //   const onError = (err: any) => {
  //     console.log("---false", err);
  //   };

  //   TW_ADD_FORM_SERVICES.GET_MAKE_MODEL_LIST(onError, onSuccess);
  // };

  const GET_FUEL_TYPE_LIST = () => {
    const onSuccess = (res: any) => {
      const response = res.results;
      const error = response.error;

      if (error === false) {
        let data: TDropdown[] = [];
        const datas = response.response;

        Object.entries(datas).map(([brand, value]) =>
          data.push({ label: brand, value: brand })
        );

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "FUEL_TYPE_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_FUEL_TYPE_LIST(
      onSuccess,
      onError,
      `${formData.make_model.value}`
    );
  };

  const GET_VARIANT_LIST = () => {
    const onSuccess = (res: any) => {
      const response = res.results;
      const error = response.error;

      if (error === false) {
        let data: TDropdown[] = [];
        const datas = response.response;

        Object.entries(datas).map(([brand, value]) =>
          data.push({ label: brand, value: brand })
        );

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "VARIANT_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_VARIANT_LIST(
      onSuccess,
      onError,
      `${formData.fuel_type.value}`,
      `${formData.make_model.value}`
    );
  };

  const GET_PREV_INSURER_LIST = () => {
    const onSuccess = (res: any) => {
      const response = res.data.results;
      const error = response.error;

      if (error === false) {
        let data: TDropdown[] = [];
        const datas = response.response;
        data.push({ label: "Do not remember", value: "Do not remember" });
        Object.entries(datas).map(([brand, value]) =>
          data.push({ label: `${value}`, value: `${value}` })
        );

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "PREVIOUS_INSURER_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_PREVIOUS_INSURER_LIST(onError, onSuccess);
  };

  const GET_RTO_LIST = () => {
    const onSuccess = (res: any) => {
      const response = res.data.results;
      const error = response.error;

      if (error === false) {
        let data: TDropdown[] = [];
        const datas = response.response;

        Object.entries(datas).map(([brand, value]) =>
          data.push({ label: `${brand} - ${value}`, value: brand })
        );

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "RTO_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_RTO_LIST(onError, onSuccess);
  };

  const POST_TW_FORM = () => {
    const onSuccess = (res: any) => {
      const error = res.results.error;
      const message = res.results.message;

      if (!error) {
        const response = res?.results?.response;
        const error = res?.results?.error;
        if (!error) {
          window.location.href = `${FRONTEND_DOMAIN}${TW_ROUTES.QUOTE}?type=TW&quote_no=${response.quote_no}`;
        }
      } else {
        toast.error(`${message}`);
      }
    };

    const onError = () => {};

    const data: ADD_TW_DTO = {
      twBusinessType:
        formData.reg_date.value || formData.reg_no.value
          ? "Rollover"
          : formData.rto.value
          ? "New"
          : "Rollover",
      twClaimMade: formData.claimed,
      twFuelType: formData.fuel_type.value,
      twMakeModel: formData.make_model.value,
      twMobile: formData.mobile.value,
      twPExpiryDate: formData.policy_expiry_date.value,
      twPreviousInsurer: formData.previous_insurer.value,
      twPreviousNCB: formData.prev_ncb,
      twRegDate: formData.reg_date.value,
      twRegNo: formData.reg_no.value,
      twRtoCode: formData.rto.value,
      twVariantCC: formData.variant_cc.value,
    };

    TW_ADD_FORM_SERVICES.ADD_TW_DATA(onSuccess, onError, data);
  };

  const GET_VEHICLE_INFO = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const response = results.response;
      const error = results.error;

      if (error === false && response) {
        const {
          make,
          make_model,
          variant_cc,
          fuel_type,
          policy_expiry_date,
          previous_insurer,
          owner_name,
        } = response;

        setFormData((prevFormData) => ({
          ...prevFormData,
          make: { value: make, warning: false },
          model: { value: make_model, warning: false },
          make_model: { value: make_model, warning: false },
          variant_cc: { value: variant_cc, warning: false },
          fuel_type: { value: fuel_type, warning: false },
          policy_expiry_date: { value: policy_expiry_date, warning: false },
          previous_insurer: { value: previous_insurer, warning: false },
          owner_name: { value: owner_name, warning: false },
        }));
      }
      setStep(2);
    };
    const onError = () => {};

    TW_ADD_FORM_SERVICES.GET_VEHICLE_NO_INFO(
      onSuccess,
      onError,
      formData.reg_no.value
    );
  };

  const validateForm = () => {
    let hasError = false;
    let data = { ...formData };
    data = {
      ...data,
      make_model: {
        ...data.make_model,
        warning: isEmpty(data.make_model.value),
      },
      fuel_type: {
        ...data.fuel_type,
        warning: isEmpty(data.fuel_type.value),
      },
      variant_cc: {
        ...data.variant_cc,
        warning: isEmpty(data.variant_cc.value),
      },
      policy_expiry_date: {
        ...data.policy_expiry_date,
        warning: isEmpty(data.policy_expiry_date.value),
      },
      previous_insurer: {
        ...data.previous_insurer,
        warning: isEmpty(data.previous_insurer.value),
      },
      owner_name: {
        ...data.owner_name,
        warning: isEmpty(data.owner_name.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
    };

    if (data.business_type === "New") {
      if (
        data.make_model.warning ||
        data.fuel_type.warning ||
        data.variant_cc.warning ||
        data.owner_name.warning ||
        data.mobile.warning
      ) {
        hasError = true;
      }
    } else {
      if (data.previous_insurer.value === "Do not remember") {
        data = { ...data, policy_expiry_date: { value: "", warning: false } };
      }

      if (
        data.make_model.warning ||
        data.fuel_type.warning ||
        data.variant_cc.warning ||
        data.previous_insurer.warning ||
        data.policy_expiry_date.warning ||
        data.owner_name.warning ||
        data.mobile.warning
      ) {
        hasError = true;
      }
    }

    setFormData({ ...data });

    if (hasError) {
      if (
        data.make_model.warning ||
        data.fuel_type.warning ||
        data.variant_cc.warning ||
        data.owner_name.warning ||
        data.mobile.warning
      )
        return;
    }

    POST_TW_FORM();
  };

  const updateMasterState = (attrName: string, value: any) => {
    let data: TTWForm = { ...formData };

    switch (attrName) {
      case "reg_no":
        data = {
          ...data,
          reg_no: { value: value, warning: !validateRegno(value) },
        };
        break;

      case "make_model":
        data = {
          ...data,
          make_model: { value: value, warning: false },
          fuel_type: { value: "", warning: false },
          variant_cc: { value: "", warning: false },
        };
        break;

      case "fuel_type":
        data = {
          ...data,
          fuel_type: { value: value, warning: false },
          variant_cc: { value: "", warning: false },
        };
        break;

      case "variant_cc":
        data = {
          ...data,
          variant_cc: { value: value, warning: false },
        };
        break;

      case "prev_ncb":
      case "claimed":
        data = {
          ...data,
          [attrName]: value,
        };
        break;

      case "owner_name":
        data = {
          ...data,
          owner_name: { value: value, warning: !validateFullName(value) },
        };
        break;

      case "mobile":
        data = {
          ...data,
          mobile: { value: value, warning: !validateMobileNumber(value) },
        };
        break;

      default:
        data = {
          ...data,
          [attrName]: { value: value, warning: false },
        };
        break;
    }

    setFormData({ ...data });
  };

  return (
    <div>
      {isMobile ? (
        <MTWForm
          formData={formData}
          setFormData={setFormData}
          updateMasterState={updateMasterState}
          open={open}
          setOpen={setOpen}
          validateForm={validateForm}
          GET_VEHICLE_INFO={GET_VEHICLE_INFO}
          step={step}
          setStep={setStep}
        />
      ) : (
        <TWForm
          formData={formData}
          setFormData={setFormData}
          updateMasterState={updateMasterState}
          open={open}
          setOpen={setOpen}
          validateForm={validateForm}
          GET_VEHICLE_INFO={GET_VEHICLE_INFO}
          step={step}
          setStep={setStep}
        />
      )}
    </div>
  );
};

export default TwFormContainer;
