import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./../../../SCSS/CMSPages.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import TextArea from "../../../Component/InputFields/TextArea/TextArea";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";

const Claim = () => {
  const isMobile = useIsMobile();
  const [productTypeTab, setProductTypeTab] = useState<"motor" | "health">(
    "motor"
  );
  const [claimTypeTabsvalue, setClaimTypeTabsvalue] = React.useState("1");
  const [claimTypeTabsHealthvalue, setClaimTypeTabsHealthvalue] =
    React.useState("1");
  const handleTabsChange = (
    event: React.SyntheticEvent,
    newTabsValue: string
  ) => {
    setClaimTypeTabsvalue(newTabsValue);
  };
  const handleTabsChangeHealth = (
    event: React.SyntheticEvent,
    newTabsValue2: string
  ) => {
    setClaimTypeTabsHealthvalue(newTabsValue2);
  };
  const [formFields, setFormFields] = useState<{
    policyNumber: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    interested: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    message: { value: string; warn_status: boolean };
  }>({
    policyNumber: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    interested: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    message: { value: "", warn_status: false },
  });

  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };
  return (
    <Box className="cmsPage-layout">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="contentPages-layout mb-margin1">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h2 className="mb-5">Claim Assistance</h2>
            <p>
              Claim settlement is one of the most important services that an
              insurance company provides you.It’s what you pay for and when
              things go wrong, you want to ensure you're back on your feet as
              soon as possible. Insurance companies are bound to settle the
              lawful claims promptly. Every product and every Insurance Company
              has a different claim process that you might go through. You must
              familiarise yourself with the process of claim as mentioned in the
              policy terms and conditions before initiating it. We will happily
              guide and advise you on all aspects of claims from your initial
              inquiry about a potential claim.
            </p>
          </Grid>
        </Grid>
        <Grid container className="row" columnSpacing={3}>
          <Grid
            xs={12}
            marginBottom={"32px"}
            className="mb-margin1 productTabs-section"
          >
            <Link
              className={`product_claim_tab ${
                productTypeTab === "motor" ? "active" : ""
              }`}
              onClick={() => setProductTypeTab("motor")}
            >
              <Box className="productTag_box mb-4">
                <img
                  src="./images/pt_motorclaim_img.svg"
                  alt=""
                  height={"50px"}
                />
                <span>Motor Claim</span>
              </Box>
              <p>
                Going through an accident would be a very traumatic experience.
                Just stay calm in such situation and follow these procedures to
                get your claim
              </p>
              <a href="">Need Claim</a>
            </Link>
            <Link
              className={`product_claim_tab ${
                productTypeTab === "health" ? "active" : ""
              }`}
              onClick={() => setProductTypeTab("health")}
            >
              <Box className="productTag_box mb-4">
                <img
                  src="./images/pt_healthclaim_img.svg"
                  alt=""
                  height={"50px"}
                />
                <span>Health Claim</span>
              </Box>
              <p>
                In today's lifestyle, one can face health issues anytime. Got
                the health insurance but not knowing what to do when need arise?
              </p>
              <a href="">Need Claim</a>
            </Link>
          </Grid>
        </Grid>

        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            {productTypeTab === "motor" && (
              <>
                <Box className="gradientBox">
                  <h2>Motor Claim</h2>
                  {!isMobile && (
                    <Box className="claimTypeTabs">
                      <TabContext value={claimTypeTabsvalue}>
                        <Box>
                          <TabList
                            onChange={handleTabsChange}
                            aria-label="lab API tabs example"
                          >
                            <Tab label="Third Party Claim" value="1" />
                            <Tab label="Theft Claim" value="2" />
                            <Tab label="Own Damage Claim" value="3" />
                          </TabList>
                        </Box>
                      </TabContext>
                    </Box>
                  )}
                </Box>
                <Box className="claimTypeTabs">
                  <TabContext value={claimTypeTabsvalue}>
                    {isMobile && (
                      <TabList
                        onChange={handleTabsChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Third Party Claim" value="1" />
                        <Tab label="Theft Claim" value="2" />
                        <Tab label="Own Damage Claim" value="3" />
                      </TabList>
                    )}
                    <TabPanel value="1">
                      <Grid container columnSpacing={3}>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h3 className="mb-2">Third Party Claim</h3>
                          <p>
                            If your vehicle is insured and has a third party
                            liability cover, the compensation sought by the
                            third party for damages in case of an accident will
                            be fully paid by your insurance company.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">Report to the Police </h4>
                          <p>
                            Where your vehicle is involved, it is important to
                            report the accident to the police and obtain the
                            first information report quoting the details such as
                            registration number of the vehicle, license number
                            of the driver, and the names/contact details of
                            witnesses, if any.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">Inform your insurer </h4>
                          <p>
                            Intimate your insurer immediately along with the
                            basic details of the Policy, Driver, and Place of
                            accident. If other's vehicle is involved i.e. if you
                            are a victim, you must obtain the insurance details
                            of that vehicle and intimate the insurer about that
                            vehicle.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">
                            Submit documents to your insurer
                          </h4>
                          <p>
                            Submit the following documents to the insurance
                            company for processing your claim:
                          </p>
                          <ul className="bluedot_list">
                            <li>Claim form</li>
                            <li>Policy copy</li>
                            <li>Registration Certificate</li>
                            <li>Driving License</li>
                            <li>First Information Report</li>
                            <li>
                              Any other documents/information as required by the
                              Insurer
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                      <Grid container columnSpacing={3}>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h3 className="mb-2">Theft Claim</h3>
                          <p>
                            If you have Comprehensive coverage on your insurance
                            policy, you will be covered for the stolen car as
                            well and eligible to get a claim for the theft of
                            your car.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">Lodge an FIR</h4>
                          <p>
                            You must report to the police and obtain the First
                            Information Report.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">Inform your Insurer</h4>
                          <p>
                            If your vehicle is not found within 90 days, then
                            you need to obtain Non-traceable report from Police
                            and submit the same to your insurance company.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">
                            Submit claim form and other documents
                          </h4>
                          <p>
                            You should fill claim form giving information like
                            Policy number, Vehicle details, Date, time and
                            description of the incident. You need to submit the
                            duly signed Claim Form along with the following
                            documents:-
                          </p>
                          <ul className="bluedot_list">
                            <li>
                              Registration certificate (RC) of the vehicle
                            </li>
                            <li>Driving license</li>
                            <li>Policy copy</li>
                            <li>FIR</li>
                            <li>Final Non-traceable report by police</li>
                            <li>Letter of Subrogation</li>
                            <li>All sets of keys</li>
                            <li>
                              Letter addressed to the RTO intimating theft
                            </li>
                          </ul>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">
                            Inform your Regional Transport Officer (RTO)
                          </h4>
                          <p>
                            The next important step to do is to notify the
                            Regional Transport Officer (RTO). It is also a
                            mandatory step as per The Motor Vehicle Act 1988.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">Claim Settlement</h4>
                          <p>
                            If you have taken a car loan to purchase your car,
                            the insurer will settle the amount directly to the
                            financier. The settlement amount is on the Insured
                            Declared Value (IDV) which might however differ
                            based on usage and market value.
                          </p>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="3">
                      <Grid container columnSpacing={3}>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h3 className="mb-2">Own damage claim</h3>
                          <p>
                            Claim for damage to your own insured vehicle is
                            called an own damage claim and you are eligible for
                            this if you are holding what is known as a package
                            or a comprehensive policy.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">Inform Insurer and police</h4>
                          <p>
                            Where your vehicle is damaged due to accident, you
                            must immediately inform insurance company and
                            police, wherever required, to enable them to depute
                            a surveyor to assess the loss.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">
                            Send your vehicle to the garage
                          </h4>
                          <p>
                            You should send your vehicle to the garage or
                            authorized workshop where you can also report your
                            claim. Your workshop or garage will prepare an
                            estimated cost of repairs and liaise with insurance
                            company.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">Surveyor Inspection Report</h4>
                          <p>
                            Surveyor will inspect the vehicle and submit the
                            inspection documents to your insurance company.
                            Insurance company will review the documents and on
                            the basis of the inspection will authorize the
                            garage or workshop for the repairs.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">Payment of Expenses</h4>
                          <p>
                            If your policy provides for cashless service, which
                            means you do not have to pay out of your pocket for
                            covered damages, the insurance company will pay the
                            workshop directly. In case of reimbursement service,
                            the approved claim amount will be intimated to the
                            garage and you will be paid by insurer within 7 days
                            of submission of final documents.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">
                            Reimbursement documents to be submitted to the
                            Insurer
                          </h4>
                          <p>
                            Reimbursement documents to be submitted to the
                            Insurer
                          </p>
                          <ul className="bluedot_list">
                            <li>
                              Copy of the Insurance certificate cum Policy
                              Schedule
                            </li>
                            <li>Claim form</li>
                            <li>Copy of Registration Certificate</li>
                            <li>Copy of Driving License</li>
                            <li>First Information Report</li>
                            <li>Letter of Subrogation</li>
                            <li>Estimate of cost</li>
                            <li>
                              Final repair invoice and receipt / Satisfaction
                              voucher for cashless payment
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </TabContext>
                </Box>
              </>
            )}
            {productTypeTab === "health" && (
              <>
                <Box className="gradientBox">
                  <h2>Health Claim</h2>
                  {!isMobile && (
                    <Box className="claimTypeTabs">
                      <TabContext value={claimTypeTabsHealthvalue}>
                        <Box>
                          <TabList
                            onChange={handleTabsChangeHealth}
                            aria-label="lab API tabs example"
                          >
                            <Tab label="Cashless Basis" value="1" />
                            <Tab label="Reimbursement" value="2" />
                          </TabList>
                        </Box>
                      </TabContext>
                    </Box>
                  )}
                </Box>
                <Box className="claimTypeTabs">
                  <TabContext value={claimTypeTabsHealthvalue}>
                    {isMobile && (
                      <TabList
                        onChange={handleTabsChangeHealth}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Cashless Basis" value="1" />
                        <Tab label="Reimbursement" value="2" />
                      </TabList>
                    )}
                    <TabPanel value="1">
                      <Grid container columnSpacing={3}>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h3 className="mb-2">On Cashless Basis</h3>
                          <p>
                            Cashless hospitalization is a facility provided by
                            the Insurance Company wherein the policyholder can
                            get admitted and undergo the required treatment
                            without paying directly for the medical expenditure.
                            The eligible medical expense, thus incurred, shall
                            be settled by the respective Insurance Company
                            directly with the hospital as per policy terems and
                            conditions. The Cashless claim facility can be
                            obtained only for the treatment done at the network
                            hospital listed by the respective Insurance
                            Company.To know the network hospital, check your
                            policy document or call the toll-free number
                            mentioned therein.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">Inform your Insurer </h4>
                          <p>
                            Hospitalization can be under two circumstances -
                            planned or in case emergency
                          </p>
                          <ul className="bluedot_list">
                            <li>
                              <span>Planned: </span>
                              Once your doctor has recommended hospitalization,
                              contact your insurer immediately and inform them
                              about the hospitalization.
                            </li>
                            <li>
                              <span>Emergency: </span> In case of emergency
                              hospitalization, you must inform your insurer
                              within 24 hours of hospitalization.
                            </li>
                          </ul>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">
                            Process of Admission in the hospital
                          </h4>
                          <p>
                            To avail the cashless benefits kindly show your
                            health ID card or policy number for identification
                            purposes.post which the Hospital TPA Support Desk
                            will providea pre-authorization form in which
                            personal details need to be filled by
                            insured/patient and the restof the details need to
                            be filled by the treating doctor and hospital staff.
                            Insured/Patient needs to optfor the room as per
                            their policy eligibility only. The preauthorization
                            form needs to be signed by the patient or his family
                            member.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">
                            Documents to be sent to the Insurance Company along
                            with the pre-authorization form
                          </h4>
                          <p>
                            Collate the documents to be sent along with the
                            pre-authorization form:
                          </p>
                          <ul className="bluedot_list">
                            <li>Policy Copy/ Health Card</li>
                            <li>ID proof with address</li>
                            <li>Investigation Test reports from Pathologist</li>
                            <li>
                              Medico Legal Certificate (MLO)- in accident cases
                            </li>
                          </ul>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">
                            Confirmation of insurer to proceed with services
                          </h4>
                          <p>
                            Hospital will submit duly filledthe
                            pre-authorization form to the Insurer. The Insurer
                            will review the documents and provide confirmation
                            to the hospital to proceed with the cashless
                            services. Normally, TPA issues a letter of
                            confirmation within 3-6 hours, provided all relevant
                            documents are submitted.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">
                            Hospital sends the bill to the insurer during
                            discharge
                          </h4>
                          <p>
                            Hospital shares all the bills, treatment papers, and
                            discharge summariesto the respective Insurance
                            Company. It is advisable that the Insured/patient
                            also obtain a copy of the same for record-keeping.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">Denial Of Cashless</h4>
                          <p>
                            If admissibility of the claim cannot be established
                            based upon the documents provided at the time of
                            cashless request, the cashless facility shall be
                            denied by the Insurer/TPA. However please note that
                            denial of authorization for cashless benefit does
                            not mean denial of claim or denial of treatment and
                            does not in any way prevent you from seeking
                            necessary medical attention or hospitalization. The
                            insured/patient can submit the claim documents for
                            reconsideration in reimbursement along with duly
                            completed claim form within 7 days from date of
                            discharge from the hospital.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">Cashless Settlement</h4>
                          <p>
                            At the time of discharge, the hospital would send
                            all hospitalization related documents along with
                            discharge summary, final bill, reports, medicine
                            details etc. & claim would be processed as per
                            consideration of hospital tariff, package or
                            schedule of charges, mutually agreed upon with
                            either TPA or Insurance Companies, whichever is
                            applicable as per policy. Insured should note that
                            the policy may contain certain exclusions &/or
                            restrictions which will be applied at the time of
                            initial & final authorization. Non-medical expenses
                            (NME) are not payable and will be deducted.
                          </p>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                      <Grid container columnSpacing={3}>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h3 className="mb-2">
                            Claims on Reimbursement Basis
                          </h3>
                          <p>
                            Reimbursement claims are how you can claim expenses
                            incurred at an out-of-network hospital or claims
                            rejected for cashless benefit.
                          </p>
                          <p>
                            In case of a reimbursement claim, the policy holder
                            pays the expenses himself with the hospital and then
                            claims for a reimbursement of those expenses from
                            the Insurance Company by providing necessary
                            documentation.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">Inform your Insurer </h4>
                          <p>
                            Hospitalization can be under two circumstances -
                            planned or in case emergency
                          </p>
                          <ul className="bluedot_list">
                            <li>
                              <span>Planned: </span>
                              Once your doctor has recommended hospitalization,
                              contact your insurer immediately and inform them
                              about the hospitalization.
                            </li>
                            <li>
                              <span>Emergency: </span> In case of emergency
                              hospitalization, you must inform your insurer
                              within 24 hours of hospitalization.
                            </li>
                          </ul>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">Get the treatment done</h4>
                          <p>
                            Avail the treatment as prescribed. Intimate your
                            insurer in case of the treatment done outside the
                            network hospital. In the meantime, you can proceed
                            with your treatment and pay the bill.Ensure to get
                            your all original case related papers, medical
                            bills, medicalreports,discharge summary and any
                            other documents given by the hospital from the
                            hospital.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">
                            Submit the documents to your insurer
                          </h4>
                          <p>
                            Submit the following documents to your insurer for
                            scrutinizing the same as per policy terms and
                            conditions:
                          </p>
                          <ul className="bluedot_list">
                            <li>
                              Complete and signed Claim form with attending
                              doctor's prescription
                            </li>
                            <li>
                              First prescription of the present illness, for
                              which treatment has been undergone in
                              hospital/nursing home
                            </li>
                            <li>Medical advice for hospitalization</li>
                            <li>Discharge summary of hospital</li>
                            <li>Original Final bill of the hospital</li>
                            <li>Medical fitness certificate</li>
                            <li>
                              All test reports supported by medical prescription
                              (pre & post hospitalization)
                            </li>
                            <li>
                              All vouchers/bills/receipts pertaining to the
                              tests mentioned above
                            </li>
                            <li>
                              All medicines purchased from outside the hospital
                              at any point in time to be supported by original
                              prescription
                            </li>
                          </ul>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">
                            Claim documents review by Insurer
                          </h4>
                          <p>
                            Your insurer will review the claim documents
                            received and effect the payment within 7 days of
                            receiving documents. If the treatment claimed is not
                            covered under your plan, a rejection letter will be
                            sent to you stating the reason for the same.
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">
                            Hospital sends the bill to the insurer during
                            discharge
                          </h4>
                          <p>
                            Ready with you the below documents to be sent along
                            with the pre-authorization form:
                          </p>
                        </Grid>
                        <Grid xs={12} marginBottom={"16px"}>
                          <h4 className="mb-2">Direct payment</h4>
                          <p>
                            Once the bill is confirmed, insurer makes payment
                            directly to the hospital.
                          </p>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </TabContext>
                </Box>
              </>
            )}
          </Grid>
        </Grid>

        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} marginBottom={"24px"}>
            <Box className="claim_form_box">
              <h3>Submit a Claim Ticket</h3>
              <p className="mb-6">
                Please enter your policy details. We will assist you with your
                claim.
              </p>
              <Grid container columnSpacing={3}>
                <Grid xs={12} md={6} lg={4}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Policy Number"}
                    value={formFields.policyNumber.value}
                    attrName={"policyNumber"}
                    value_update={updateFieldsState}
                    warn_status={formFields.policyNumber.warn_status}
                  />
                </Grid>
                <Grid xs={12} md={6} lg={4}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Mobile Number"}
                    value={formFields.mobile.value}
                    attrName={"mobile"}
                    value_update={updateFieldsState}
                    warn_status={formFields.mobile.warn_status}
                  />
                </Grid>
                <Grid xs={12} md={6} lg={4}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Mobile"}
                    value={formFields.mobile.value}
                    attrName={"mobile"}
                    value_update={updateFieldsState}
                    warn_status={formFields.mobile.warn_status}
                  />
                </Grid>
                <Grid xs={12}>
                  <TextArea
                    class_name="inputField mb-6"
                    title={"Please provide Details to take an Action"}
                    value={formFields.message.value}
                    attrName={"message"}
                    value_update={updateFieldsState}
                    warn_status={formFields.message.warn_status}
                    placeholder="Describe"
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"}>
                  <CustomButton
                    className="primaryBtn large"
                    text={"Submit Details"}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <p>
              Here we help you to guide through the various claim process which
              you might go through with insurance companies. If in case you need
              further assistance, you may simply email us at{" "}
              <a href="mailto:info@walnnut.com" target="_blank">
                info@walnnut.com
              </a>
            </p>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default Claim;
