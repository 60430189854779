import React, { useEffect, useState } from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
// import TWProductPage from "../../Pages/Desktop/TWProductPage/TWProductPage";
import { useAppDispatch, useAppSelector } from "../../Store/Store/hooks";
import { useNavigate } from "react-router-dom";
import { TTWForm } from "../../Type/TW/TTwSlice";
import { TWSlice } from "../../Store/Reducer/TW/TwSlice";
import {
  FORMAT_YYYY_MM_DD,
  isEmpty,
  validateMobileNumber,
  validateRegno,
} from "../../SupportingFiles/HelpingFunction";

import { FRONTEND_DOMAIN } from "../../Routing/CommonURLs/Domain";
// import { TW_ROUTES } from "../../Routing/Path/TWRoutes";
import { toast } from "react-toastify";

import { TW_ROUTES } from "../../Routing/Path/TWRoutes";
import TWProductPage from "../../ProductFormPage/TWProductPage/TWProductPage";
import { TW_ADD_FORM_SERVICES } from "../../Services/TW/TWAddFormServices";
import { ADD_TW_DTO } from "../../DTO/TWDTO/Form/AddTWDTO";

const TWProductContainer = () => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [BikeFormSteps, setBikeFormSteps] = useState<
    "oldStep1" | "oldStep2" | "newStep1" | "newStep2"
  >("oldStep1");
  const { Tw } = useAppSelector((state) => state);
  const { ADD_FORM } = useAppSelector((state) => state.Tw);
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Tw);

  const useInputChange = (initialValue: string) => {
    const [value, setValue] = useState(initialValue);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event?.target?.value);
    };
    return { value, onChange: handleChange };
  };
  const [vehicleInfoStatus, setVehicleInfoStatus] = useState<boolean>(false);

  const [ncbValue, changeNCB] = useState("0");
  const [value, onChange] = useState("Yes");
  const [formData, setFormData] = useState<TTWForm>(
    TWSlice.getInitialState().ADD_FORM
  );

  useEffect(() => {
    if (value === "Yes") {
      changeNCB("0");
    }
  }, [value]);

  useEffect(() => {
    setFormData((prev: any) => ({
      ...prev,
      business_type: "Rollover",
      reg_no: { value: "", warning: false },
      reg_date: { value: null, warning: false },
      make_model: { value: "", warning: false },
      fuel_type: { value: "", warning: false },
      variant_cc: { value: "", warning: false },
      rto: { value: "", warning: false },
      previous_insurer: { value: "", warning: false },
      policy_expiry_date: { value: "", warning: false },
      mobile: { value: "", warning: false },
      claimed: value,
      anti_theft_device: "No",
      automobile_association: "0",
      deductible: "0",
      make: { value: "", warning: false },
      model: { value: "", warning: false },
      owner_name: { value: "", warning: false },
      prev_ncb: "0",
      vehicle_owned: "Individual",
    }));
  }, []);
  useEffect(() => {
    GET_MAKE_LIST();
    GET_PREV_INSURER_LIST();
    GET_RTO_LIST();
  }, []);
  useEffect(() => {
    if (!isEmpty(formData?.make?.value)) {
      GET_MODEL_LIST();
    } else {
      dispatch(
        TWSlice.actions.UPDATE_DROPDOWN_MASTER({
          key: "MODEL_LIST",
          value: [],
        })
      );
    }
  }, [formData?.make?.value]);

  useEffect(() => {
    if (!isEmpty(formData.make.value) && !isEmpty(formData.model.value)) {
      GET_FUEL_TYPE_LIST();
    }
  }, [formData.make.value, formData.model.value]);

  useEffect(() => {
    if (
      !isEmpty(formData.make.value) &&
      !isEmpty(formData.model.value) &&
      !isEmpty(formData.fuel_type.value)
    ) {
      GET_VARIANT_LIST();
    }
  }, [formData.make.value, formData.model.value, formData.fuel_type.value]);

  const GET_MAKE_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          make?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.make === "string") {
            data.push({ label: `${value.make}`, value: `${value.make}` });
          }
        });

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "MAKE_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_MAKE_LIST(onError, onSuccess);
  };

  const GET_MODEL_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          make_model?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.make_model === "string") {
            data.push({
              label: `${value.make_model}`,
              value: `${value.make_model}`,
            });
          }
        });

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "MODEL_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_MODEL_LIST(
      onError,
      onSuccess,
      `${formData.make.value}`
    );
  };

  const GET_FUEL_TYPE_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          fuel_type?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.fuel_type === "string") {
            data.push({
              label: `${value.fuel_type}`,
              value: `${value.fuel_type}`,
            });
          }
        });

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "FUEL_TYPE_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_FUEL_TYPE_LIST(
      onError,
      onSuccess,
      `${formData.make.value} ${formData.model.value}`
    );
  };

  const GET_VARIANT_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          variant_cc?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.variant_cc === "string") {
            data.push({
              label: `${value.variant_cc}`,
              value: `${value.variant_cc}`,
            });
          }
        });

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "VARIANT_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_VARIANT_LIST(
      onError,
      onSuccess,
      `${formData.make.value} ${formData.model.value}`,
      `${formData.fuel_type.value}`
    );
  };

  const GET_PREV_INSURER_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          short_name?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        data.push({ label: "Do not remember", value: "Do not remember" });
        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.short_name === "string") {
            data.push({
              label: `${value.short_name}`,
              value: `${value.short_name}`,
            });
          }
        });

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "PREVIOUS_INSURER_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_PREVIOUS_INSURER_LIST(onError, onSuccess);
  };

  const GET_RTO_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        const data: Array<{ label: string; value: string }> = [];

        Object.entries(response).map(([brand, value]) =>
          data.push({ label: `${brand} - ${value}`, value: brand })
        );

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "RTO_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_RTO_LIST(onError, onSuccess);
  };

  const POST_TW_FORM = () => {
    const onSuccess = (res: any) => {
      const quote_no = res?.response;
      const error = res?.error;
      const message = res?.message;
      setLoader(false);
      if (!error) {
        window.location.href = `${FRONTEND_DOMAIN}${TW_ROUTES.QUOTE}?type=TW&quote_no=${quote_no}`;
      } else {
        toast.error(`${message}`);
      }
    };

    const onError = () => {
      setLoader(false);
    };

    const data: ADD_TW_DTO = {
      twBusinessType: formData.business_type,
      twClaimMade: formData.claimed,
      twFuelType: formData.fuel_type.value,
      twMakeModel: `${formData.make.value} ${formData.model.value}`,
      twMobile: formData.mobile.value,
      twPExpiryDate: FORMAT_YYYY_MM_DD(formData.policy_expiry_date.value),
      twPreviousInsurer: formData.previous_insurer.value,
      twPreviousNCB: formData.prev_ncb,
      twRegDate: FORMAT_YYYY_MM_DD(formData.reg_date.value),
      twRegNo: formData.reg_no.value,
      twRtoCode: formData.rto.value,
      twVariantCC: formData.variant_cc.value,
      // twPincode: formData.pincode.value,
    };

    setLoader(true);
    TW_ADD_FORM_SERVICES.ADD_TW_DATA(onSuccess, onError, data);
  };
  const GET_VEHICLE_INFO = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const response = results.response;
      const error = results.error;

      if (error === false && response) {
        const {
          make,
          make_model,
          variant_cc,
          fuel_type,
          policy_expiry_date,
          previous_insurer,
          owner_name,
        } = response;

        setFormData((prevFormData) => ({
          ...prevFormData,
          make: { value: make, warning: false },
          model: { value: make_model, warning: false },
          make_model: { value: make_model, warning: false },
          variant_cc: { value: variant_cc, warning: false },
          fuel_type: { value: fuel_type, warning: false },
          policy_expiry_date: { value: policy_expiry_date, warning: false },
          previous_insurer: { value: previous_insurer, warning: false },
          owner_name: { value: owner_name, warning: false },
        }));

        setVehicleInfoStatus(true);
      }
    };
    const onError = () => {};

    TW_ADD_FORM_SERVICES.GET_VEHICLE_NO_INFO(
      onSuccess,
      onError,
      formData.reg_no.value
    );
  };
  const validateForm = () => {
    let hasError = false;
    let data = { ...formData };
    if (BikeFormSteps === "oldStep2") {
      if (formData.business_type === "Rollover") {
        data = {
          ...data,
          make: {
            ...data.make,
            warning: isEmpty(data.make.value),
          },
          model: {
            ...data.model,
            warning: isEmpty(data.model.value),
          },
          fuel_type: {
            ...data.fuel_type,
            warning: isEmpty(data.fuel_type.value),
          },
          variant_cc: {
            ...data.variant_cc,
            warning: isEmpty(data.variant_cc.value),
          },
          policy_expiry_date: {
            ...data.policy_expiry_date,
            warning: isEmpty(data.policy_expiry_date.value),
          },
          previous_insurer: {
            ...data.previous_insurer,
            warning: isEmpty(data.previous_insurer.value),
          },
          owner_name: {
            ...data.owner_name,
            warning: isEmpty(data.owner_name.value),
          },
          mobile: {
            ...data.mobile,
            warning: !validateMobileNumber(data.mobile.value),
          },
        };

        if (
          data.make.warning ||
          data.model.warning ||
          data.fuel_type.warning ||
          data.variant_cc.warning ||
          data.previous_insurer.warning ||
          data.policy_expiry_date.warning ||
          data.owner_name.warning ||
          data.mobile.warning
        ) {
          hasError = true;
        }
      }
    } else if (BikeFormSteps === "newStep2") {
      if (formData.business_type === "New") {
        data = {
          ...data,
          make: {
            ...data.make,
            warning: isEmpty(data.make.value),
          },
          model: {
            ...data.model,
            warning: isEmpty(data.model.value),
          },
          fuel_type: {
            ...data.fuel_type,
            warning: isEmpty(data.fuel_type.value),
          },
          variant_cc: {
            ...data.variant_cc,
            warning: isEmpty(data.variant_cc.value),
          },
          owner_name: {
            ...data.owner_name,
            warning: isEmpty(data.owner_name.value),
          },
          mobile: {
            ...data.mobile,
            warning: isEmpty(data.mobile.value),
          },
        };
      }
    }
    setFormData({ ...data });

    if (
      data.make.warning ||
      data.model.warning ||
      data.fuel_type.warning ||
      data.variant_cc.warning ||
      data.owner_name.warning ||
      data.mobile.warning
    ) {
      hasError = true;
    }

    if (!hasError) {
      POST_TW_FORM();
    }
  };

  const updateMasterState = (attrName: string, value: any) => {
    // debugger;
    setFormData((prev: any) => {
      if (attrName === "make") {
        return {
          ...prev,
          make: { value: value, warning: isEmpty(value) },
          fuel_type: { value: "", warning: false },
          variant_cc: { value: "", warning: false },
        };
      }
      if (attrName === "model") {
        return {
          ...prev,
          model: { value: value, warning: isEmpty(value) },
          fuel_type: { value: "", warning: false },
          variant_cc: { value: "", warning: false },
        };
      }
      if (attrName === "fuel_type") {
        return {
          ...prev,
          fuel_type: { value: value, warning: isEmpty(value) },
          variant_cc: { value: "", warning: false },
        };
      }
      // if (attrName === "mobile") {
      //   return {
      //     ...prev,
      //     mobile: {
      //       ...prev.mobile,
      //       warning: !validateMobileNumber(value),
      //     },
      //   };
      // }

      return {
        ...prev,
        [attrName]: {
          value: value,
          warning:
            attrName === "mobile"
              ? !validateMobileNumber(value)
              : attrName === "reg_no"
              ? !validateRegno(value)
              : isEmpty(value),
        },
      };
    });
  };

  return (
    <>
      <TWProductPage
        BikeFormSteps={BikeFormSteps}
        setBikeFormSteps={setBikeFormSteps}
        setFormData={setFormData}
        formData={formData}
        value={value}
        updateMasterState={updateMasterState}
        onChange={onChange}
        changeNCB={changeNCB}
        validateForm={validateForm}
        ncbValue={ncbValue}
      />
    </>
  );
};

export default TWProductContainer;
