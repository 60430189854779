import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import HealthForm from "../../Page/Desktop/Home/HealthForm/HealthForm";
import { CLIENTS } from "../../Routing/Clients";
import { HEALTH_ROUTE } from "../../Routing/Path/HealthRoutes";
// import { HEALTH_FORM_SERVICES } from "../../Services/Health/HealthServices";
import { useAppDispatch, useAppSelector } from "../../Store/Store/hooks";
import {
  calculateAge,
  isEmpty,
  validateMobileNumber,
  validatePincode,
} from "../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import { THealthAddForm } from "../../Type/Health/THealthSlice/THealthSlice";
import { HealthSlice } from "../../Store/Reducer/Health/HealthSlice";
import MHealthForm from "../../Page/Mobile/MHome/MHealthForm/MHealthForm";
import { HEALTH_FORM_SERVICES } from "../../Services/HealthServices/HealthFormServices";

export const HealthFormContainer = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) => {
  const isMobile = useIsMobile();
  const [healthFormSteps, setHealthFormSteps] = useState<"1" | "2">("1");
  const [formData, setFormData] = useState<THealthAddForm>(
    HealthSlice.getInitialState().ADD_FORM
  );
  const relationship_data = [
    { label: "Spouse", value: "Spouse" },
    { label: "Mother", value: "Mother" },
    { label: "Father", value: "Father" },
  ];

  const validate_form = () => {
    let hasError = false;
    let data: any = { ...formData };

    if (healthFormSteps === "1") {
      if (formData.self_status) {
        data = {
          ...data,
          self_dob: {
            ...data.self_dob,
            warning: isEmpty(data.self_dob.value),
          },
          gender: {
            ...data.gender,
            warning: isEmpty(data.gender.value),
          },
        };

        if (data.self_dob.warning || data.gender.warning) {
          hasError = true;
        }
      }

      if (formData.spouse_status) {
        data = {
          ...data,
          spouse_dob: {
            ...data.spouse_dob,
            warning: isEmpty(data.spouse_dob.value),
          },
          spouse_relationship: {
            ...data.spouse_relationship,
            warning: isEmpty(data.spouse_relationship.value),
          },
        };

        if (data.spouse_dob.warning || data.spouse_relationship.warning) {
          hasError = true;
        }
      }

      if (
        (formData.son_status || formData.daughter_status) &&
        (formData.son_count.value > 0 || formData.daughter_count.value > 0)
      ) {
        const childDobFields = [
          "child_one_dob",
          "child_two_dob",
          "child_three_dob",
          "child_four_dob",
        ];
        const sonCount = parseInt(formData.son_count.value, 10);
        const daughterCount = parseInt(formData.daughter_count.value, 10);
        const totalChildren = sonCount + daughterCount;

        for (let i = 0; i < totalChildren; i++) {
          const fieldName = childDobFields[i];
          data = {
            ...data,
            [fieldName]: {
              ...data[fieldName],
              warning: isEmpty(data[fieldName].value),
            },
          };
          hasError = hasError || data[fieldName].warning;
        }
      }

      if (!hasError) {
        setHealthFormSteps("2");
      }
    } else {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: !validateMobileNumber(data.mobile.value),
        },
        pincode: {
          ...data.pincode,
          warning: !validatePincode(data.pincode.value),
        },
      };

      if (data.mobile.warning || data.pincode.warning) {
        hasError = true;
      }

      if (!hasError) {
        ADD_FORM_DETAILS(data);
      }
    }

    setFormData({ ...data });

    if (
      data.self_dob.warning ||
      data.gender.warning ||
      data.spouse_dob.warning ||
      data.spouse_relationship.warning ||
      data.child_one_dob.warning ||
      data.child_two_dob.warning ||
      data.child_three_dob.warning ||
      data.child_four_dob.warning
    ) {
      hasError = true;
    }

    if (!hasError) {
      setHealthFormSteps("2");
    }
  };

  const ADD_FORM_DETAILS = (data: THealthAddForm) => {
    const onSuccess = (res: any) => {
      const results = res.results.response;
      const error = res.results.error;
      const message = res.results.message;

      if (error === false) {
        window.location.href = `https://${CLIENTS.MUTHOOT_QUOTE}${HEALTH_ROUTE.QOUTE_PAGE}?type=HP&quote_no=${results?.quote_no}`;
      } else {
        toast.error(`${message}`);
      }
    };

    const onError = (error: any) => {};

    // Define the parameters outside the JSX return statement
    const { son_count, daughter_count } = data;
    const total_sons = parseInt(son_count.value);
    const total_daughters = parseInt(daughter_count.value);

    let params = {
      healthSumInsured: "500000",
      healthCover: `${data?.spouse_status ? "2A" : "1A"}${
        total_sons + total_daughters > 0
          ? `${total_sons + total_daughters}C`
          : ""
      }`,
      healthAdult: `${data?.spouse_status ? "2" : "1"}`,
      children: `${total_sons + total_daughters}`,
      healthAge: calculateAge(data.self_dob.value),
      healthRelation: "self",
      healthGender: data.gender.value,
      health2Age: calculateAge(data.spouse_dob.value),
      health2Relation: data.spouse_relationship.value,
      healthName: "",
      healthMobile: data.mobile.value,
      healthPincode: data.pincode.value,
      childgender_1: total_sons >= 1 ? "M" : total_daughters >= 1 ? "F" : "",
      childrelation_1:
        total_sons >= 1 ? "son" : total_daughters >= 1 ? "daughter" : "",
      childage_1: data.child_one_dob.value,
      childgender_2:
        total_sons + total_daughters >= 2 ? (total_sons >= 2 ? "M" : "F") : "",
      childrelation_2:
        total_sons + total_daughters >= 2
          ? total_sons >= 2
            ? "son"
            : "daughter"
          : "",
      childage_2:
        total_sons + total_daughters >= 2 ? data.child_two_dob.value : "",
      childgender_3:
        total_sons + total_daughters >= 3 ? (total_sons >= 3 ? "M" : "F") : "",
      childrelation_3:
        total_sons + total_daughters >= 3
          ? total_sons >= 3
            ? "son"
            : "daughter"
          : "",
      childage_3:
        total_sons + total_daughters >= 3 ? data.child_three_dob.value : "",
      childgender_4:
        total_sons + total_daughters >= 4 ? (total_sons >= 4 ? "M" : "F") : "",
      childrelation_4:
        total_sons + total_daughters >= 4
          ? total_sons >= 4
            ? "son"
            : "daughter"
          : "",
      childage_4:
        total_sons + total_daughters >= 4 ? data.child_four_dob.value : "",
    };

    HEALTH_FORM_SERVICES.ADD_HEALTH_FORM(onSuccess, onError, params);
  };
  const updateMasterState = (attrName: any, value: any) => {
    setFormData((prev: any) => {
      let updatedData = { ...prev };

      switch (attrName) {
        case "whom_to_insure":
        case "children":
        case "self":
          updatedData[attrName] = value;
          break;

        case "son_status":
          if (value === true) {
            if (parseInt(prev.daughter_count.value) < 4) {
              updatedData[attrName] = value;
              updatedData.son_count = { value: "1", warning: false };
            }
          } else {
            updatedData[attrName] = value;
            updatedData.son_count = { value: "0", warning: false };
            updatedData = removeDobValuesIfNeeded(updatedData);
          }
          break;
        case "spouse_status":
          if (value === true) {
            updatedData[attrName] = value;
          } else {
            updatedData[attrName] = value;
            updatedData.spouse_dob = { value: "", warning: false };
            updatedData.spouse_relationship = { value: "", warning: false };
          }
          break;

        case "daughter_status":
          if (value === true) {
            if (parseInt(prev.son_count.value) < 4) {
              updatedData[attrName] = value;
              updatedData.daughter_count = { value: "1", warning: false };
            }
          } else {
            updatedData[attrName] = value;
            updatedData.daughter_count = { value: "0", warning: false };
            updatedData = removeDobValuesIfNeeded(updatedData);
          }
          break;

        case "pincode":
          updatedData[attrName] = {
            value: value,
            warning: !validatePincode(value),
          };
          break;

        case "mobile":
          updatedData[attrName] = {
            value: value,
            warning: !validateMobileNumber(value),
          };
          break;

        case "son_subtract":
          const newSonCount = Math.max(parseInt(prev.son_count.value) - 1, 0);
          if (newSonCount === 0) {
            updatedData.son_status = false;
          } else {
            updatedData.son_count = {
              value: newSonCount.toString(),
              warning: false,
            };
          }

          updatedData = removeDobValuesIfNeeded(updatedData);
          break;

        case "daughter_subtract":
          const newDaughterCount = Math.max(
            parseInt(prev.daughter_count.value) - 1,
            0
          );
          if (newDaughterCount === 0) {
            updatedData.daughter_status = false;
          } else {
            updatedData.daughter_count = {
              value: newDaughterCount.toString(),
              warning: false,
            };
          }
          updatedData = removeDobValuesIfNeeded(updatedData);
          break;

        case "daughter_add":
          if (
            (prev.son_status ? parseInt(prev.son_count.value) : 0) +
              (prev.daughter_status ? parseInt(prev.daughter_count.value) : 0) <
            4
          ) {
            updatedData.daughter_count = {
              value: (parseInt(prev.daughter_count.value) + 1).toString(),
              warning: false,
            };
          }
          break;

        case "son_add":
          if (
            (prev.son_status ? parseInt(prev.son_count.value) : 0) +
              (prev.daughter_status ? parseInt(prev.daughter_count.value) : 0) <
            4
          ) {
            updatedData.son_count = {
              value: (parseInt(prev.son_count.value) + 1).toString(),
              warning: false,
            };
          }
          break;

        default:
          updatedData[attrName] = { value: value, warning: isEmpty(value) };
      }

      return updatedData;
    });
  };

  const removeDobValuesIfNeeded = (data: THealthAddForm) => {
    const updatedData = { ...data };
    const totalChildrenCount =
      (updatedData.son_status ? parseInt(updatedData.son_count.value) : 0) +
      (updatedData.daughter_status
        ? parseInt(updatedData.daughter_count.value)
        : 0);
    // Clear DOB fields for children exceeding the total count
    if (totalChildrenCount < 4)
      updatedData.child_four_dob = { value: "", warning: false };
    if (totalChildrenCount < 3)
      updatedData.child_three_dob = { value: "", warning: false };
    if (totalChildrenCount < 2)
      updatedData.child_two_dob = { value: "", warning: false };
    if (totalChildrenCount < 1)
      updatedData.child_one_dob = { value: "", warning: false };

    return updatedData;
  };

  return (
    <div>
      {isMobile ? (
        <MHealthForm
          validate_form={validate_form}
          updateMasterState={updateMasterState}
          healthFormSteps={healthFormSteps}
          open={open}
          setOpen={setOpen}
          ADD_FORM_DETAILS={ADD_FORM_DETAILS}
          setHealthFormSteps={setHealthFormSteps}
          formData={formData}
          relationship_data={relationship_data}
        />
      ) : (
        <HealthForm
          validate_form={validate_form}
          updateMasterState={updateMasterState}
          healthFormSteps={healthFormSteps}
          open={open}
          setOpen={setOpen}
          ADD_FORM_DETAILS={ADD_FORM_DETAILS}
          setHealthFormSteps={setHealthFormSteps}
          formData={formData}
          relationship_data={relationship_data}
        />
      )}
    </div>
  );
};
