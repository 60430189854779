import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { CarFormContainer } from "../../../Container/Car/CarFormContainer";
import { HealthFormContainer } from "../../../Container/Health/HealthFormContainer";
import TwFormContainer from "../../../Container/TW/TwFormContainer";
import "./MHome.scss";
import { FRONTEND_DOMAIN } from "../../../Routing/CommonURLs/Domain";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";

function MHome() {
  const [openCarForm, setOpenCarForm] = useState(false);
  const [openBikeForm, setOpenBikeForm] = useState(false);
  const [openHealthForm, setOpenHealthForm] = useState(false);

  const productSlidersettings = {
    className: "center",
    // centerMode: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    // cssEase: "linear",
    arrows: false,
    dots: false,
  };

  const recentSlidersettings = {
    className: "center",
    // centerMode: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    // cssEase: "linear",
    arrows: false,
    dots: true,
  };

  const settings = {
    className: "center",
    // centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    arrows: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    dots: true,
  };

  const awardsSliderArray = [
    {
      awrds_desc:
        "The Group has achieved recognition by way of securing various prestigious awards and notable mentions:",
      awrds_img: "./images/award_img1.png",
    },
    {
      awrds_desc: `"Reinsurance Broker of the Year" from Zimbabwe Reinsurance Corporation in 1996, 1997
                                    & 1999`,
      awrds_img: "./images/award_img2.png",
    },
    {
      awrds_desc: `"Reinsurance Broker of the Year" from Asia Insurance Industry Awards : 2000`,
      awrds_img: "./images/award_img3.png",
    },
    {
      awrds_desc: `"National Organising Committee for support" during 15th African
                                    Reinsurance Forum : 2009`,
      awrds_img: "./images/award_img4.png",
    },
    {
      awrds_desc: `"Certificate of Appreciation" from Ethiopian Insurance Corporation : 2009`,
      awrds_img: "./images/award_img5.png",
    },
    {
      awrds_desc: `"Outstanding Contribution" to their business from Bajaj Allianz, India : 2012 - 13:`,
      awrds_img: "./images/award_img6.png",
    },
    {
      awrds_desc: `"Best Usage of e-Thru Platform" from GIC Re, India : 2013`,
      awrds_img: "./images/award_img7.png",
    },
    {
      awrds_desc: `"Sincere Appreciation" from Religare, India : 2013`,
      awrds_img: "./images/award_img8.png",
    },
    {
      awrds_desc: `"Plaque from Govt. House, Mongolia Ulan Bator” : 2015`,
      awrds_img: "./images/award_img8.png",
    },
  ];

  const recentSliderArray = [
    {
      img_url: "./images/story2.svg",
      story_heading: `India’s Retail Health Insurance market to touch USD 25 billion in next 5 years: Study`,
      story_desc: `Retail Health segment is expected to witness continued investor interest backed by multiple growth tailwinds and offer the ability to generate return on equity of 20per cent+... `,
      story_link: `https://health.economictimes.indiatimes.com/news/insurance/indias-retail-health-insurance-market-to-touch-usd-25-billion-in-next-5-years-study/97321646`,
    },
    {
      img_url: "./images/story3.svg",
      story_heading: `Motor insurance riders: All you need to know for a smooth ride`,
      story_desc: `Technology has transformed the way insurance works; the driving habits of policyholders can now be tapped and used to customise plans accordingly.What does one get for being a good driver? Safety, a clear conscience, and peace of mind would top the list...`,
      story_link: `https://www.moneycontrol.com/news/business/personal-finance/all-you-need-to-know-about-the-newly-added-motor-insurance-riders-for-a-smooth-ride-8846561.html`,
    },
    {
      img_url: "./images/story1.svg",
      story_heading: `Good driving habits can help you save money! Here's how Indian Govt plans to reward drivers?`,
      story_desc: `As a step towards facilitating technology-enabled covers, Irdai has permitted general insurance companies to introduce tech-enabled concepts for the OD surface...`,
      story_link: `https://zeenews.india.com/auto/drive-well-and-pay-less-insurance-premium-irdai-announces-new-scheme-to-promote-road-safety-2482307.html`,
    },
    {
      img_url: "./images/newStory1.svg",
      story_heading: `Cancer care is expensive; health insurance that covers cancer is not. Here’s how you can choose the right health insurance plan to protect yourself and your loved ones.`,
      story_desc: `Cancer looms as a major public health burden in India, with the number of cancer cases only projected to rise. So, are you protected?...`,
      story_link: `https://economictimes.indiatimes.com/wealth/insure/health-insurance/cancer-care-is-expensive-health-insurance-that-covers-cancer-is-not-heres-how-you-can-choose-the-right-health-insurance-plan-to-protect-yourself-and-your-loved-ones-/articleshow/98293547.cms`,
    },
    {
      img_url: "./images/newStory2.svg",
      story_heading: `As EVs catch fire, insurance firms face pricing risk`,
      story_desc: `While the current fires may not be covered under insurance policies, auto-insurance providers want to develop bespoke insurance options that primarily cater to these variants...`,
      story_link: `https://auto.economictimes.indiatimes.com/news/two-wheelers/as-evs-catch-fire-insurance-firms-face-pricing-risk/91464305`,
    },
    {
      img_url: "./images/newStory3.svg",
      story_heading: `Latest third-party insurance premiums for cars, bikes as fixed by IRDAI`,
      story_desc: `According to the Motor Vehicles Act of 1988, anyone who owns or operates a motor vehicle in India must have third-party insurance coverage...`,
      story_link: `https://economictimes.indiatimes.com/wealth/insure/motor-insurance/latest-third-party-insurance-premium-for-cars-bikes-as-fixed-by-irdai/articleshow/95620350.cms`,
    },
  ];

  const partnersArray = [
    "./images/insurers/Royal_Sundaram.svg",
    "./images/insurers/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg",
    "./images/insurers/Go-Digit-General-Insurance.svg",
    "./images/insurers/Star-Health-and-Allied-Insurance-Company-Limited.svg",
    "./images/insurers/Reliance-General-Insurance-Co-Ltd.svg",
    "./images/insurers/Religare-Health-Insurance-Company-Limited.svg",
    "./images/insurers/Cholamandalam_MS_General_Insurance.svg",
    "./images/insurers/IFFCO-Tokio-General-Insurance-Co-Ltd.svg",
    "./images/insurers/sbi_general_insurance_company_limited.svg",
    "./images/insurers/Tata-AIG-General-Insurance-Co-Ltd.svg",
    "./images/insurers/bajaj_logo.svg",
    "./images/insurers/the_new_india_insurance_co_ltd.svg",
    "./images/insurers/ICICI-Lombard-General-Insurance-Co-Ltd.svg",
    "./images/insurers/HDFC-ERGO-General-Insurance-Co-Ltd.svg",
  ];

  return (
    <Box className="mhomepage-wrapper">
      {openCarForm ? (
        <CarFormContainer open={openCarForm} setOpen={setOpenCarForm} />
      ) : null}
      {openBikeForm ? (
        <TwFormContainer open={openBikeForm} setOpen={setOpenBikeForm} />
      ) : null}
      {openHealthForm ? (
        <HealthFormContainer
          open={openHealthForm}
          setOpen={setOpenHealthForm}
        />
      ) : null}

      <MNavBar />

      <Box className="product-section-header_m">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
            <h3 className="mb-2">Insurance Policies Made For You.</h3>
            <p>Search, Compare & Apply for customised policies free of cost.</p>
          </Grid>
          <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
            <img
              src="./images/header-new-img.png"
              alt=""
              className="header-img"
            />
          </Grid>
          <Grid xs={12} textAlign={"center"} marginBottom={"48px"}>
            <ul className="product-List">
              <li>
                <Link
                  onClick={() => {
                    window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.HEALTH_FORM}`;
                  }}>
                  <img src="./images/health-product.svg" alt="" />
                  Health
                  <br /> Insurance
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.CAR_FORM}`;
                  }}>
                  <img src="./images/car-product.svg" alt="" />
                  Four Wheeler
                  <br /> Insurance
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TW_FORM}`;
                  }}>
                  <img src="./images/bike-product.svg" alt="" />
                  Two Wheeler
                  <br /> Insurance
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid xs={12} textAlign={"center"}>
            <img src="./images/ad_journey_link_icon.svg" alt="" />
            <h6 className="mt-2 mb-3">
              "Empower your LIFE journey with Comprehensive HEALTH coverage"
            </h6>
            <CustomButton
              className="primaryBtn medium"
              text={"For free advisory journey, click here..."}
              fullWidth={false}
              variant="contained"
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="bluebg-section_m">
        <Grid container columnSpacing={3}>
          <Grid xs={12}>
            <ul className="irda-sectionlist">
              <li>
                <span className="irda"></span>
                <h6>
                  IRDAI/WBA29/2015<span>Licensed by IRDAI</span>{" "}
                </h6>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="achievementsbg_box_m" marginBottom={"20px"}>
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"}>
            <h3 className="mb-2">Achievements</h3>
            <h6>
              Over 7 decades, the J.B.Boda Group has recieved notable
              recognition globally.
            </h6>
          </Grid>
        </Grid>
      </Box>

      <Box className="awrds_sec_m" marginBottom={"40px"}>
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"}>
            <Slider {...productSlidersettings}>
              {awardsSliderArray?.map((data, index) => (
                <Box padding={"0"}>
                  <Box>
                    <img src={data.awrds_img} alt="" />
                    <p>{data.awrds_desc}</p>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Box>

      <Box className="recent-stories-section_m">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <h3>Recent stories</h3>
          </Grid>
          <Grid xs={12} padding={0}>
            <Slider {...recentSlidersettings}>
              {recentSliderArray?.map((data, index) => (
                <Box padding={"0 12px"} margin={"16px 0"}>
                  <Box className="recent_story_box">
                    <img
                      src={data.img_url}
                      alt="story-image"
                      className="story-img"
                    />
                    <Box marginTop={"24px"}>
                      <h5>{data.story_heading}</h5>
                      <p>{data.story_desc}</p>
                      <a
                        href={data.story_link}
                        target="_blank"
                        className="view_more">
                        View More <ChevronRightIcon />
                      </a>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Box>

      <Box className="why-choose-sec_m" marginBottom={"60px"}>
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} marginBottom={"30px"} textAlign={"center"}>
            <h3>Why choose us ?</h3>
            <p>We are here to stay for lifetime</p>
          </Grid>
          <Grid xs={12}>
            <ul className="wc_points">
              <li>
                <span>
                  <img src="./images/wc_icon1.svg" alt="" />
                </span>
                <h5>Policy issued in less than 1 min:</h5>
                <p>
                  Pre-Filled details if you are an existing customer with 4 step
                  simple journey
                </p>
              </li>
              <li>
                <span>
                  <img src="./images/wc_icon2.svg" alt="" />
                </span>
                <h5>Reminders:</h5>
                <p>
                  Renewal reminders and dedicated Relationship Manager’s
                  assigned.
                </p>
              </li>
              <li>
                <span>
                  <img src="./images/wc_icon3.svg" alt="" />
                </span>
                <h5>Doubts solved:</h5>
                <p>
                  Qualified Insurance professionals to solve all your doubts
                </p>
              </li>
              <li>
                <span>
                  <img src="./images/wc_icon4.svg" alt="" />
                </span>
                <h5>Easy to understand:</h5>
                <p>
                  Nicely explained insurance products with clarifications which
                  can be understood to any age group.
                </p>
              </li>
              <li>
                <span>
                  <img src="./images/wc_icon5.svg" alt="" />
                </span>
                <h5>Lifetime Personalized Account:</h5>
                <p>
                  You will be provided with a lifetime personalized account for
                  seamless policy management.
                </p>
              </li>
              <li>
                <span>
                  <img src="./images/wc_icon6.svg" alt="" />
                </span>
                <h5>Easy to compare and buy:</h5>
                <p>
                  No need to search options from the market. We have best
                  options from the market under a single window.
                </p>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="policy-issuance-section_m" marginBottom={"60px"}>
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} textAlign={"center"}>
            <Box className="step_policy_box">
              <h3 className="mb-2">Step Policy issuance</h3>
              <p>We do the work, you stay worry-free.</p>
            </Box>
            <ul>
              <li className="one">
                <span className="oneImg"></span>
                <img
                  src="./images/4steps1.svg"
                  alt="step-icon"
                  className="step_icon"
                />
                <h5>Search</h5>
                <p>Search the plans according to your need</p>
              </li>
              <li className="two">
                <span className="twoImg"></span>
                <img
                  src="./images/4steps2.svg"
                  alt="step-icon"
                  className="step_icon"
                />
                <h5>Compare</h5>
                <p>Compare the plans from the list</p>
              </li>
              <li className="three">
                <span className="threeImg"></span>
                <img
                  src="./images/4steps3.svg"
                  alt="step-icon"
                  className="step_icon"
                />
                <h5>Buy</h5>
                <p>Choose & buy the plan the suits you the best</p>
              </li>
              <li className="four">
                <span className="fourImg"></span>
                <img
                  src="./images/4steps4.svg"
                  alt="step-icon"
                  className="step_icon"
                />
                <h5>Get Policy</h5>
                <p>Get the policy in your inbox instantly</p>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="partners-sec_m">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <h3>Our Partners</h3>
          </Grid>
          <Grid xs={12} padding={0} marginBottom={"24px"}>
            <Slider {...settings}>
              {partnersArray?.map((data, index) => (
                <Box padding={"0 12px"}>
                  <Box textAlign={"center"}>
                    <img src={data} alt="" className="partner_img" />
                  </Box>
                </Box>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Box>

      <MFooter />
    </Box>
  );
}

export default MHome;
