import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./../../../SCSS/CMSPages.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";

const Aboutus = () => {
  const isMobile = useIsMobile();
  return (
    <Box className="cmsPage-layout">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="aboutPage-layout">
        <Box className="about_header mb-margin2">
          <Grid container className="row" columnSpacing={3}>
            <Grid xs={12}>
              <h1 className="mb-2">The Group</h1>
              <h4>
                We are an experienced and creative company that is prepared to
                offer you fresh ideas and solutions.
              </h4>
            </Grid>
          </Grid>
        </Box>

        <Grid container className="row abt-grp mb-margin2" columnSpacing={3}>
          <Grid xs={12}>
            <h1 className="mb-4">About Us - The Group</h1>
            <p>
              J B BODA INSURANCE & REINSURANCE BROKERS may be almost a century
              old, but we are just getting started with our online journey with
              Walnnut.com!
            </p>
            <p>
              J B BODA INSURANCE & REINSURANCE BROKERS (Walnnut.com) They
              pioneered Reinsurance Broking in India and today we are the
              largest and oldest Reinsurance Brokers in India, Asia, Africa,
              Middle-East, Developing countries, and the first Indian
              Multinational Reinsurance Broking House operating for over seven
              decades.
            </p>
            <p>
              Our nationwide chain of offices, staffed with an experienced team,
              makes buying insurance and registering a claim a convenient
              episode on walnnut.com which is an online presence of J B BODA
              INSURANCE & REINSURANCE Brokers Pvt Ltd.
            </p>
            <p>
              We pride ourselves on a seamless online portal for the first time
              on J B BODA platform for Retail Products ,where you can buy Motor
              , Two-Wheeler & Health insurance products.Because we’ve been
              around for seven decades, you directly benefit from our excellent
              relationship with leading insurance companies. The strong
              relationships with insurance service providers across product
              types ensure that we can offer the state of art solution to our
              customers, which is both relevant and right-sized, partnering with
              them in their growth.
            </p>
          </Grid>
        </Grid>

        <Grid container className="row" columnSpacing={3} marginBottom={"24px"}>
          <Grid xs={12}>
            <Box className="vision_box">
              <h1 className="mb-3">Vision</h1>
              <p>
                Driven by the vision to emerge as a leading insurance
                intermediary for Retail and Corporate consumers across regions &
                segments, we at J B BODA Insurance & Reinsurance Brokers Pvt Ltd
                are committed to nurture and grow clients through a great buying
                experience, delivered through technological interventions.
              </p>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          className="row mb-margin2"
          columnSpacing={3}
          marginBottom={"80px"}
        >
          <Grid xs={12}>
            <Box className="mission_box">
              <h1 className="mb-3">Mission</h1>
              <ul className="bluedot_list">
                <li>
                  To be India's broker of choice for innovative insurance
                  products with a reputation for the quality of service and for
                  speed of implementation and execution.
                </li>
                <li>
                  To be among India's finest employers, to foster a culture of
                  inclusion and empowerment, with paths to success available for
                  all through career development, continuous learning,
                  leadership opportunities and work-life balance.
                </li>
                <li>
                  To be a profitable partner to clients and to insurance
                  companies and have a track record of conducting every
                  relationship with fairness, transparency and a 'win win'
                  emphasis.
                </li>
                <li>
                  To be a stable, consistent, and long-term player, acknowledge
                  for the durability of achievements and the certain of an
                  industry- redefining future.
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          className="row mb-margin2"
          columnSpacing={3}
          marginBottom={"80px"}
          alignItems={"center"}
        >
          <Grid xs={12} md={7} className="mb-margin1">
            <h1 className="mb-3" style={{ textAlign: "left" }}>
              Values & Beliefs
            </h1>
            <ul className="bluedot_list">
              <li>
                To continue the <span>LEGACY</span> of an incredible journey.
              </li>
              <li>
                We will treat all with <span>DIGNITY & HONOUR</span>.
              </li>
              <li>
                We seek <span>OPPORTUNITIES</span> - They are{" "}
                <span>EVERYWHERE</span> and bring <span>ENTREPRENEURSHIP</span>.
              </li>
              <li>
                We value a deep sense of <span>RESPONSIBILITY</span> and
                <span>SELF-DISCIPLINE</span> to meet and exceed our{" "}
                <span>COMMITMENTS</span>.
              </li>
              <li>
                We will grow as a <span>TEAM</span> to <span>ENCOURAGE</span>,
                <span>RISE & EMPOWER</span> boundaryless approaches.
              </li>
              <li>
                <span>TOGETHER</span> towards a better future.
              </li>
            </ul>
          </Grid>
          <Grid xs={12} md={5}>
            <img src="./images/About_value_image.svg" alt="" width={"100%"} />
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default Aboutus;
