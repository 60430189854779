import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../Component/InputFields/CustomButton/CustomButton";
import CustomCheckbox from "../../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../../Component/InputFields/DatePicker/DatePicker";
import NativeSelectDropdown from "../../../../Component/InputFields/NativeSelectDropdown/NativeSelectDropdown";
import RKTextField from "../../../../Component/InputFields/RKTextField/RKTextField";
import { useAppSelector } from "../../../../Store/Store/hooks";
import { COLORS } from "../../../../SupportingFiles/colors";
import { TDropdown } from "../../../../Type/Common/TDropdown";
import { THealthAddForm } from "../../../../Type/Health/THealthSlice/THealthSlice";
import { subDays, subYears } from "date-fns";
import SelectDropdown from "../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import {
  FORMAT_DATE_DD_MM_YYYY,
  calculateAgeInDays,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";

interface Props {
  open: boolean;
  setOpen: Function;
  validate_form: Function;
  updateMasterState: Function;
  healthFormSteps: any;
  ADD_FORM_DETAILS: Function;
  setHealthFormSteps: Function;
  formData: THealthAddForm;
  relationship_data: TDropdown[];
}

const MHealthForm: React.FC<Props> = ({
  open,
  setOpen,
  validate_form,
  updateMasterState,
  healthFormSteps,
  setHealthFormSteps,
  formData,
  relationship_data,
}) => {
  const navigate = useNavigate();
  const { GENDER } = useAppSelector((state) => state.CommonDropdownData);
  return (
    <>
      {open ? (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: "#ffffff",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            paddingTop: "84px",
            overflowY: "auto",
            zIndex: "999",
          }}
        >
          {/* topnav */}
          <Box
            sx={{
              backgroundColor: "rgba(255, 243, 244, 1)",
              height: "60px",
              position: "fixed",
              top: "0px",
              width: "100%",
              zIndex: "99",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              className="row"
              spacing={3}
              alignItems="center"
              width="100%"
            >
              <Grid xs={12} paddingLeft={"40px"} paddingRight={"40px"}>
                {healthFormSteps === "2" ? (
                  <Link
                    style={{
                      position: "absolute",
                      left: "12px",
                      top: "19px",
                      display: "inline-block",
                      cursor: "pointer",
                      paddingRight: "4px",
                      color: "#000",
                      height: "20px",
                    }}
                    onClick={() => setHealthFormSteps("1")}
                  >
                    <ArrowBackIosNewRoundedIcon sx={{ fontSize: "20px" }} />
                  </Link>
                ) : null}
                <h6 style={{ textAlign: "center" }}>
                  {healthFormSteps === "healthStep2"
                    ? "Personal Details"
                    : "Find the Best Health Insurance"}
                </h6>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                  sx={{
                    position: "absolute",
                    right: "12px",
                    top: "19px",
                    maxWidth: "22px",
                    minWidth: "22px",
                    maxHeight: "22px",
                    color: COLORS.white,
                    backgroundColor: COLORS.primary,
                    borderRadius: "60px",
                    transition: "all ease-in-out 0.2s",
                    "&:hover": {
                      backgroundColor: COLORS.darkGrey,
                      transform: "rotate(-90deg)",
                    },
                  }}
                >
                  <CloseIcon style={{ height: "18px", width: "18px" }} />
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* contentsec */}
          <Box>
            {healthFormSteps === "1" ? (
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
              >
                <Grid xs={12} marginBottom={"12px"}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                    }}
                  >
                    Select the family members you want to insure
                  </p>
                </Grid>
                <Grid xs={6} paddingRight={"6px"}>
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "10px 12px 10px 8px",
                      marginBottom: "12px",
                      position: "relative",
                    }}
                    className="self-selectField"
                  >
                    <CustomCheckbox
                      label="One Adult"
                      attrName={"self_status"}
                      value={formData?.self_status}
                      isChecked={
                        !formData.spouse_status && formData.self_status
                      }
                      value_update={(newValue: any) => {
                        if (newValue) {
                          updateMasterState("spouse_status", false);
                        }
                        updateMasterState("self_status", newValue);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid xs={6} paddingLeft={"6px"}>
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "10px 12px 10px 8px",
                      marginBottom: "12px",
                    }}
                  >
                    <CustomCheckbox
                      label="Two Adults"
                      attrName={"spouse_status"}
                      value={formData?.spouse_status}
                      isChecked={formData.spouse_status}
                      value_update={() =>
                        updateMasterState(
                          "spouse_status",
                          !formData.spouse_status
                        )
                      }
                    />
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "10px 12px 10px 8px",
                      marginBottom: "12px",
                      position: "relative",
                    }}
                  >
                    <CustomCheckbox
                      label="Son"
                      attrName={"son_status"}
                      value={formData?.son_status}
                      value_update={updateMasterState}
                      defaultChecked={formData?.son_status}
                      isChecked={formData?.son_status}
                    />
                    {formData.son_status && (
                      <Box
                        position="absolute"
                        right="8px"
                        top="12px"
                        display="flex"
                        alignItems="center"
                        gap="8px"
                        zIndex="9"
                      >
                        <Box
                          onClick={() => updateMasterState("son_subtract", "")}
                          height="16px"
                          width="16px"
                          color={COLORS.primary}
                          textAlign="center"
                          sx={{ cursor: "pointer" }}
                        >
                          <RemoveRoundedIcon
                            sx={{ width: "16px", height: "16px" }}
                          />
                        </Box>
                        <Box
                          height="16px"
                          width="16px"
                          color={COLORS.primary}
                          borderRadius="40px"
                          textAlign="center"
                          sx={{
                            backgroundColor: "rgba(255, 243, 244, 1)",
                            fontSize: "12px",
                          }}
                        >
                          {formData.son_count.value}
                        </Box>
                        <Box
                          onClick={() => updateMasterState("son_add", "")}
                          height="16px"
                          width="16px"
                          color={COLORS.primary}
                          textAlign="center"
                          sx={{ cursor: "pointer" }}
                        >
                          <AddRoundedIcon
                            sx={{ width: "16px", height: "16px" }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid xs={12} marginBottom={"8px"}>
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "10px 12px 10px 8px",
                      marginBottom: "12px",
                      position: "relative",
                    }}
                  >
                    <CustomCheckbox
                      label="Daughter"
                      attrName={"daughter_status"}
                      value={formData?.daughter_status}
                      value_update={updateMasterState}
                      isChecked={formData?.daughter_status}
                      defaultChecked={formData?.daughter_status}
                    />
                    {formData.daughter_status && (
                      <Box
                        position="absolute"
                        right="8px"
                        top="12px"
                        display="flex"
                        alignItems="center"
                        gap="8px"
                        zIndex="9"
                      >
                        <Box
                          onClick={() =>
                            updateMasterState("daughter_subtract", "")
                          }
                          height="16px"
                          width="16px"
                          color={COLORS.primary}
                          textAlign="center"
                          sx={{ cursor: "pointer" }}
                        >
                          <RemoveRoundedIcon
                            sx={{ width: "16px", height: "16px" }}
                          />
                        </Box>
                        <Box
                          height="16px"
                          width="16px"
                          color={COLORS.primary}
                          borderRadius="40px"
                          textAlign="center"
                          sx={{
                            backgroundColor: "rgba(255, 243, 244, 1)",
                            fontSize: "12px",
                          }}
                        >
                          {formData.daughter_count.value}
                        </Box>
                        <Box
                          onClick={() => updateMasterState("daughter_add", "")}
                          height="16px"
                          width="16px"
                          color={COLORS.primary}
                          textAlign="center"
                          sx={{ cursor: "pointer" }}
                        >
                          <AddRoundedIcon
                            sx={{ width: "16px", height: "16px" }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid xs={12} marginBottom={"12px"}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                    }}
                  >
                    Select age of your family members
                  </p>
                </Grid>
                <Grid xs={6} marginBottom={"20px"} paddingRight={"6px"}>
                  <DatePicker
                    class_name="inputField"
                    title={"1st Adult DOB"}
                    value={formData.self_dob.value}
                    attrName={"self_dob"}
                    value_update={updateMasterState}
                    warn_status={formData.self_dob.warning}
                    error_message={"Select 1st Adult DOB"}
                    min_date={110}
                    max_date={18}
                    date_validation_type="YEARS"
                    default_date={subYears(new Date(), 18)}
                  />
                </Grid>
                <Grid xs={6} marginBottom={"20px"} paddingLeft={"6px"}>
                  <SelectDropdown
                    class_name="inputField"
                    title={"Gender"}
                    attrName={"gender"}
                    value={formData.gender.value}
                    value_update={updateMasterState}
                    data={GENDER}
                    warn_status={formData.gender.warning}
                    error_message={"Select Gender"}
                  />
                </Grid>
                {formData.spouse_status && (
                  <>
                    <Grid xs={6} marginBottom={"20px"} paddingRight={"6px"}>
                      <DatePicker
                        class_name="inputField"
                        title={"2nd Adult DOB"}
                        value={formData.spouse_dob.value}
                        attrName={"spouse_dob"}
                        value_update={updateMasterState}
                        warn_status={formData.spouse_dob.warning}
                        error_message={"Select 2nd Adult DOB"}
                        min_date={110}
                        max_date={18}
                        date_validation_type="YEARS"
                        default_date={subYears(new Date(), 18)}
                      />
                    </Grid>
                    <Grid xs={6} marginBottom={"20px"} paddingLeft={"6px"}>
                      <NativeSelectDropdown
                        class_name="inputField"
                        title={"Select Relationship"}
                        attrName={"spouse_relationship"}
                        value={formData?.spouse_relationship?.value}
                        value_update={updateMasterState}
                        data={relationship_data}
                        warn_status={formData.spouse_relationship.warning}
                        error_message={"Relationship with 1st adult"}
                      />
                    </Grid>
                  </>
                )}
                {(formData.son_status
                  ? parseInt(formData.son_count.value)
                  : 0) +
                  (formData.daughter_status
                    ? parseInt(formData.daughter_count.value)
                    : 0) >=
                  1 && (
                  <Grid xs={6} marginBottom={"20px"} paddingRight={"6px"}>
                    <DatePicker
                      class_name="inputField"
                      title={"1st Child DOB"}
                      value={formData?.child_one_dob?.value}
                      attrName={"child_one_dob"}
                      value_update={updateMasterState}
                      warn_status={formData?.child_one_dob?.warning}
                      error_message={"Select 1st Child DOB"}
                      min_date={calculateAgeInDays(
                        FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 25)}`)
                      )}
                      max_date={91}
                      date_validation_type="DAYS"
                      default_date={subDays(new Date(), 91)}
                    />
                  </Grid>
                )}
                {(formData.son_status
                  ? parseInt(formData.son_count.value)
                  : 0) +
                  (formData.daughter_status
                    ? parseInt(formData.daughter_count.value)
                    : 0) >=
                  2 && (
                  <Grid xs={6} marginBottom={"20px"} paddingLeft={"6px"}>
                    <DatePicker
                      class_name="inputField"
                      title={"2nd Child DOB"}
                      value={formData?.child_two_dob?.value}
                      attrName={"child_two_dob"}
                      value_update={updateMasterState}
                      warn_status={formData?.child_two_dob?.warning}
                      error_message={"Select 2nd Child DOB"}
                      min_date={calculateAgeInDays(
                        FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 25)}`)
                      )}
                      max_date={91}
                      date_validation_type="DAYS"
                      default_date={subDays(new Date(), 91)}
                    />
                  </Grid>
                )}
                {(formData.son_status
                  ? parseInt(formData.son_count.value)
                  : 0) +
                  (formData.daughter_status
                    ? parseInt(formData.daughter_count.value)
                    : 0) >=
                  3 && (
                  <Grid xs={6} marginBottom={"20px"} paddingRight={"6px"}>
                    <DatePicker
                      class_name="inputField"
                      title={"3rd Child DOB"}
                      value={formData?.child_three_dob?.value}
                      attrName={"child_three_dob"}
                      value_update={updateMasterState}
                      warn_status={formData?.child_three_dob?.warning}
                      error_message={"Select 3rd Child DOB"}
                      min_date={calculateAgeInDays(
                        FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 25)}`)
                      )}
                      max_date={91}
                      date_validation_type="DAYS"
                      default_date={subDays(new Date(), 91)}
                    />
                  </Grid>
                )}
                {(formData.son_status
                  ? parseInt(formData.son_count.value)
                  : 0) +
                  (formData.daughter_status
                    ? parseInt(formData.daughter_count.value)
                    : 0) >=
                  4 && (
                  <Grid xs={6} marginBottom={"20px"} paddingLeft={"6px"}>
                    <DatePicker
                      class_name="inputField"
                      title={"4th Child DOB"}
                      value={formData?.child_four_dob?.value}
                      attrName={"child_four_dob"}
                      value_update={updateMasterState}
                      warn_status={formData?.child_four_dob?.warning}
                      error_message={"Select 4th Child DOB"}
                      min_date={calculateAgeInDays(
                        FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 25)}`)
                      )}
                      max_date={91}
                      date_validation_type="DAYS"
                      default_date={subDays(new Date(), 91)}
                    />
                  </Grid>
                )}
                <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                  <CustomButton
                    className="primaryBtn "
                    text="Continue"
                    onClick={() => validate_form()}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
              </Grid>
            ) : healthFormSteps === "2" ? (
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid xs={12} textAlign={"center"} marginBottom={"12px"}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                    }}
                  >
                    Enter Personal Details
                  </p>
                </Grid>
                <Grid xs={6} marginBottom={"20px"} paddingRight={"6px"}>
                  <RKTextField
                    class_name="inputField"
                    title={"Enter mobile number"}
                    value={formData.mobile.value}
                    attrName={"mobile"}
                    max_length={10}
                    validation_type="NUMBER"
                    value_update={updateMasterState}
                    warn_status={formData.mobile.warning}
                    error_message={
                      isEmpty(formData.mobile.value)
                        ? "Enter Mobile Number"
                        : "Enter Valid Mobile Number"
                    }
                  />
                </Grid>
                <Grid xs={6} marginBottom={"20px"} paddingLeft={"6px"}>
                  <RKTextField
                    class_name="inputField"
                    title={"Enter your pincode"}
                    value={formData.pincode.value}
                    attrName={"pincode"}
                    value_update={updateMasterState}
                    warn_status={formData.pincode.warning}
                    max_length={6}
                    validation_type="NUMBER"
                    error_message={
                      isEmpty(formData.pincode.value)
                        ? "Enter Pincode"
                        : "Enter Valid Pincode"
                    }
                  />
                </Grid>

                <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                  <CustomButton
                    className="primaryBtn "
                    text="View Quotes"
                    onClick={() => {
                      validate_form();
                    }}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid
                  xs={12}
                  textAlign={"center"}
                  marginBottom={"24px"}
                  className="popup-Checkbox"
                >
                  <CustomCheckbox
                    defaultChecked={true}
                    disabled
                    label={
                      <>
                        By clicking on view quotes you agree to all {""}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(COMMON_ROUTES.TERM_AND_CONDTION)
                          }
                        >
                          Terms & Conditions
                        </span>
                      </>
                    }
                    attrName={"termCondition"}
                    value={true}
                    // value_update={handleCheckbox}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default MHealthForm;
