import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { COLORS } from "../../SupportingFiles/colors";
import "./popup.scss";

interface PopupProps {
  open: boolean;
  handleClose: () => void;
  variant?: "xsmall" | "small" | "medium" | "large";
  title?: string;
  heading_subDesc?: string;
  content: React.ReactNode;
}

const Popup: React.FC<PopupProps> = ({
  open,
  handleClose,
  variant = "medium",
  title,
  heading_subDesc,
  content,
}) => {
  const variantData: {
    [key in "xsmall" | "small" | "medium" | "large"]: string;
  } = {
    xsmall: "412px",
    small: "600px",
    medium: "800px",
    large: "100px",
  };

  return (
    <Dialog
      open={open}
      scroll={"body"}
      className="popup"
      sx={{
        ".MuiPaper-root": {
          // boxShadow: "0px 4px 20px rgba(0,0,0,0.15)",
          maxWidth: variantData[variant],
          width: "100%",
          borderRadius: "16px",
          overflow: "visible",
          padding: "24px 12px",
          "@media (max-width: 767px)": {
            maxWidth: "calc(100% - 24px)!important",
            margin: "12px",
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <h3 className="mt-6">{title}</h3>
        <p className="mt-2">{heading_subDesc}</p>
        <Button
          onClick={handleClose}
          sx={{
            position: "absolute",
            top:"0",
            right: "12px",
            maxWidth: "29px",
            minWidth: "29px",
            maxHeight: "26px",
            color: COLORS.white,
            backgroundColor: "#ff0000",
            borderRadius: "4px",
            transition: "all ease-in-out 0.2s",
            "&:hover": {
              transform: "rotate(-180deg)",
              color: COLORS.white,
              backgroundColor: "#ff0000",
            },
            "@media (max-width: 767px)": {
              maxWidth: "22px",
              minWidth: "22px",
              maxHeight: "22px",
            },
          }}
        >
          <CloseIcon style={{ height: "18px", width: "18px" }} />
        </Button>
      </Box>
      {content}
    </Dialog>
  );
};

export default Popup;
