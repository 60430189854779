import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./../../../SCSS/ProductPage.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import FAQ from "../../../Component/FAQ/FAQ";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { COLORS } from "../../../SupportingFiles/colors";
import TWProductContainer from "../../../Container/TWProductContainer/TWProductContainer";

const TWProductPage = () => {
  const isMobile = useIsMobile();
  const [value, setValue] = React.useState("1");
  const [covrageTabvalue, setCovrageTabvalue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const handleTabsChange = (
    event: React.SyntheticEvent,
    newTabsValue: string
  ) => {
    setCovrageTabvalue(newTabsValue);
  };

  const benefitsArray = [
    {
      title: "No More Agents",
      desc: `Always read the fine print of the policy you choose. Check out the inclusions and exclusions so that you know the scope of the policy. This will ensure that you are not caught off-guard when you suffer a claim.`,
    },
    {
      title: "Zero Paperwork",
      desc: `Though the third party coverage fulfils the legal obligations, it does not cover the damages that your car might suffer. Such damages can also incur considerable financial losses and so, it is best to cover them as well. So, opt for comprehensive plans for a wider scope of coverage.`,
    },
    {
      title: "Convenient & Saves Time",
      desc: `This, again, is a common practice that you should follow. Since comparison helps you find the best policy, always compare the available plans online and then invest in the best policy.`,
    },
    {
      title: "Easy Comparison",
      desc: `Choosing the right insurance company is as vital as choosing the right insurance policy. You must always check the incurred claim ratio of the insurance company you choose, which is the amount of money paid out as claims against the total money collected as a premium. Ideally, the ICR should not be too high, which would mean that the company doesn't have enough surplus to pay claims and neither should it be too low, which would mean that the company is overcharging the premium.`,
    },
    {
      title: "Easy customisation",
      desc: `Add-ons enhance the scope of coverage of the policy and can prove to be a great value addition, especially at the time of claims. So, choose suitable add-ons, depending on your needs, to make your coverage all-inclusive`,
    },
    {
      title: "Cost-Effective",
      desc: `If you want a cashless claim settlement, which is easier, always check out the network of garages the insurance company is tied up with. The information is easily available on the insurer’s website and you should choose an insurer with the largest network so that locating a preferred garage becomes easier.`,
    },
    {
      title: "Instant policy issuance",
      desc: `Comprehensive plans allow a host of premium discounts, besides the no claim discount. Look for the available discounts and try and claim as many as you can. This would reduce your car insurance premiums and make the policy more affordable.`,
    },
  ];

  const twAddonsArray = [
    {
      title: "Roadside assistance",
      desc: `If your two-wheeler breaks down in the middle of a remote place and you cannot take it to the nearest garage, this add-on proves handy. The add-on provides round-the-clock assistance for flat tyres, battery jump-start, fuel assistance, etc.`,
    },
    {
      title: "Zero depreciation cover",
      desc: `At the time of claims, the depreciation of the parts repaired or replaced is deducted from the claim amount. This reduces the admissible claim amount and increases your out-of-pocket expenses. However, through this add-on, the effect of depreciation becomes nil and you get full settlement of the claim`,
    },
    {
      title: "NCB Protect",
      desc: `The no claim bonus that you earn becomes zero if you make any claim in a policy year. This add-on protects the no claim bonus and allows you to claim the relevant discount on renewal even when you suffer a claim`,
    },
    {
      title: "Return to invoice",
      desc: `Under this add-on the insurance company pays the invoice value of the bike if it is damaged beyond repairs or if it is stolen`,
    },
    {
      title: "Lost key replacement",
      desc: `If you lose your two-wheeler keys, the add-on would cover the cost of replacing them`,
    },
    {
      title: "Engine protection	",
      desc: `This add-on covers the possible damages to the engine due to water logging or water seepage`,
    },
    {
      title: "Personal accident cover for pillion rider",
      desc: `Under this add-on you can avail of an additional personal accident cover for the pillion rider`,
    },
  ];

  const twFeaturesArray = [
    {
      title: "Comprehensive and Liability Only Coverage",
      desc: `If you have a two-wheeler, you have the option to choose a comprehensive or third-party insurance cover.`,
    },
    {
      title: "Coverage tenure",
      desc: `Two wheeler insurance plans are available as annual plans or as long term insurance plans. You can buy either of these policies and get covered. However, for new bikes, mandatory 5-year third party coverage is a must. You can opt for this mandatory cover and then an annual standalone policy or a bundled policy which offers 5 year third party coverage and 1 year own damage cover.`,
    },
    {
      title: "Optional Coverage",
      desc: `You can choose from a long list of optional covers in your two wheeler insurance policy. These optional coverage benefits are called add-ons and they are available at an additional premium under comprehensive two-wheeler insurance plans.`,
    },
    {
      title: "Easy Transfer of No Claim Bonus (NCB)",
      desc: `If you do not make claims in your policy in a year, you receive a no claim bonus which allows a premium discount on renewals. Your no claim bonus can be easily transferred to another insurer if you port your policy. Moreover, if you sell your bike and buy a new one, you can transfer the accumulated no claim bonus to the new bike insurance policy as well.`,
    },
    {
      title: "Quick Issuance for Online Purchase",
      desc: `Two wheeler insurance plans are available online. If you buy bike insurance online, the policy would be issued instantly after you provide the information of your bike, finalize the coverage, and pay the premium.`,
    },
    {
      title: "Personal Accident Cover of Rs. 15 Lakh",
      desc: `The IRDAI has made it mandatory for you to have a personal accident cover that covers accidental deaths and disablements. You can buy a cover of Rs.15 lakhs at an affordable premium with your two wheeler insurance policy. However, if you already have a personal accident cover in your name, you don’t have to buy another one with your two wheeler insurance plan.`,
    },
    {
      title: "Discounts",
      desc: `The IRDAI approves of several discounts on the purchase of a two wheeler insurance policy on selected grounds. For example, if you are a member of an automobile association, have an anti-theft device installed, etc., can get you some great discounts. If you have a previous NCB too, you can get a discount on the renewal premium. Compare bike insurance policy online to look for the maximum discounts.`,
    },
  ];
  return (
    <Box className="productPage-layout">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="header-Form-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} textAlign={"center"} marginBottom={"32px"}>
            <h2 className="mb-4">
              Clarify All Doubts Related To Two Wheeler Insurance
            </h2>
            <p>
              In India, the rules for vehicles running on the road are governed
              by the Motor Vehicles Act, 1988. The Act specifies that every car
              and two-wheeler should carry a valid third party insurance policy
              if it is to run on Indian roads. So, if you own a bike or a
              scooter, you need a two wheeler insurance plan on it to run your
              vehicle legally.
            </p>
          </Grid>
          <Grid xs={12} textAlign={"center"}>
            <Box className="formBox">
              <TWProductContainer />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="benefits-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Box className="benfitsbox">
              <Box className="headerBox">
                <h2>Benefits of Buying Two Wheeler Insurance Policy</h2>
              </Box>
              <Box className="contentBox">
                <Grid container columnSpacing={3}>
                  <Grid xs={12} md={9}>
                    <p>
                      A two wheeler policy is a mandatory cover that is needed
                      when you drive your bike on the road. Besides being a
                      mandatory cover, a two-wheeler insurance policy provides
                      you with complete financial security when using your bike.
                      There are other benefits of a two-wheeler insurance policy
                      that makes the policy a useful addition to your portfolio.
                      Let’s have a look at the various benefits that you can
                      avail from a two wheeler insurance policy:
                    </p>
                  </Grid>
                  <Grid xs={12} md={3}>
                    <img
                      src="./images/bike-product-img.svg"
                      alt=""
                      className="bikeProduct-img mb-3"
                    />
                  </Grid>
                  <Grid xs={12}>
                    <ul className="twBenefits-list">
                      <li>
                        <span>Coverage against unforeseen contingencies</span>A
                        two wheeler insurance policy covers the unforeseen
                        contingencies that you might suffer in an emergency.
                        When using the bike, you might suffer emergencies when
                        using the bike on the road. Moreover, theft is also a
                        contingency that causes considerable financial loss. A
                        bike insurance policy covers these contingencies and
                        gives you security in the face of emergencies.
                      </li>
                      <li>
                        <span>Financial Protection</span>
                        The main benefit of a bike insurance policy is financial
                        protection. By covering unforeseen contingencies the
                        policy gives you financial protection. It ensures that
                        the financial losses suffered in contingencies are
                        compensated so that you don’t suffer the loss yourself.
                      </li>
                      <li>
                        <span>Covers all types of Two Wheelers</span>
                        Two wheeler insurance plans allow coverage for all types
                        of two-wheelers. Whether you have a scooter, moped,
                        bike, whether new or used, you can avail of coverage
                        under the policy.
                      </li>
                      <li>
                        <span>Fulfils the legal mandate</span>Since a third
                        party coverage is mandatory, a two wheeler insurance
                        plan becomes a must. You need the policy to cover the
                        legal mandate specified under the Motor Vehicles Act,
                        1988. If you drive your bike without a valid cover, you
                        face legal consequences. When you invest in a bike
                        insurance plan you can avoid legal consequences and
                        drive without violating any rules.
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="types-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <h2 className="mb-4">Types Of Two Wheeler Insurance Plans</h2>
            <p className="mb-4">
              There are various types of two-wheeler insurance plans. These
              include the following:
            </p>
          </Grid>
          <Grid xs={12} className="types-tabs">
            <TabContext value={value}>
              <Box>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label={
                      <>
                        Third-party
                        <br /> Liability
                      </>
                    }
                    value="1"
                  />
                  <Tab
                    label={
                      <>
                        Comprehensive
                        <br /> Cover
                      </>
                    }
                    value="2"
                  />
                  <Tab
                    label={
                      <>
                        Long Term Two
                        <br /> Wheeler Cover
                      </>
                    }
                    value="3"
                  />
                  <Tab
                    label={
                      <>
                        Long term Third Party
                        <br /> Liability cover
                      </>
                    }
                    value="4"
                  />
                  <Tab
                    label={
                      <>
                        Standalone Own
                        <br /> Damage Cover
                      </>
                    }
                    value="5"
                  />
                  <Tab
                    label={
                      <>
                        Bundled
                        <br /> Plan
                      </>
                    }
                    value="6"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <p>
                  These plans, as necessitated by the Motor Vehicles Act, cover
                  only third party financial liabilities. These liabilities
                  arise if you cause injury or death to any third party in an
                  accident involving your two-wheeler. Moreover, third party
                  property damages are also covered under this plan.
                </p>
              </TabPanel>
              <TabPanel value="2">
                <p>
                  These plans provide a combination of two types of coverage
                  benefits. The first part of the plan covers third party legal
                  liabilities which arises when a third party is hurt or killed
                  or his property is damaged because of your two-wheeler. The
                  second part of the coverage is called the own damage cover.
                  This covers damages suffered by the two-wheeler itself and
                  pays for the applicable cost of repairs.
                </p>
              </TabPanel>
              <TabPanel value="3">
                <p>
                  IRDAI allows comprehensive two-wheeler insurance plans to be
                  offered for a longer tenure of 2 or 3 years. This removes the
                  hassle of annual renewals. Also, you are protected against an
                  annual increase in third party rates by IRDAI.
                </p>
              </TabPanel>
              <TabPanel value="4">
                <p>
                  These plans are available only for new bikes. They offer long
                  term coverage for 5 years against third party liabilities. If
                  you are buying a new bike, you have to get coverage against
                  third party liabilities for a continuous period of 5 years.
                  This is where this policy becomes mandatory.
                </p>
              </TabPanel>
              <TabPanel value="5">
                <p>
                  It provides protection against damages to your bike in case of
                  accidents, theft, fire or natural calamities. It can only be
                  bought if you already have an existing third party insurance
                  cover valid for at least a year.
                </p>
              </TabPanel>
              <TabPanel value="6">
                <p>
                  New Bikes need to have a mandatory third-party insurance
                  coverage for at least 5 years and hence a Bundled Insurance
                  Policy can be availed for the same. This plan offers 1-year
                  own damage cover and 5 years third party cover.
                </p>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Box>

      <Box className="features-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Box className="featurebox">
              <Box className="headerBox">
                <h2>Features Of Two Wheeler Insurance Plans</h2>
              </Box>
              <Box className="contentBox">
                <Grid container columnSpacing={3}>
                  <Grid xs={12}>
                    <p>
                      Two wheeler insurance plans have some notable features
                      that differentiate them from other insurance plans
                      available in the market. These features are as follows:
                    </p>
                    <FAQ data={twFeaturesArray} />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="addOns-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <h2>Add-ons Under Two Wheeler Insurance Plans</h2>
            <p>
              In a comprehensive bike insurance policy, there are various add-on
              covers that help increase the scope of protection offered by the
              policy. Add-on covers are additional coverage options that come at
              an additional premium. The following are the various add-ons
              available for you:
            </p>
            <FAQ data={twAddonsArray} />
          </Grid>
        </Grid>
      </Box>

      <Box className="inclusionExclusion-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Box className="inclusionbox">
              <Box className="headerBox">
                <h2>
                  Coverage Inclusions & Exclusions In Bike Insurance Plans
                </h2>
              </Box>
              <Box className="contentBox">
                <Box className="coverageTabs">
                  <TabContext value={covrageTabvalue}>
                    <Box>
                      <TabList
                        onChange={handleTabsChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Coverage Inclusions" value="1" />
                        <Tab label="Coverage Exclusions" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Grid container columnSpacing={3}>
                        <Grid xs={12} md={9}>
                          <ul className="blue-square-list mb-3">
                            <li>
                              Financial loss due to theft of your two-wheeler
                            </li>
                            <li>
                              Third party legal liability suffered in the
                              following cases:
                              <ol>
                                <li>
                                  If you cause bodily injury to any individual
                                </li>
                                <li>
                                  If an individual dies in an accident involving
                                  your two-wheeler
                                </li>
                                <li>If you damage any individual’s property</li>
                              </ol>
                            </li>
                            <li>
                              Damages to the two-wheeler due to natural
                              disasters like flood, earthquake, landslide,
                              hurricane, storm, cyclone tempest, typhoon,
                              lightning, etc.
                            </li>
                            <li>
                              Damages to the two-wheeler due to man-made
                              calamities like fire, explosion or implosion,
                              riots, malicious acts, strikes, etc.
                            </li>
                            <li>
                              Damages suffered by your two-wheeler when in
                              transit through air, land or water.
                            </li>
                            <li>
                              Personal accident coverage against death or
                              disablement while riding your two-wheeler.
                            </li>
                          </ul>
                        </Grid>
                        <Grid xs={12} md={3} textAlign={"center"}>
                          <img
                            src="./images/bike-coverage.svg"
                            alt=""
                            className="coverage-img"
                          />
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                      <Grid container columnSpacing={3}>
                        <Grid xs={12} md={9}>
                          <ul className="blue-square-list mb-3">
                            <li>
                              The expense of regular wear and tear of your
                              two-wheeler
                            </li>
                            <li>
                              Loss from mechanical or electrical breakdowns
                            </li>
                            <li>
                              The depreciation caused by everyday use of the
                              two-wheeler
                            </li>
                            <li>
                              Any damages or losses sustained by the usage of
                              the bike outside of the scope of the policy
                            </li>
                            <li>
                              Any damages sustained by the two-wheeler while
                              being ridden by a person without a valid driver’s
                              license.
                            </li>
                            <li>
                              Any damages or losses sustained by the usage of
                              the bike by a person under the influence of drugs
                              or alcohol
                            </li>
                            <li>
                              Damages sustained when driving the bike outside
                              the geographical boundaries of India.
                            </li>
                            <li>
                              Any damages due to war, mutiny or nuclear attacks.
                            </li>
                          </ul>
                        </Grid>
                        <Grid xs={12} md={3} textAlign={"center"}>
                          <img
                            src="./images/bike-coverage.svg"
                            alt=""
                            className="coverage-img"
                          />
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </TabContext>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default TWProductPage;
