import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./../../../SCSS/ProductPage.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import FAQ from "../../../Component/FAQ/FAQ";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { COLORS } from "../../../SupportingFiles/colors";
import HealthProductContainer from "../../../Container/HealthProductContainer/HealthProductContainer";

const HealthProductPage = () => {
  const isMobile = useIsMobile();
  const [value, setValue] = React.useState("1");
  const [covrageTabvalue, setCovrageTabvalue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const handleTabsChange = (
    event: React.SyntheticEvent,
    newTabsValue: string
  ) => {
    setCovrageTabvalue(newTabsValue);
  };

  const benefitsArray = [
    {
      title: "No More Agents",
      desc: `Always read the fine print of the policy you choose. Check out the inclusions and exclusions so that you know the scope of the policy. This will ensure that you are not caught off-guard when you suffer a claim.`,
    },
    {
      title: "Zero Paperwork",
      desc: `Though the third party coverage fulfils the legal obligations, it does not cover the damages that your car might suffer. Such damages can also incur considerable financial losses and so, it is best to cover them as well. So, opt for comprehensive plans for a wider scope of coverage.`,
    },
    {
      title: "Convenient & Saves Time",
      desc: `This, again, is a common practice that you should follow. Since comparison helps you find the best policy, always compare the available plans online and then invest in the best policy.`,
    },
    {
      title: "Easy Comparison",
      desc: `Choosing the right insurance company is as vital as choosing the right insurance policy. You must always check the incurred claim ratio of the insurance company you choose, which is the amount of money paid out as claims against the total money collected as a premium. Ideally, the ICR should not be too high, which would mean that the company doesn't have enough surplus to pay claims and neither should it be too low, which would mean that the company is overcharging the premium.`,
    },
    {
      title: "Easy customisation",
      desc: `Add-ons enhance the scope of coverage of the policy and can prove to be a great value addition, especially at the time of claims. So, choose suitable add-ons, depending on your needs, to make your coverage all-inclusive`,
    },
    {
      title: "Cost-Effective",
      desc: `If you want a cashless claim settlement, which is easier, always check out the network of garages the insurance company is tied up with. The information is easily available on the insurer’s website and you should choose an insurer with the largest network so that locating a preferred garage becomes easier.`,
    },
    {
      title: "Instant policy issuance",
      desc: `Comprehensive plans allow a host of premium discounts, besides the no claim discount. Look for the available discounts and try and claim as many as you can. This would reduce your car insurance premiums and make the policy more affordable.`,
    },
  ];

  const twAddonsArray = [
    {
      title: "Roadside assistance",
      desc: `If your two-wheeler breaks down in the middle of a remote place and you cannot take it to the nearest garage, this add-on proves handy. The add-on provides round-the-clock assistance for flat tyres, battery jump-start, fuel assistance, etc.`,
    },
    {
      title: "Zero depreciation cover",
      desc: `At the time of claims, the depreciation of the parts repaired or replaced is deducted from the claim amount. This reduces the admissible claim amount and increases your out-of-pocket expenses. However, through this add-on, the effect of depreciation becomes nil and you get full settlement of the claim`,
    },
    {
      title: "NCB Protect",
      desc: `The no claim bonus that you earn becomes zero if you make any claim in a policy year. This add-on protects the no claim bonus and allows you to claim the relevant discount on renewal even when you suffer a claim`,
    },
    {
      title: "Return to invoice",
      desc: `Under this add-on the insurance company pays the invoice value of the bike if it is damaged beyond repairs or if it is stolen`,
    },
    {
      title: "Lost key replacement",
      desc: `If you lose your two-wheeler keys, the add-on would cover the cost of replacing them`,
    },
    {
      title: "Engine protection	",
      desc: `This add-on covers the possible damages to the engine due to water logging or water seepage`,
    },
    {
      title: "Personal accident cover for pillion rider",
      desc: `Under this add-on you can avail of an additional personal accident cover for the pillion rider`,
    },
  ];

  const twFeaturesArray = [
    {
      title: "Comprehensive and Liability Only Coverage",
      desc: `If you have a two-wheeler, you have the option to choose a comprehensive or third-party insurance cover.`,
    },
    {
      title: "Coverage tenure",
      desc: `Two wheeler insurance plans are available as annual plans or as long term insurance plans. You can buy either of these policies and get covered. However, for new bikes, mandatory 5-year third party coverage is a must. You can opt for this mandatory cover and then an annual standalone policy or a bundled policy which offers 5 year third party coverage and 1 year own damage cover.`,
    },
    {
      title: "Optional Coverage",
      desc: `You can choose from a long list of optional covers in your two wheeler insurance policy. These optional coverage benefits are called add-ons and they are available at an additional premium under comprehensive two-wheeler insurance plans.`,
    },
    {
      title: "Easy Transfer of No Claim Bonus (NCB)",
      desc: `If you do not make claims in your policy in a year, you receive a no claim bonus which allows a premium discount on renewals. Your no claim bonus can be easily transferred to another insurer if you port your policy. Moreover, if you sell your bike and buy a new one, you can transfer the accumulated no claim bonus to the new bike insurance policy as well.`,
    },
    {
      title: "Quick Issuance for Online Purchase",
      desc: `Two wheeler insurance plans are available online. If you buy bike insurance online, the policy would be issued instantly after you provide the information of your bike, finalize the coverage, and pay the premium.`,
    },
    {
      title: "Personal Accident Cover of Rs. 15 Lakh",
      desc: `The IRDAI has made it mandatory for you to have a personal accident cover that covers accidental deaths and disablements. You can buy a cover of Rs.15 lakhs at an affordable premium with your two wheeler insurance policy. However, if you already have a personal accident cover in your name, you don’t have to buy another one with your two wheeler insurance plan.`,
    },
    {
      title: "Discounts",
      desc: `The IRDAI approves of several discounts on the purchase of a two wheeler insurance policy on selected grounds. For example, if you are a member of an automobile association, have an anti-theft device installed, etc., can get you some great discounts. If you have a previous NCB too, you can get a discount on the renewal premium. Compare bike insurance policy online to look for the maximum discounts.`,
    },
  ];
  return (
    <Box className="productPage-layout">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="header-Form-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} textAlign={"center"} marginBottom={"32px"}>
            <h2 className="mb-4">
              Clarify All Doubts Related To Individual/Family Health Insurance
            </h2>
            <p>
              A health insurance policy is an agreement between an insurance
              company and a policyholder. In this agreement, the policy provider
              offers financial assistance for medical expenses to the
              policyholder in return for a premium. The policyholder can get
              reimbursement for medical treatments, hospital bills, surgical
              bills, etc. or choose the cashless claim feature. The cashless
              claim feature allows the policyholder to avail treatment on a
              network hospital without worrying about the payments. The
              insurance company and the network hospital have an association
              through which they settle the bills of the policyholders.
            </p>
          </Grid>
          <Grid xs={12} textAlign={"center"}>
            <Box className="formBox pt-4" position="relative">
              <h3 className="my-2 px-10">Health Insurance</h3>
              <p className="subheading px-10">
                Search, Compare & Apply for customised policies free of cost.
              </p>
              <hr />
              <HealthProductContainer />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="benefits-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Box className="benfitsbox">
              <Box className="headerBox">
                <h2>Benefits of Buying Health Insurance Policy</h2>
              </Box>
              <Box className="contentBox">
                <Grid container columnSpacing={3}>
                  <Grid xs={12} md={9}>
                    <p>
                      When the medical bills pile up, your savings drain quickly
                      out of your pocket. A regular health check-up may also
                      cost you more than you can afford. This doesn’t mean that
                      you cannot have access to the best treatments available
                      for you and your family. A health insurance policy comes
                      to your aid in such a scenario. It covers your medical
                      expenses so that you can lead a long and healthy life.
                      Here are some key benefits you should look out for while
                      buying online health insurance:
                    </p>
                  </Grid>
                  <Grid xs={12} md={3} textAlign={"center"}>
                    <img
                      src="./images/health-product-img.svg"
                      alt=""
                      className="healthProduct-img"
                    />
                  </Grid>
                  <Grid xs={12} className="mt-6">
                    <ul className="healthBenefits-list">
                      <li>
                        <img src="./images/health_benefits_icon1.svg" alt="" />
                        <p>
                          Annual Health
                          <br /> Checkup
                        </p>
                      </li>
                      <li>
                        <img src="./images/health_benefits_icon2.svg" alt="" />
                        <p>
                          Robotic
                          <br /> Surgery
                        </p>
                      </li>
                      <li>
                        <img src="./images/health_benefits_icon3.svg" alt="" />
                        <p>
                          Ambulance
                          <br /> Emergency
                        </p>
                      </li>
                      <li>
                        <img src="./images/health_benefits_icon4.svg" alt="" />
                        <p>
                          Hospitalization
                          <br /> More or Less than 24 hrs
                        </p>
                      </li>
                      <li>
                        <img src="./images/health_benefits_icon5.svg" alt="" />
                        <p>
                          Post-Hospitalization
                          <br /> Expenses
                        </p>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="types-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <h2 className="mb-4">Types Of Health Insurance Plans</h2>
            <p className="mb-4">There are 2 types of health insurance plans:</p>
          </Grid>
          <Grid xs={12} className="types-tabs">
            <TabContext value={value}>
              <Box>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label={"Indeminity"} value="1" />
                  <Tab label={"Fixed Benefit"} value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <p className="mb-4">
                  An indemnity health insurance policy would pay for the
                  hospitalisation expenses that are incurred when treatment is
                  taken. In simple words, it is the actual hospitalisation
                  expense that is covered up to your plan’s sum insured limit.
                  This kind of health insurance is the most common and is opted
                  for a basic or comprehensive health plan for families,
                  individuals and senior citizens.
                </p>
                <p>
                  Such plans cover in-patient treatment expenses, doctor’s fee,
                  ICU charges, surgery, room rent, medicines, pre and
                  post-hospitalisation expenses etc. In addition, most health
                  insurance companies offer cashless treatment under indemnity
                  based health insurance plans.
                </p>
              </TabPanel>
              <TabPanel value="2">
                <p>
                  In a fixed benefit health plan, a fixed sum is paid as a cover
                  for certain predetermined illnesses or conditions. The pay-out
                  is done in the form of a lump sum, irrespective of the actual
                  medical expenses. Such policies are considered helpful if you
                  are looking for accidental covers or protection against
                  specific critical illnesses.
                </p>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Box>

      <Box className="inclusionExclusion-section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Box className="inclusionbox">
              <Box className="headerBox">
                <h2>
                  Coverage Inclusions & Exclusions In Health Insurance Plans
                </h2>
              </Box>
              <Box className="contentBox">
                <Box className="coverageTabs">
                  <TabContext value={covrageTabvalue}>
                    <Box>
                      <TabList
                        onChange={handleTabsChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Coverage Inclusions" value="1" />
                        <Tab label="Coverage Exclusions" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Grid container columnSpacing={3}>
                        <Grid xs={12}>
                          <ul className="blue-square-list mb-3">
                            <li>In-Patient Hospitalization expenses</li>
                            <li>
                              In the case of organ transplant, donor expenses up
                              to a certain amount
                            </li>
                            <li>
                              Overnight hospitalizations in cases of serious
                              injuries
                            </li>
                            <li>
                              Pre-existing diseases after a set incubation
                              period (Usually 2-4 years)
                            </li>
                            <li>
                              Pre and post hospitalization expenses for the same
                              illness and for a specified number of days
                            </li>
                            <li>
                              Ambulance charge up to a certain amount, and for a
                              specific number of hospitalisations
                            </li>
                            <li>Room rent up to a sub-limit</li>
                            <li>Free health check-ups </li>
                            <li>
                              Maternity and newborn expenses are not covered
                              unless opted as an add-on
                            </li>
                            <li>Most daycare treatments are covered</li>
                            <li>
                              Doctor-prescribed home treatment or domiciliary
                              hospitalization
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                      <Grid container columnSpacing={3}>
                        <Grid xs={12}>
                          <ul className="blue-square-list mb-3">
                            <li>
                              No coverage for expenses incurred during the
                              waiting period (30 days) unless it is an
                              accidental emergency{" "}
                            </li>
                            <li>
                              Pre-existing diseases are not covered in the
                              incubation period. (Usually 2-4 years){" "}
                            </li>
                            <li>
                              Illness and injuries caused by war or nuclear
                              activities
                            </li>
                            <li>Suicide attempt or self-inflicted injuries </li>
                            <li>Cosmetic/Obesity/Aesthetic surgeries </li>
                            <li>
                              Replacement of hormones or sex-change operations{" "}
                            </li>
                            <li>
                              Dental or Eye Surgeries, unless covered under the
                              plan{" "}
                            </li>
                            <li>
                              Non-allopathic and experimental line of treatment{" "}
                            </li>
                            <li>
                              Certain treatments or diagnostic tests, listed in
                              the policy document{" "}
                            </li>
                            <li>
                              Treatment abroad or beyond geographic limitations
                              as specified in the policy{" "}
                            </li>
                            <li>
                              Doctor-prescribed home treatment or domiciliary
                              hospitalization{" "}
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </TabContext>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="health-clarifypoints-sec">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} marginBottom={"16px"} textAlign={"center"}>
            <h2>
              Clarify All Doubts Related To Multi Individual Health Insurance
            </h2>
          </Grid>
          <Grid xs={12} md={8}>
            <ul className="blue-square-list">
              <li>
                In case of multiple policies taken by an Insured Person during a
                period from one or more Insurers to indemnify treatment costs,
                the Insured Person shall have the right to require a settlement
                of his/her claim in terms of any of his/her policies.
              </li>
              <li>
                In all such cases the Insurer chosen by the Insured Person shall
                be obliged to settle the claim as long as the claim is within
                the limits of and according to the terms of the chosen policy.
              </li>
              <li>
                Insured Person having multiple policies shall also have the
                right to prefer claims under this policy for the amounts
                disallowed under any other policy/policies, even if the sum
                insured is not exhausted. Then the Insurer shall independently
                settle the claim subject to the terms and condition of this
                policy.
              </li>
              <li>
                If the amount to be claimed exceeds the sum insured under a
                single policy, the Insured Person shall have the right to choose
                Insurer from whom he/she wants to claim the balance amount.
              </li>
              <li>
                Where an Insured Person has policies from more than one Insurer
                to cover the same risk on indemnity basis, the Insured Person
                shall only be indemnified the treatment costs in accordance with
                the terms and conditions of the chosen policy.
              </li>
              <li>
                In case no claim is made in a particular policy year, no claim
                bonus would be credited automatically to the subsequent policy
                year even in case of multi year policies (with 2 or 3 year
                policy tenure)
              </li>
            </ul>
          </Grid>
          <Grid xs={12} md={4} textAlign={"center"}>
            <img
              src="./images/health-clarify-img.svg"
              alt=""
              className="clarifyHealth-img"
            />
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default HealthProductPage;
