import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./../../../SCSS/CMSPages.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import TextArea from "../../../Component/InputFields/TextArea/TextArea";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";

const Complaint = () => {
  const isMobile = useIsMobile();
  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    interested: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    message: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    interested: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    message: { value: "", warn_status: false },
  });

  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };
  return (
    <Box className="cmsPage-layout">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="formcms-layout fl_complaint">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} md={6} className="mb-margin2">
            <Box className="formBox">
              <h2 className="mb-8">Complaint</h2>
              <RKTextField
                class_name="inputField mb-6"
                title={"Name"}
                value={formFields.name.value}
                attrName={"name"}
                value_update={updateFieldsState}
                warn_status={formFields.name.warn_status}
              />
              <RKTextField
                class_name="inputField mb-6"
                title={"Email Id"}
                value={formFields.email.value}
                attrName={"email"}
                value_update={updateFieldsState}
                warn_status={formFields.email.warn_status}
              />
              <RKTextField
                class_name="inputField mb-6"
                title={"Mobile"}
                value={formFields.mobile.value}
                attrName={"mobile"}
                value_update={updateFieldsState}
                warn_status={formFields.mobile.warn_status}
              />
              <TextArea
                class_name="inputField mb-6"
                title={"Write Your Issue here"}
                value={formFields.message.value}
                attrName={"message"}
                value_update={updateFieldsState}
                warn_status={formFields.message.warn_status}
                placeholder="Write here..."
              />
              <CustomButton
                className="primaryBtn large"
                text={
                  <>
                    <PhoneIcon
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "8px",
                      }}
                    />
                    Call Me Back{" "}
                  </>
                }
                fullWidth={true}
                variant="contained"
              />
            </Box>
          </Grid>
          <Grid xs={12} md={6} className="right_outer ro_complaint">
            <Box className="rightbox">
              <h2>
                Don't hesitate to <span>Contact us</span>
              </h2>
              <p>We don't mind.</p>
              <ul className="contactDetails_list">
                <li>
                  <a href="mailto:info@walnnut.com" target="_blank">
                    <EmailIcon />
                    info@walnnut.com
                  </a>
                </li>
                <li>
                  <a href="tel:022 6781 7835" target="_blank">
                    <PhoneIcon />
                    022 6781 7835
                  </a>
                </li>
                <li>
                  <p>
                    <WatchLaterOutlinedIcon />
                    Working hours (10:00 AM - 06: 30 PM)
                  </p>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default Complaint;
