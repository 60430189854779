import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { subDays, subYears } from "date-fns";
import React from "react";
import CustomButton from "../../../../Component/InputFields/CustomButton/CustomButton";
import CustomCheckbox from "../../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../../Component/InputFields/DatePicker/DatePicker";
import RKTextField from "../../../../Component/InputFields/RKTextField/RKTextField";
import SelectDropdown from "../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import Popup from "../../../../Component/Popup/Popup";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import {
  FORMAT_DATE_DD_MM_YYYY,
  calculateAgeInDays,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../../SupportingFiles/colors";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../Store/Store/hooks";
import { TDropdown } from "../../../../Type/Common/TDropdown";
import { THealthAddForm } from "../../../../Type/Health/THealthSlice/THealthSlice";

interface Props {
  open: boolean;
  setOpen: Function;
  validate_form: Function;
  updateMasterState: Function;
  healthFormSteps: any;
  ADD_FORM_DETAILS: Function;
  setHealthFormSteps: Function;
  formData: THealthAddForm;
  relationship_data: TDropdown[];
}

const HealthForm: React.FC<Props> = ({
  open,
  setOpen,
  validate_form,
  updateMasterState,
  healthFormSteps,
  setHealthFormSteps,
  formData,
  relationship_data,
}) => {
  const navigate = useNavigate();
  const { GENDER } = useAppSelector((state) => state.CommonDropdownData);
  return (
    <Popup
      open={open}
      handleClose={() => {
        setOpen(false);
      }}
      variant="medium"
      title={healthFormSteps === "1" ? "Whom to insure?" : "Personal Details"}
      content={
        <Box padding="24px">
          {healthFormSteps === "2" && (
            <Link
              sx={{
                position: "absolute",
                top: "20px",
                left: "12px",
                color: COLORS.black,
                display: "inline-block",
              }}
              onClick={() => setHealthFormSteps("1")}>
              <ChevronLeftRoundedIcon sx={{ fontSize: "30px" }} />
            </Link>
          )}

          {healthFormSteps === "1" ? (
            <Box>
              <Grid container spacing={2} justifyContent={"center"}>
                <Grid xs={12}>
                  <h6
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      marginBottom: "8px",
                    }}>
                    Select the family members you want to insure
                  </h6>
                </Grid>
                <Grid xs={4}>
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "10px 12px 10px 8px",
                      marginBottom: "12px",
                      position: "relative",
                    }}
                    className="self-selectField">
                    <CustomCheckbox
                      label="One Adult"
                      attrName={"self_status"}
                      value={formData?.self_status}
                      isChecked={
                        !formData.spouse_status && formData.self_status
                      }
                      value_update={(newValue: any) => {
                        if (newValue) {
                          updateMasterState("spouse_status", false);
                        }
                        updateMasterState("self_status", newValue);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid xs={4}>
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "10px 12px 10px 8px",
                      marginBottom: "12px",
                    }}>
                    <CustomCheckbox
                      label="Two Adults"
                      attrName={"spouse_status"}
                      value={formData?.spouse_status}
                      isChecked={formData.spouse_status}
                      value_update={() =>
                        updateMasterState(
                          "spouse_status",
                          !formData.spouse_status
                        )
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent={"center"}>
                <Grid xs={4}>
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "10px 12px 10px 8px",
                      marginBottom: "12px",
                      position: "relative",
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <CustomCheckbox
                      label="Son"
                      attrName={"son_status"}
                      value={formData?.son_status}
                      value_update={updateMasterState}
                      defaultChecked={formData?.son_status}
                      isChecked={formData?.son_status}
                    />
                    {formData.son_status && (
                      <Box
                        position="absolute"
                        right="8px"
                        top="12px"
                        display="flex"
                        alignItems="center"
                        gap="8px"
                        zIndex="9">
                        <Box
                          onClick={() => updateMasterState("son_subtract", "")}
                          height="16px"
                          width="16px"
                          color={COLORS.primary}
                          textAlign="center"
                          sx={{ cursor: "pointer" }}>
                          <RemoveRoundedIcon
                            sx={{ width: "16px", height: "16px" }}
                          />
                        </Box>
                        <Box
                          height="16px"
                          width="16px"
                          color={COLORS.primary}
                          borderRadius="40px"
                          textAlign="center"
                          sx={{
                            backgroundColor: "rgba(255, 243, 244, 1)",
                            fontSize: "12px",
                          }}>
                          {formData.son_count.value}
                        </Box>
                        <Box
                          onClick={() => updateMasterState("son_add", "")}
                          height="16px"
                          width="16px"
                          color={COLORS.primary}
                          textAlign="center"
                          sx={{ cursor: "pointer" }}>
                          <AddRoundedIcon
                            sx={{ width: "16px", height: "16px" }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid xs={4}>
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "10px 12px 10px 8px",
                      marginBottom: "12px",
                      position: "relative",
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <CustomCheckbox
                      label="Daughter"
                      attrName={"daughter_status"}
                      value={formData?.daughter_status}
                      value_update={updateMasterState}
                      isChecked={formData?.daughter_status}
                      defaultChecked={formData?.daughter_status}
                    />
                    {formData.daughter_status && (
                      <Box
                        position="absolute"
                        right="8px"
                        top="12px"
                        display="flex"
                        alignItems="center"
                        gap="8px"
                        zIndex="9">
                        <Box
                          onClick={() =>
                            updateMasterState("daughter_subtract", "")
                          }
                          height="16px"
                          width="16px"
                          color={COLORS.primary}
                          textAlign="center"
                          sx={{ cursor: "pointer" }}>
                          <RemoveRoundedIcon
                            sx={{ width: "16px", height: "16px" }}
                          />
                        </Box>
                        <Box
                          height="16px"
                          width="16px"
                          color={COLORS.primary}
                          borderRadius="40px"
                          textAlign="center"
                          sx={{
                            backgroundColor: "rgba(255, 243, 244, 1)",
                            fontSize: "12px",
                          }}>
                          {formData.daughter_count.value}
                        </Box>
                        <Box
                          onClick={() => updateMasterState("daughter_add", "")}
                          height="16px"
                          width="16px"
                          color={COLORS.primary}
                          textAlign="center"
                          sx={{ cursor: "pointer" }}>
                          <AddRoundedIcon
                            sx={{ width: "16px", height: "16px" }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                marginBottom="20px">
                <Grid xs={12} marginTop="12px" textAlign={"center"}>
                  <h6 style={{ fontWeight: "500" }}>
                    Select age of your family members
                  </h6>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                marginBottom="8px">
                <Grid xs={4}>
                  <DatePicker
                    class_name="inputField"
                    title={"1st Adult DOB"}
                    value={formData.self_dob.value}
                    attrName={"self_dob"}
                    value_update={updateMasterState}
                    warn_status={formData.self_dob.warning}
                    error_message={"Select 1st Adult DOB"}
                    min_date={110}
                    max_date={18}
                    date_validation_type="YEARS"
                    default_date={subYears(new Date(), 18)}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title={"Gender"}
                    attrName={"gender"}
                    value={formData.gender.value}
                    value_update={updateMasterState}
                    data={GENDER}
                    warn_status={formData.gender.warning}
                    error_message={"Select Gender"}
                  />
                </Grid>
              </Grid>
              {formData.spouse_status && (
                <>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    marginBottom="8px">
                    <Grid xs={4}>
                      <DatePicker
                        class_name="inputField"
                        title={"2nd Adult DOB"}
                        value={formData.spouse_dob.value}
                        attrName={"spouse_dob"}
                        value_update={updateMasterState}
                        warn_status={formData.spouse_dob.warning}
                        error_message={"Select 2nd Adult DOB"}
                        min_date={110}
                        max_date={18}
                        date_validation_type="YEARS"
                        default_date={subYears(new Date(), 18)}
                      />
                    </Grid>
                    <Grid xs={4}>
                      <SelectDropdown
                        class_name="inputField"
                        title={"Relationship with 1st Adult"}
                        attrName={"spouse_relationship"}
                        value={formData?.spouse_relationship?.value}
                        value_update={updateMasterState}
                        data={relationship_data}
                        warn_status={formData.spouse_relationship.warning}
                        error_message={"Select Relationship with 1st Adult"}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                maxWidth={"512px"}
                marginLeft={"auto"}
                marginRight={"auto"}
                marginBottom="20px">
                {(formData.son_status
                  ? parseInt(formData.son_count.value)
                  : 0) +
                  (formData.daughter_status
                    ? parseInt(formData.daughter_count.value)
                    : 0) >=
                  1 && (
                  <Grid xs={6}>
                    <DatePicker
                      class_name="inputField"
                      title={"Child 1 Date of birth (DOB)"}
                      value={formData?.child_one_dob?.value}
                      attrName={"child_one_dob"}
                      value_update={updateMasterState}
                      warn_status={formData?.child_one_dob?.warning}
                      error_message={"Select 1st Child DOB"}
                      min_date={calculateAgeInDays(
                        FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 25)}`)
                      )}
                      max_date={91}
                      date_validation_type="DAYS"
                      default_date={subDays(new Date(), 91)}
                    />
                  </Grid>
                )}
                {(formData.son_status
                  ? parseInt(formData.son_count.value)
                  : 0) +
                  (formData.daughter_status
                    ? parseInt(formData.daughter_count.value)
                    : 0) >=
                  2 && (
                  <Grid xs={6}>
                    <DatePicker
                      class_name="inputField"
                      title={"Child 2 Date of birth (DOB)"}
                      value={formData?.child_two_dob?.value}
                      attrName={"child_two_dob"}
                      value_update={updateMasterState}
                      warn_status={formData?.child_two_dob?.warning}
                      error_message={"Select 2nd Child DOB"}
                      min_date={calculateAgeInDays(
                        FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 25)}`)
                      )}
                      max_date={91}
                      date_validation_type="DAYS"
                      default_date={subDays(new Date(), 91)}
                    />
                  </Grid>
                )}
                {(formData.son_status
                  ? parseInt(formData.son_count.value)
                  : 0) +
                  (formData.daughter_status
                    ? parseInt(formData.daughter_count.value)
                    : 0) >=
                  3 && (
                  <Grid xs={6}>
                    <DatePicker
                      class_name="inputField"
                      title={"Child 3 Date of birth (DOB)"}
                      value={formData?.child_three_dob?.value}
                      attrName={"child_three_dob"}
                      value_update={updateMasterState}
                      warn_status={formData?.child_three_dob?.warning}
                      error_message={"Select 3rd Child DOB"}
                      min_date={calculateAgeInDays(
                        FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 25)}`)
                      )}
                      max_date={91}
                      date_validation_type="DAYS"
                      default_date={subDays(new Date(), 91)}
                    />
                  </Grid>
                )}
                {(formData.son_status
                  ? parseInt(formData.son_count.value)
                  : 0) +
                  (formData.daughter_status
                    ? parseInt(formData.daughter_count.value)
                    : 0) >=
                  4 && (
                  <Grid xs={6}>
                    <DatePicker
                      class_name="inputField"
                      title={"Child 4 Date of birth (DOB)"}
                      value={formData?.child_four_dob?.value}
                      attrName={"child_four_dob"}
                      value_update={updateMasterState}
                      warn_status={formData?.child_four_dob?.warning}
                      error_message={"Select 4th Child DOB"}
                      min_date={calculateAgeInDays(
                        FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 25)}`)
                      )}
                      max_date={91}
                      date_validation_type="DAYS"
                      default_date={subDays(new Date(), 91)}
                    />
                  </Grid>
                )}
                <Grid xs={12} textAlign={"center"}>
                  <CustomButton
                    className="primaryBtn mb-2"
                    text="Continue"
                    onClick={() => validate_form()}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
              </Grid>
            </Box>
          ) : healthFormSteps === "2" ? (
            <Box>
              <Grid
                container
                spacing={2}
                justifyContent={"center"}
                marginBottom={"30px"}>
                <Grid xs={12}>
                  <h6
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      marginBottom: "8px",
                    }}>
                    Fill Personal Details
                  </h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Enter mobile number"}
                    value={formData.mobile.value}
                    attrName={"mobile"}
                    max_length={10}
                    validation_type="NUMBER"
                    value_update={updateMasterState}
                    warn_status={formData.mobile.warning}
                    error_message={
                      isEmpty(formData.mobile.value)
                        ? "Enter Mobile Number"
                        : "Enter Valid Mobile Number"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Enter your pincode"}
                    value={formData.pincode.value}
                    attrName={"pincode"}
                    value_update={updateMasterState}
                    warn_status={formData.pincode.warning}
                    max_length={6}
                    validation_type="NUMBER"
                    error_message={
                      isEmpty(formData.pincode.value)
                        ? "Enter Pincode"
                        : "Enter Valid Pincode"
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent={"center"}>
                <Grid xs={12}>
                  <CustomButton
                    className="primaryBtn mb-2"
                    text="View Quotes"
                    onClick={() => {
                      validate_form();
                    }}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid xs={12} className="popup-Checkbox">
                  <CustomCheckbox
                    // label="By clicking on view quotes you agree to all Terms & Conditions."
                    label={
                      <>
                        By clicking on view quotes you agree to all {""}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(COMMON_ROUTES.TERM_AND_CONDTION)
                          }>
                          Terms & Conditions
                        </span>
                      </>
                    }
                    checked={true}
                    attrName={"termCondition"}
                    defaultChecked
                    value={true}
                    disabled={true}
                    // value={termCondition}
                    // value_update={handleCheckbox}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </Box>
      }
    />
  );
};

export default HealthForm;
