import { PayloadAction } from "@reduxjs/toolkit";
import { TDropdown } from "../../../Type/Common/TDropdown";
import { TTWForm, TTWSlice, TWDropdown } from "../../../Type/TW/TTwSlice";

function BULK_UPLOAD(state: TTWSlice, action: PayloadAction<TTWSlice>) {
  const data: TTWSlice = { ...state, ...action.payload };

  return data;
}

function ADD_FORM_DATA(state: TTWSlice, action: PayloadAction<TTWForm>) {
  let data: TTWSlice = { ...state };

  data = { ...data, ADD_FORM: { ...data.ADD_FORM, ...action.payload } };

  return data;
}
type keyTTWForm = keyof TTWForm;
function UPDATE_FORM_DATA(
  state: TTWSlice,
  action: PayloadAction<{ key: keyTTWForm; value: any }[]>
): TTWSlice {
  const updates = action.payload;

  // Use object destructuring to create a shallow copy of state
  let data: TTWSlice = { ...state };

  const new_data = Object.fromEntries(
    updates.map(({ key, value }) => [key, value])
  );

  data = { ...data, ADD_FORM: { ...data.ADD_FORM, ...new_data } };

  return data;
}

type KEY_DROPDOWN_MASTER = keyof TWDropdown;
const UPDATE_DROPDOWN_MASTER = (
  state: TTWSlice,
  action: PayloadAction<{ key: KEY_DROPDOWN_MASTER; value: TDropdown[] }>
) => {
  const { key, value } = action.payload;
  const data = {
    ...state,
    DROPDOWN_DATA: {
      ...state.DROPDOWN_DATA,
      [key]: value,
    },
  };

  return data;
};
export const TW_REDUCERS = {
  BULK_UPLOAD,
  ADD_FORM_DATA,
  UPDATE_DROPDOWN_MASTER,
  UPDATE_FORM_DATA,
};

export type TWReducers = typeof TW_REDUCERS;
