import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import Popup from "../../../../Popup/Popup";
import { COLORS } from "../../../../../SupportingFiles/colors";
import CustomCheckbox from "../../../../InputFields/CustomCheckbox/CustomCheckbox";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import RKTextField from "../../../../InputFields/RKTextField/RKTextField";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";

interface Props {
  open: boolean;
  setOpen: any;
  showEnterMobileSection: boolean;
  setShowEnterMobileSection: any;
  loginFields: any;
  setLoginFields: any;
  fieldsUpdateState: Function;
  handleShowMessage: () => void;
  showMessage: Function;
  resendOtp: Function;
}

const LoginPopup: React.FC<Props> = ({
  open,
  setOpen,
  showEnterMobileSection,
  setShowEnterMobileSection,
  loginFields,
  setLoginFields,
  fieldsUpdateState,
  handleShowMessage,
  showMessage,
  resendOtp,
}) => {
  // const [loginSteps, setLoginSteps] = useState<"1" | "2">("1");

  // const [formData, setFormData] = useState<{
  //   otp: { value: string; warning: boolean };
  //   mobile: { value: string; warning: boolean };
  // }>({
  //   otp: { value: "", warning: false },
  //   mobile: { value: "", warning: false },
  // });

  // const updateMasterState = (attrName: string, value: any) => {
  //   setFormData((prev) => ({ ...prev, [attrName]: { value: value } }));
  // };

  // const handleSendMobileNo = () => {
  //   handleShowMessage();
  //   // setLoginSteps("2");
  //   setShowEnterMobileSection(false);
  // };

  // const handleSendeOTP = () => {
  //   handleShowMessage();
  // };

  return (
    <Popup
      open={open}
      handleClose={() => {
        setShowEnterMobileSection(true);
        setOpen(false);
        setLoginFields({
          enterMobile: { value: "", warning: false },
          enterOtp: { value: "", warning: false },
        });
      }}
      variant="medium"
      title={showEnterMobileSection ? "Customer Login" : "One Time Password"}
      content={
        <Box padding="24px">
          {showEnterMobileSection ? (
            <Box>
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent={"center"}
              >
                <Grid item xs={12} marginBottom={""}>
                  <img src="./images/login_popup_img1.svg" alt="" />
                </Grid>
                <Grid item xs={12}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      marginBottom: "8px",
                    }}
                  >
                    Hey, Enter your details to get sign in to your account
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Enter Mobile Number"}
                    value={loginFields.enterMobile.value}
                    attrName={[
                      "enterMobile",
                      "value",
                      loginFields,
                      setLoginFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={loginFields.enterMobile.warning}
                    error_message={
                      isEmpty(loginFields.enterMobile.value)
                        ? "Enter Mobile Number"
                        : "Enter Valid Mobile Number"
                    }
                    max_length={10}
                    validation_type="NUMBER"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <CustomButton
                    className="primaryBtn"
                    text="Send OTP"
                    onClick={handleShowMessage}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box>
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent={"center"}
              >
                <Grid item xs={12} marginBottom={""}>
                  <img src="./images/login_popup_img2.svg" alt="" />
                </Grid>
                <Grid item xs={12}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      marginBottom: "8px",
                    }}
                  >
                    OTP sent successfully on{" "}
                    <span style={{ fontWeight: 600, fontSize: "16px" }}>
                      {loginFields.enterMobile.value}
                    </span>
                    <Link
                      sx={{
                        background: "rgba(242, 242, 242, 1)",
                        fontSize: "12px",
                        padding: "2px 10px",
                        borderRadius: "40px",
                        color: "rgba(51, 51, 51, 1)",
                        fontWeight: 500,
                        marginLeft: "12px",
                        display: "inline-block",
                        lineHeight: "18px",
                      }}
                      onClick={() => setShowEnterMobileSection(true)}
                    >
                      Edit
                    </Link>
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Enter OTP"}
                    value={loginFields.enterOtp.value}
                    attrName={[
                      "enterOtp",
                      "value",
                      loginFields,
                      setLoginFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={loginFields.enterOtp.warning}
                    error_message={
                      isEmpty(loginFields.enterOtp.value)
                        ? "Enter OTP"
                        : "Enter Valid OTP"
                    }
                    max_length={4}
                    validation_type="NUMBER"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <CustomButton
                    className="primaryBtn"
                    text="Verify OTP"
                    onClick={() => {
                      setLoginFields({
                        ...loginFields,
                        enterOtp: { value: "", warning: false },
                      });
                      showMessage();
                    }}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid item xs={12}>
                  <p>
                    Didn’t receive?{" "}
                    <span onClick={() => resendOtp()}>
                      <Link sx={{ color: COLORS.primary, fontWeight: 600 }}>
                        Resend OTP
                      </Link>
                    </span>
                  </p>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      }
    />
  );
};

export default LoginPopup;
