import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./../../../SCSS/CMSPages.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";

const PrivacyPolicy = () => {
  const isMobile = useIsMobile();
  return (
    <Box className="cmsPage-layout">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="contentPages-layout mb-margin1">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h2 className="mb-2">Privacy Policy</h2>
            <h4 className="mb-4">What is the Purpose of this Document?</h4>
            <p>
              Walnnut.com the electronic commerce platform digital arm of
              J.B.BODA Insurance & Reinsurance Brokers Pvt Ltd(hereinafter
              referred to as “J.B.Boda” or “We” or “Us” or “Our”) is committed
              to nurturing and growing clients through a great buying
              experience, delivered through technological interventions.
              TheJ.B.Boda is commited to safeguarding your personal
              information's privacy and confidentiality. “Personal Information”
              refers to information that may be used to directly identify or
              reasonably be used to identify an individual user of this Website,
              located at www.walnnut.com (“Site”). This Website Privacy Notice
              (this “Privacy Notice”) was created to inform you of our Website
              privacy policies. We also urge that you read the Terms of Service,
              which govern your use of this Site and its content.
            </p>
            <p>
              The J.B.Boda will regard any personally identifiable information
              gathered on this Site as Personal Information and will treat it in
              line with this Privacy Notice. You agree and consent to the
              processing, transfer, usage, and storage of your Personal
              Information in accordance with this Privacy Notice by using this
              Site and submitting your Personal Information.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <Box className="formBox">
              <h3 className="mb-2">
                Our Policies and Practices to Protect Your Personal Information
              </h3>
              <p className="mb-2">
                The J.B.Boda is committed to safeguard the privacy of visitors
                to this website. The J.B.Boda Group's will always use and
                disclose your Personal Information in a way that is compliant
                with all relevant laws and regulations, contractual
                responsibilities, and our company's policies and processes. We
                maintain physical, technological, and administrative procedures
                to secure the Personal Information we collect about you that
                meet or exceed relevant requirements.
              </p>
              <p>
                Electronic storage and transmission of Personal Information are
                protected using appropriate security measures to protect your
                Personal Information. Employees, agents, and contractors of The
                J.B.Boda and its subsidiaries have access to this Site's
                servers, and they are all obligated to maintain the
                confidentiality of Personal Information they receive while doing
                their duties and running The J.B.Boda Group's business.
              </p>
            </Box>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">Information We Collect</h3>
            <p>
              When you visit the Site or use our Services, the J.B.Boda gathers
              two categories of information about you: personal information that
              you supply and technical, aggregate, and non-personal information
              obtained by automatic means.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">Personal Information You Provide</h3>
            <p>This generally includes :-</p>
            <ul className="bluedot_list">
              <li>Name (first, middle, and last).</li>
              <li>A street address (either postal or otherwise).</li>
              <li>Email address and date of birth.</li>
              <li>Information about your credit card.</li>
              <li>Your account's username is a unique identifier for you.</li>
              <li>Contact information.</li>
              <li>Name of the company and its location.</li>
              <li>Occupational title.</li>
              <li>
                IP address, browser information, device information, and
                operating system information regarding devices used to visit our
                websites.
              </li>
              <li>
                We may also use automated methods, such as cookies, web beacons,
                HTML5 localStorage, and other technologies, to gather
                information on the usage of websites, mobile applications,
                social media presence, and internet-enabled services and
                platforms. The J.B.Boda does not employ technology that detects
                your browser's do-not-track signals.
              </li>
              <li>Publicly available information.</li>
            </ul>
            <p>
              Please see The J.B.Boda's Cookies Notice for more information on
              how The J.B.Boda uses cookies, as well as third-party cookie usage
              and how to prevent or decline cookies.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">Aggregate Information</h3>
            <p>
              Any personal data we gather in connection with our Services or
              Site may be aggregated so that it is no longer individually
              identifiable or traceable to you. We may utilise aggregated data
              for our legitimate business objectives, such as analysing website
              traffic and user behaviour.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">Sources</h3>
            <p>
              We may collect personal information from a variety of sources,
              including:
            </p>
            <ul className="bluedot_list">
              <li>
                When you utilise our “Email Signup” tool to get emails from The
                J.B.Boda, we collect information directly from you by phone,
                fax, postal mail, or email.
              </li>
              <li>
                From our suppliers, such as those listed below in the section on
                the Use and Disclosure of Personal Information, through your use
                of our Site.
              </li>
              <li>
                Third parties, such as those listed below in the section on the
                Use and Disclosure of Personal Information.
              </li>
              <li>
                You employ automated technologies and internet tools to connect
                with our Site automatically from your devices (e.g., cookies).
              </li>
              <li>
                Obtaining information from government agencies or public
                records.
              </li>
              <li>
                Should you want to leave comments on The J.B.Boda's blogs,
                should you apply for a job with The J.B.Boda.
              </li>
              <li>Should you apply for a job with The J.B.Boda.</li>
            </ul>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">Use and Disclosure of Personal Information</h3>
            <p>
              Except as required by law and in line with your instructions, the
              J.B.Boda will not swap, sell for monetary value, or otherwise
              disseminate your Personal Information without your approval.
              Unless a longer retention time is required or authorised by law,
              we will keep your Personal Information for as long as it is needed
              to fulfil the objectives mentioned in this Privacy Notice. Do not
              provide any Personal Information via this Server if you do not
              consent.
            </p>
            <p>
              Below we provide how we use your personal information and with
              whom we share your personal information .
            </p>

            <ul className="pp_listpoint mt-4">
              <li>
                <h4>
                  Use of and Disclosure within The J.B.Boda Group Family of
                  Companies.
                </h4>
                <p>
                  Your Personal Information may be shared with firms in The
                  J.B.Boda Group family of enterprises. We may use this
                  information to: ensure that content from our Site is presented
                  most effectively for you and your computer; provide you with
                  information, products, or services that you request from us or
                  that we believe may be of interest to you (where you have
                  consented to be contacted for such purposes); carry out any
                  obligations arising from any contracts entered into between
                  you and The J.B.Boda Group, and allow you to participate in
                  interactive features of our Site. If you do not agree, do not
                  submit any Personal Information over the Internet.
                </p>
              </li>
              <li>
                <h4>
                  Use of and Disclosure to Third-Party Agents and Service
                  Providers, generally.
                </h4>
                <p>
                  We occasionally hire other businesses and people to execute
                  duties or provide services on our behalf. The J.B.Boda, for
                  example, occasionally contracts with businesses that host
                  websites, handle job applications, and send e-mail messages
                  and/or conduct phone calls on our behalf. They may have access
                  to Personal Information (such as names, e-mail addresses, and
                  phone numbers) that they need to perform their functions, but
                  they are prohibited from using such Personal Information for
                  any purpose other than providing services to The J.B.Boda and
                  are required by The J.B.Boda to keep such Personal Information
                  confidential. Do not disclose any Personal Information via
                  this Site if you do not consent.
                </p>
              </li>
              <li>
                <h4>
                  Use of and Disclosure of Tracking Information to Certain
                  Third-Party Service Providers
                </h4>
                <p>
                  Third parties who offer interactive plug-ins or social
                  networking features (for example, Facebook's “Like” button) or
                  send e-mail messages on our behalf to users in their databases
                  may use cookies or other means to track the activity on our
                  Site. These third parties may utilise these cookies or other
                  tracking mechanisms for their purposes by correlating
                  information about your visit to our website and/or purchases
                  with any personally-identifying information they may have
                  about you. Please visit our Cookies Notice for more details.
                </p>
              </li>
              <li>
                <h4>
                  Use of and Disclosure to Insurance Companies and
                  Intermediaries.
                </h4>
                <p>
                  We may share your Personal Information with insurance
                  companies or intermediaries to obtain quotes and place
                  insurance on your behalf if you request a quote for the
                  issuance of an insurance policy or seek to purchase insurance
                  through the Site. This includes, but is not limited to,
                  medical, financial, drivers' licence, and/or other Personal
                  Information you choose to provide. If you do not consent, do
                  not submit Personal Information to this Site to purchase
                  insurance or receive insurance quotations.
                </p>
              </li>
              <li>
                <h4>
                  Use of and Disclosure of Credit Card Data to Credit Card
                  Insurance Company Processing Payment.
                </h4>
                <p>
                  Furthermore, if you choose to purchase an insurance policy or
                  policies through our Site using a credit card or debit card,
                  The J.B.Boda and/or The Insurance company's payment processing
                  processor will collect and process your credit or debit card
                  information. If you select regular billing to cover future
                  premium payments, your credit card or debit card information
                  will be saved until you discontinue ongoing billing. Although
                  The J.B.Boda takes steps to ensure that its third-party credit
                  and debit card processing vendors have adequate privacy
                  protections in place and are required to comply with all
                  applicable data privacy laws and regulations, The J.B.Boda
                  cannot guarantee any third party's data privacy practises. By
                  submitting your credit card or debit card information through
                  this Site, you consent to The J.B.Boda and any third-party
                  suppliers aiding with credit and debit card transactions
                  collecting, processing, and using your credit card or debit
                  card information. If you do not agree, do not submit credit or
                  debit card information through this Site.
                </p>
              </li>
              <li>
                <h4>
                  Use of and Disclosure to Third Parties in Connection with
                  Legal Matters.
                </h4>
                <p>
                  If The J.B.Boda has a good faith belief that such action is
                  necessary to: (a) Protect and defend the rights, property, or
                  safety of The J.B.Boda or its affiliates, employees, other
                  users of this Site, or the public; (b) Enforce the terms and
                  conditions that apply to use of this Site; or (c) Respond to
                  claims that any information about you or your use of this Site
                  has been misused, The J.B.Bod We may also release information
                  if we believe it is required by law, regulation, legal
                  process, or governmental request. You consent to the
                  processing, transmission, use, and/or storage of your Personal
                  Information for any of these reasons by submitting Personal
                  Information via this Site. Do not disclose Personal
                  Information via this Site if you do not consent.
                </p>
              </li>
            </ul>
          </Grid>

          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">External Links</h3>
            <p>
              Links to other websites can be found on our website. Please be
              aware that we cannot be held liable for the privacy policies of
              third-party websites. We advise our visitors to read the privacy
              policies of each website they visit. This Privacy Notice only
              applies to data gathered by The J.B.Boda when you visit this Site.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">Information Storage and Security</h3>
            <p className="mb-2">
              In Internet communications, our systems are set up to offer
              privacy, integrity, and authentication. The security procedures
              we've implemented assist to guarantee that your Personal
              Information is secure and encrypted when you visit our Site so
              that a third party cannot intercept, read, or access it while it's
              in transit between your computer and our system. However, data
              transmission over the Internet is not secure, and while we will do
              everything possible to protect your Personal Information, we
              cannot guarantee the security of data provided to our Site. As a
              result, every transfer you make is entirely at your own risk. We
              will utilise suitable processes and security mechanisms to assist
              prevent unauthorised access after we have received your
              information.
            </p>
            <p className="mb-2">
              Internal access to our systems is restricted, and we maintain
              physical, technical, and administrative precautions to protect any
              Personal Information collected from visitors to our Site. When
              executing our tasks and conducting business, we at The J.B.Boda
              adhere to high standards of ethics and honesty.
            </p>
            <p className="mb-2">
              It should be emphasised, however, that there are some things that
              The J.B.Boda and other website owners cannot control, such as
              viruses and hacker assaults.
            </p>
            <p className="mb-2">
              The J.B.Boda will take all reasonable means to ensure that your
              Personal Information is treated securely and in compliance with
              this Privacy Notice, even if such location does not have the same
              degree of privacy protection as your home country. You consent to
              this processing, transfer, use, and/or storage of your Personal
              Information outside of your country of residence by submitting it.
            </p>
            <p>
              The J.B.Boda securely disposes of Personal Information by
              destroying or permanently erasing electronic data, and by burning,
              pulverising, or shredding hard copy material in ways that prevent
              Personal Information from being read or reconstructed.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">Minors</h3>

            <p>
              The J.B.Boda is dedicated to safeguarding children's privacy and
              encourages parents and guardians to participate actively in their
              children's online activities and interests. The J.B.Boda does not
              intentionally collect information from children under the age of
              13, and the Site is not directed towards minors.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">Updates</h3>

            <p>
              At any moment, we retain the right to make modifications to this
              Privacy Notice. We advise you to check this Site for any updates
              or modifications regularly. When the J.B.Boda makes major
              modifications to this Privacy Notice, it will post a prominent
              notice on the Site homepage to notify users. By continuing to
              access or use this Site, you agree to be bound by all of the terms
              and conditions set out in this Privacy Notice.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"32px"} className="mb-margin1">
            <h3 className="mb-2">
              Contact Information for Questions, Comments, and Requests
            </h3>

            <p>
              Please contact us on our e-mail at{" "}
              <a href="mailto:info@walnnut.com" target="_blank">
                info@walnnut.com
              </a>{" "}
              if you have any questions or concerns concerning data privacy and
              security, the management of your data, or desire to request to
              view your information.
            </p>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default PrivacyPolicy;
