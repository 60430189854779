import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./../../../SCSS/CMSPages.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";

const Disclaimer = () => {
  const isMobile = useIsMobile();
  return (
    <Box className="cmsPage-layout">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="contentPages-layout mb-margin1">
        <Grid container className="row" columnSpacing={3}>
          <Grid
            xs={12}
            marginBottom={"32px"}
            className="mb-margin1 disclaimerPage"
          >
            <h2 className="mb-5">Disclaimer</h2>
            <h4>
              Walnnut.com is the brand name of J.B.BODA Insurance & Reinsurance
              Brokers Private Limited.
            </h4>
            <h4>
              IRDAI Composite Broker License No. IRDA/CB050/03/133 valid till
              16/03/2027.
            </h4>
            <h4>
              Insurance is the subject matter of solicitation. Visitors are
              hereby informed that the information shared on the website may be
              shared with Insurance Companies.
            </h4>
            <h4>
              The product information displayed is authentic and solely bases
              upon the information shared by Insurance companies.
            </h4>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default Disclaimer;
